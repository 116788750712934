import * as actions from "./customers.action";

const customersReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.CUSTOMERS_LOADING:
      return {
        ...state,
        [actions.CUSTOMERS_LOADING]: action.payload
      };
    case actions.GET_CUSTOMERS:
      return {
        ...state,
        [actions.GET_CUSTOMERS]: action.payload
      };
    case actions.CUSTOMERS_ERRORS:
      return {
        ...state,
        [actions.CUSTOMERS_ERRORS]: action.payload
      };
    case actions.CUSTOMER_LOADING:
      return {
        ...state,
        [actions.CUSTOMER_LOADING]: action.payload
      };
    case actions.GET_CUSTOMER:
      return {
        ...state,
        [actions.GET_CUSTOMER]: action.payload
      };
    case actions.CUSTOMER_ERRORS:
      return {
        ...state,
        [actions.CUSTOMER_ERRORS]: action.payload
      };
    case actions.CUSTOMER_UPDATING:
      return {
        ...state,
        [actions.CUSTOMER_UPDATING]: action.payload
      };
    case actions.UPDATE_CUSTOMER:
      return {
        ...state,
        [actions.UPDATE_CUSTOMER]: action.payload
      };
    case actions.CUSTOMER_UPDATED:
      return {
        ...state,
        [actions.CUSTOMER_UPDATED]: action.payload
      };
    case actions.UPDATE_CUSTOMER_ERROR:
      return {
        ...state,
        [actions.UPDATE_CUSTOMER_ERROR]: action.payload
      };
    case actions.CUSTOMER_ADD_LOADING:
      return {
        ...state,
        [actions.CUSTOMER_ADD_LOADING]: action.payload
      };
    case actions.ADD_CUSTOMER:
      return {
        ...state,
        [actions.ADD_CUSTOMER]: action.payload
      };
    case actions.CUSTOMER_ADD_ERROR:
      return {
        ...state,
        [actions.CUSTOMER_ADD_ERROR]: action.payload
      };

    case actions.EXPORT_CUSTOMERS_LOADING:
      return {
        ...state,
        [actions.EXPORT_CUSTOMERS_LOADING]: action.payload
      };
    case actions.EXPORT_CUSTOMERS:
      return {
        ...state,
        [actions.EXPORT_CUSTOMERS]: action.payload
      };
    case actions.EXPORT_CUSTOMERS_ERRORS:
      return {
        ...state,
        [actions.EXPORT_CUSTOMERS_ERRORS]: action.payload
      };
    case actions.EXPORT_SELECTED_CUSTOMERS_LOADING:
      return {
        ...state,
        [actions.EXPORT_SELECTED_CUSTOMERS_LOADING]: action.payload
      };
    case actions.EXPORT_SELECTED_CUSTOMERS:
      return {
        ...state,
        [actions.EXPORT_SELECTED_CUSTOMERS]: action.payload
      };
    case actions.EXPORT_SELECTED_CUSTOMERS_ERRORS:
      return {
        ...state,
        [actions.EXPORT_SELECTED_CUSTOMERS_ERRORS]: action.payload
      };
    case actions.SUSPEND_CUSTOMER_LOADING:
      return {
        ...state,
        [actions.SUSPEND_CUSTOMER_LOADING]: action.payload
      };
    case actions.SUSPEND_CUSTOMER:
      return {
        ...state,
        [actions.SUSPEND_CUSTOMER]: action.payload
      };
    case actions.SUSPEND_CUSTOMER_ERRORS:
      return {
        ...state,
        [actions.SUSPEND_CUSTOMER_ERRORS]: action.payload
      };
    case actions.TERMINATE_CUSTOMER_LOADING:
      return {
        ...state,
        [actions.TERMINATE_CUSTOMER_LOADING]: action.payload
      };
    case actions.TERMINATE_CUSTOMER:
      return {
        ...state,
        [actions.TERMINATE_CUSTOMER]: action.payload
      };
    case actions.TERMINATE_CUSTOMER_ERRORS:
      return {
        ...state,
        [actions.TERMINATE_CUSTOMER_ERRORS]: action.payload
      };
    case actions.EXPORT_FILTERED_CUSTOMERS_LOADING:
      return {
        ...state,
        [actions.EXPORT_FILTERED_CUSTOMERS_LOADING]: action.payload
      };
    case actions.EXPORT_FILTERED_CUSTOMERS:
      return {
        ...state,
        [actions.EXPORT_FILTERED_CUSTOMERS]: action.payload
      };
    case actions.EXPORT_FILTERED_CUSTOMERS_ERRORS:
      return {
        ...state,
        [actions.EXPORT_FILTERED_CUSTOMERS_ERRORS]: action.payload
      };
    default:
      return state;
  }
};

export default customersReducer;
