import * as actions from "./currencies.action";

const currenciesReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.CURRENCIES_LOADING:
      return {
        ...state,
        [actions.CURRENCIES_LOADING]: action.payload
      };
    case actions.GET_CURRENCIES:
      return {
        ...state,
        [actions.GET_CURRENCIES]: action.payload
      };
    case actions.CURRENCIES_ERRORS:
      return {
        ...state,
        [actions.CURRENCIES_ERRORS]: action.payload
      };
    default:
      return state;
  }
};

export default currenciesReducer;
