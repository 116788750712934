import { createStore, applyMiddleware, combineReducers } from "redux";
import Thunk from "redux-thunk";

// Reducers
import usersReducer from "./users/users.reducer";
import plansReducer from "./plans/plans.reducer";
import tagsReducer from "./tags/tags.reducer";
import customersReducer from "./customers/customers.reducer";
import currenciesReducer from "./currencies/currencies.reducer";
import paymentModesReducer from "./payment-modes/payment-modes.reducer";
import subscriptionStatusReducer from "./subscription-status/subscription-status.reducer";
import subscriptionsReducer from "./subscriptions/subscriptions.reducer";
import timezonesReducer from "./timezones/timezones.reducer";
import localesReducer from "./locales/locales.reducer";
import domainsReducer from "./domains/domains.reducer";

const rootReducer = combineReducers({
  users: usersReducer,
  plans: plansReducer,
  tags: tagsReducer,
  customers: customersReducer,
  currencies: currenciesReducer,
  paymentModes: paymentModesReducer,
  subscriptionStatus: subscriptionStatusReducer,
  subscriptions:subscriptionsReducer,
  timezones: timezonesReducer,
  locales: localesReducer,
  domains: domainsReducer,
});

export const middlewares = [Thunk];

export const createStoreWithMiddlware = applyMiddleware(...middlewares)(
  createStore
);

export const store = createStoreWithMiddlware(rootReducer);
