// Custom Components
import http from "helpers/http/http.helper";

// GET CURRENCIES
export const CURRENCIES_LOADING = "CURRENCIES_LOADING";
export const GET_CURRENCIES = "GET_CURRENCIES";
export const CURRENCIES_ERRORS = "CURRENCIES_ERRORS";

// CURRENCIES
export const getCurrencies = params => async dispatch => {
  try {
    dispatch({
      type: CURRENCIES_LOADING,
      payload: true
    });
    const response = await http().get(`/currencies`, { params });
    dispatch({
      type: GET_CURRENCIES,
      payload: response.data
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: CURRENCIES_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: CURRENCIES_LOADING,
      payload: false
    });
  }
};
