import React from "react";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";

// Styles
import "./default-modal.styles.scss";

const BasicModal = ({
  children,
  width = "80%",
  height = "auto",
  handleCloseButton = () => {}
}) => {
  return (
    <div className="app__modal--basic-wrapper">
      <div className="app__modal--basic-container" style={{ width, height }}>
        {/* Close button */}
        <div className="app__modal--basic-close-container">
          <i
            className="fas fa-times app__modal--basic-close cp"
            onClick={handleCloseButton}
          ></i>
        </div>

        {children}
      </div>
      <ToastContainer draggable={true} />
    </div>
  );
};

BasicModal.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func,
};

export default BasicModal;
