import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "./header/header.component";
import Navigation from "./navigation/navigation.component";

// Styles
import "./default.styles.scss";

function Layout({ children }) {

  return (
    <>
      <div className="app__wrapper">
        {/* Header */}
        <Header />

        {/* Left Navigation Area */}
        <Navigation />

        {/* Right Content / Middle Content  Area */}
        <section className="app__content">
          {/* Middle Content */}
          {children}
        </section>
      </div>
      <ToastContainer draggable={true} animation={false}/>
    </>
  );
}

export default Layout;
