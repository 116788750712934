import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import momentTimezone from "moment-timezone";
import moment from "moment";

// Custom Components
import * as usersAction from "store/users/users.action";

function useDate() {
  const dispatch = useDispatch();
  const [timezone, setTimezone] = useState("Asia/Kolkata");

  const loggedUserProfile = useSelector(
    state => state.users[usersAction.LOGGED_USER_PROFILE]
  );

  // If the user does not have timezone in their profile, get system timezone
  useEffect(() => {
    if (loggedUserProfile && loggedUserProfile?.timezone) {
      setTimezone(loggedUserProfile.timezone);
    }
  }, [loggedUserProfile, dispatch]);

  const momentTimezoned = (isoTimestamp = "") =>
    momentTimezone(isoTimestamp).tz(timezone);

  return momentTimezoned;
}

export default useDate;
