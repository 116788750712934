import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

//Custom components
import * as subscriptionsAction from "store/subscriptions/subscriptions.action";
import * as currenciesAction from "store/currencies/currencies.action";
import * as plansAction from "store/plans/plans.action";
import * as paymentModesAction from "store/payment-modes/payment-modes.action";
import * as subscriptionStatusAction from "store/subscription-status/subscription-status.action";
import DropDownSearch from "components/form/dropdown-search/dropdown-search.component";
import Button from "components/form/buttons/button/button.component";
import TextInput from "components/form/inputs/text/text-input.component";

// Styles
import "./subscription-and-billing-info.styles.scss";

const SubscriptionAndBillingInfo = ({
  subscriptionAndBilling,
  setSubscriptionAndBilling
}) => {
  const dispatch = useDispatch();

  //Load the initial data
  useEffect(() => {
    dispatch(currenciesAction.getCurrencies());
    dispatch(plansAction.getPlans());
    dispatch(paymentModesAction.getPaymentModes());
    dispatch(subscriptionStatusAction.getSubscriptionStatus());
    // eslint-disable-next-line
  }, []);

  const addSubscriptionResponse = useSelector(
    state => state.subscriptions[subscriptionsAction.ADD_SUBSCRIPTION]
  );
  const isSubscriptionLoading = useSelector(
    state => state.subscriptions[subscriptionsAction.SUBSCRIPTION_ADD_LOADING]
  );
  const subscriptionErrors = useSelector(
    state => state.subscriptions[subscriptionsAction.SUBSCRIPTION_ADD_ERROR]
  );
  const currencies = useSelector(
    state => state.currencies[currenciesAction.GET_CURRENCIES]
  );
  const plans = useSelector(state => state.plans[plansAction.GET_PLANS]);
  const paymentModes = useSelector(
    state => state.paymentModes[paymentModesAction.GET_PAYMENTMODES]
  );
  const subscriptionStatus = useSelector(
    state =>
      state.subscriptionStatus[subscriptionStatusAction.GET_SUBSCRIPTIONSTATUS]
  );

  return (
    <>
      {/* Contents */}
      <div className="properties">
        <div className="input-group mb-20">
          <label>
            Plan
            <span className="text-danger">*</span>
          </label>
          <DropDownSearch
            title={(() => {
              let title = "Plan";
              if (subscriptionAndBilling?.plan) {
                title = subscriptionAndBilling?.plan?.value;
              }
              return title;
            })()}
            options={plans?.data?.map(plan => ({
              id: plan.id,
              label: plan.plan_name,
              value: plan.plan_name,
              data: plan
            }))}
            onSelect={item => {
              const targetValue = item;
              setSubscriptionAndBilling(prevState => ({
                ...prevState,
                plan: targetValue
              }));
            }}
            showSearch={false}
            errorField="plan"
            errors={subscriptionErrors}
          />
        </div>
        {subscriptionAndBilling?.plan?.value === "Free" ? null : (
          <div className="input-group mb-20">
            <label>
              Currency <span className="text-danger">*</span>
            </label>
            <DropDownSearch
              title={(() => {
                let title = "Currency";
                if (subscriptionAndBilling?.currency) {
                  title = subscriptionAndBilling?.currency?.value;
                }
                return title;
              })()}
              options={currencies?.data?.map(currency => ({
                id: currency.id,
                label: currency.name,
                value: currency.name,
                data: currency
              }))}
              onSelect={item => {
                const targetValue = item;
                setSubscriptionAndBilling(prevState => ({
                  ...prevState,
                  currency: targetValue
                }));
              }}
              showSearch={false}
              errorField="currency"
              errors={subscriptionErrors}
            />
          </div>
        )}

        {subscriptionAndBilling?.plan?.value === "Free" ? null : (
          <div className="input-group mb-20">
            <label>
              Pricing and Subscription Periods
              <span className="text-danger">*</span>
            </label>
            <div className="input-group mb-20 input-flex">
              <div className="input-items">
                <label>
                  Start Date <span className="text-danger">*</span>
                </label>
                <TextInput
                  type="date"
                  id="start_date"
                  name="start_date"
                  className="input-xxl pl-25"
                  errors={subscriptionErrors}
                  iconPosition="left"
                  value={subscriptionAndBilling?.start_date || ""}
                  onChange={e => {
                    const targetValue = e.target.value;
                    setSubscriptionAndBilling(prevState => ({
                      ...prevState,
                      start_date: targetValue
                    }));
                  }}
                />
              </div>
              <div className="input-items">
                <label>
                  End date <span className="text-danger">*</span>
                </label>
                <TextInput
                  type="date"
                  id="end_date"
                  name="end_date"
                  className="input-xxl pl-25"
                  errors={subscriptionErrors}
                  iconClass=""
                  iconPosition="left"
                  value={subscriptionAndBilling?.end_date || ""}
                  onChange={e => {
                    const targetValue = e.target.value;
                    setSubscriptionAndBilling(prevState => ({
                      ...prevState,
                      end_date: targetValue
                    }));
                  }}
                />
              </div>
              <div className="input-items">
                <label>
                  Increment
                  <span className="text-danger">*</span>
                </label>
                <TextInput
                  type="text"
                  id="increment_percentage"
                  name="increment_percentage"
                  className="input-xxl pl-25"
                  errors={subscriptionErrors}
                  iconClass="fa fa-percent"
                  iconPosition="right"
                  value={subscriptionAndBilling?.increment_percentage || ""}
                  onChange={e => {
                    const targetValue = e.target.value;
                    setSubscriptionAndBilling(prevState => ({
                      ...prevState,
                      increment_percentage: targetValue
                    }));
                  }}
                />
              </div>
              <div className="input-items">
                <label>
                  Amount
                  <span className="text-danger">*</span>
                </label>
                <TextInput
                  type="text"
                  id="amount"
                  name="amount"
                  className="input-xxl pl-25"
                  errors={subscriptionErrors}
                  iconClass="fas fa-dollar"
                  iconPosition="left"
                  value={subscriptionAndBilling?.amount || ""}
                  onChange={e => {
                    const targetValue = e.target.value;
                    setSubscriptionAndBilling(prevState => ({
                      ...prevState,
                      amount: targetValue
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {subscriptionAndBilling?.plan?.value === "Free" ? null : (
          <div className="input-group mb-20">
            <label>
              Payment Mode
              <span className="text-danger">*</span>
            </label>
            <DropDownSearch
              title={(() => {
                let title = "Payment Mode";
                if (subscriptionAndBilling?.payment_mode) {
                  title = subscriptionAndBilling?.payment_mode?.value;
                }
                return title;
              })()}
              options={paymentModes?.data
                .filter(paymentMode => paymentMode.mode === "Offline")
                .map(paymentMode => ({
                  id: paymentMode.id,
                  label: paymentMode.type,
                  value: paymentMode.type,
                  data: paymentMode
                }))}
              onSelect={item => {
                const targetValue = item;
                setSubscriptionAndBilling(prevState => ({
                  ...prevState,
                  payment_mode: targetValue
                }));
              }}
              showSearch={false}
              errorField="payment_mode"
              errors={subscriptionErrors}
            />
          </div>
        )}
        {subscriptionAndBilling?.plan?.value === "Free" ? null : (
          <div className="input-group mb-20">
            <label>
              Payment Mode Details
              <span className="text-danger">*</span>
            </label>
            <TextInput
              type="text"
              id="payment_mode_details"
              name="payment_mode_details"
              className="input-xxl pl-25"
              errors={subscriptionErrors}
              iconClass="fa fa-credit-card"
              iconPosition="left"
              value={subscriptionAndBilling?.payment_mode_details || ""}
              onChange={e => {
                const targetValue = e.target.value;
                setSubscriptionAndBilling(prevState => ({
                  ...prevState,
                  payment_mode_details: targetValue
                }));
              }}
            />
          </div>
        )}
        {subscriptionAndBilling?.plan?.value === "Free" ? null : (
          <div className="input-group mb-20">
            <label>Coupon Code</label>
            <TextInput
              type="text"
              id="coupon"
              name="coupon"
              className="input-xxl pl-25"
              errors={subscriptionErrors}
              iconClass="fa fa-gift"
              iconPosition="left"
              value={subscriptionAndBilling?.coupon || ""}
              onChange={e => {
                const targetValue = e.target.value;
                setSubscriptionAndBilling(prevState => ({
                  ...prevState,
                  coupon: targetValue
                }));
              }}
            />
          </div>
        )}
        <div className="input-group mb-20">
          <label>
            Subscription Status
            <span className="text-danger">*</span>
          </label>
          <div className="input-group mb-20">
            <DropDownSearch
              title={(() => {
                let title = "Subscription Status";
                if (subscriptionAndBilling?.subscription_status) {
                  title = subscriptionAndBilling?.subscription_status?.value;
                }
                return title;
              })()}
              options={subscriptionStatus?.data?.map(status => ({
                id: status.id,
                label: status.type,
                value: status.type,
                data: status
              }))}
              onSelect={item => {
                const targetValue = item;
                setSubscriptionAndBilling(prevState => ({
                  ...prevState,
                  subscription_status: targetValue
                }));
              }}
              showSearch={false}
              errorField="subscription_status"
              errors={subscriptionErrors}
            />
          </div>
        </div>
        {/* End of Right Col */}
      </div>
      <div className="row justify-center mb-40">
        <Button
          disabled={isSubscriptionLoading}
          id="intro"
          className="btn btn--info"
          onClick={() =>
            dispatch(
              subscriptionsAction.addSubscription(subscriptionAndBilling)
            )
          }
        >
          Submit
        </Button>
      </div>
    </>
  );
};

SubscriptionAndBillingInfo.propTypes = {
  subscriptionAndBilling: PropTypes.object.isRequired,
  setSubscriptionAndBilling: PropTypes.func.isRequired
};

export default SubscriptionAndBillingInfo;
