import React, { useState } from "react";
import classnames from "classnames";
import "react-toastify/dist/ReactToastify.css";

// Custom Components
import TwoFactorOne from "assets/img/illustrations/2fa-1.png";
import ConfirmDisableModal from "./confirm-disable-modal/confirm-disable-modal";
import Button from "components/form/buttons/button/button.component";

function TwoFactorDisable() {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={classnames("app__2fa--content app__2fa--content-active")}>
      {showModal && <ConfirmDisableModal setShowModal={setShowModal} />}
      <img
        className="hero mb-50"
        src={TwoFactorOne}
        alt="Two Factor Authentication"
      />

      <p className="app__2fa--content-text my-50">
        Two factor is enabled for this account you can disable it by clicking on the button below
      </p>

      <div className="row justify-center mt-50">
        <Button id="disable-two-factor" onClick={() => setShowModal(true)} className="btn btn--danger">
          Disable Two Factor Authentication
        </Button>
      </div>
    </div>
  );
}

export default TwoFactorDisable;
