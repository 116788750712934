// Custom Components
import http from "helpers/http/http.helper";

// GET PAYMENTMODES
export const PAYMENTMODES_LOADING = "PAYMENTMODES_LOADING";
export const GET_PAYMENTMODES = "GET_PAYMENTMODES";
export const PAYMENTMODES_ERRORS = "PAYMENTMODES_ERRORS";

// PAYMENTMODES
export const getPaymentModes = () => async dispatch => {
  try {
    dispatch({
      type: PAYMENTMODES_LOADING,
      payload: true
    });
    const response = await http().get(`/payment-modes`);
    dispatch({
      type: GET_PAYMENTMODES,
      payload: response.data
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: PAYMENTMODES_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: PAYMENTMODES_LOADING,
      payload: false
    });
  }
};

