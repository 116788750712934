import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import classnames from "classnames";
import { useDispatch } from "react-redux";

// Custom Components
import * as usersAction from "store/users/users.action";
import { TAB_VERIFY, TAB_CONFIRM } from "../user-2fa.container";
import Loading from "components/loading/loading.component";
import Error from "components/form/error-label/error-label.component";
import notification from "helpers/notification/notification.helper";
import Button from "components/form/buttons/button/button.component";

// Hooks
import useHttp from "hooks/useHttp";

const TwoFactorVerify = ({ activeTab, setActiveTab, setBackupCodes }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const initTokenValue = {
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: ""
  };
  const [token, setToken] = useState(initTokenValue);
  const [verifyPostData, setVerifyPostData] = useState({
    token: ""
  });
  const tokenBox1Ref = useRef(null);
  const tokenBox2Ref = useRef(null);
  const tokenBox3Ref = useRef(null);
  const tokenBox4Ref = useRef(null);
  const tokenBox5Ref = useRef(null);
  const tokenBox6Ref = useRef(null);
  const verifyButtonRef = useRef(null);

  const {
    get: getQRCode,
    isLoading: isQRCodeLoading,
    response: qrCodeResponse
  } = useHttp(`/users/${params.id}/two-factor`);

  const {
    post: verifyTwoFactor,
    isLoading: verifyTwoFactorLoading,
    response: verifyTwoFactorResponse,
    error: verifyTwoFactorError,
    setError
  } = useHttp(`users/${params.id}/two-factor`);

  // Load the QR Code
  useEffect(() => {
    if (activeTab === TAB_VERIFY) {
      getQRCode();
      setTimeout(() => {
        tokenBox1Ref.current.focus();
      }, 700);
    }

    // eslint-disable-next-line
  }, [activeTab]);

  useEffect(() => {
    return () => {
      dispatch(usersAction.getUserProfile(params.id));
    };
    // eslint-disable-next-line
  }, [dispatch, params]);

  // Keep the post data uptodate
  useEffect(() => {
    if (token) {
      const tokenString = Object.values(token).join("");
      setVerifyPostData(prevState => {
        return {
          ...prevState,
          token: tokenString
        };
      });
    }
    // eslint-disable-next-line
  }, [token]);

  // If token verification is successful
  useEffect(() => {
    if (verifyTwoFactorResponse) {
      setBackupCodes(verifyTwoFactorResponse.data.data);
      setActiveTab(TAB_CONFIRM);
    }
    // eslint-disable-next-line
  }, [verifyTwoFactorResponse]);

  // If token verification failed
  useEffect(() => {
    if (verifyTwoFactorError) {
      notification.error("Token is invalid");
    }
    // Remove all error messages on unmount
    return () => setError(null);
    // eslint-disable-next-line
  }, [verifyTwoFactorError]);

  const validateNumbers = value => {
    const reg = new RegExp(/^[0-9]/);
    return reg.exec(value) !== null;
  };

  const handleRegenerateCode = () => {
    getQRCode();
    setToken(initTokenValue);
    tokenBox1Ref.current.focus();
  };

  const handleOnChange = (e, nextFocusElementRef) => {
    if (!validateNumbers(e.target.value)) {
      return setToken({
        ...token,
        [e.target.getAttribute("tabIndex")]: ""
      });
    }
    setToken({
      ...token,
      [e.target.getAttribute("tabIndex")]: e.target.value
    });
    nextFocusElementRef.current?.focus();
  };

  const handleOnFocus = e => {
    e.target.setSelectionRange(0, e.target.value.length);
  };

  return (
    <div
      className={classnames(
        "app__2fa--content",
        {
          "app__2fa--content-active": activeTab === TAB_VERIFY
        },
        {
          "app__2fa--content-inactive": activeTab === TAB_CONFIRM
        }
      )}
    >
      <p className="app__2fa--content-text">
        To add eisenvault to your authenticator app open the app and add a new
        token
      </p>
      <div className="row justify-center">
        <Loading isLoading={isQRCodeLoading} className="hrm-20" />

        {!isQRCodeLoading && qrCodeResponse && (
          <img
            src={qrCodeResponse?.data?.data}
            className="app__2fa--barcode my-30"
            alt="Two Factor Step 2"
          />
        )}
      </div>
      <p className="app__2fa--content-text">
        After scanning the image enter the 6 digit authentication code generated
        by the app in the box below
      </p>

      <div className="app__2fa--content-code-wrapper mt-20">
        <input
          type="text"
          maxLength="1"
          className="app__2fa--verify-code mr-10"
          tabIndex="1"
          ref={tokenBox1Ref}
          value={token[1]}
          onChange={e => handleOnChange(e, tokenBox2Ref)}
          onFocus={handleOnFocus}
        />
        <input
          type="text"
          maxLength="1"
          className="app__2fa--verify-code mr-10"
          tabIndex="2"
          value={token[2]}
          ref={tokenBox2Ref}
          onChange={e => handleOnChange(e, tokenBox3Ref)}
          onFocus={handleOnFocus}
        />
        <input
          type="text"
          maxLength="1"
          className="app__2fa--verify-code mr-10"
          tabIndex="3"
          value={token[3]}
          ref={tokenBox3Ref}
          onChange={e => handleOnChange(e, tokenBox4Ref)}
          onFocus={handleOnFocus}
        />
        <input
          type="text"
          maxLength="1"
          className="app__2fa--verify-code mr-10"
          tabIndex="4"
          value={token[4]}
          ref={tokenBox4Ref}
          onChange={e => handleOnChange(e, tokenBox5Ref)}
          onFocus={handleOnFocus}
        />
        <input
          type="text"
          maxLength="1"
          className="app__2fa--verify-code mr-10"
          tabIndex="5"
          value={token[5]}
          ref={tokenBox5Ref}
          onChange={e => handleOnChange(e, tokenBox6Ref)}
          onFocus={handleOnFocus}
        />
        <input
          type="text"
          maxLength="1"
          className="app__2fa--verify-code mr-10"
          tabIndex="6"
          value={token[6]}
          ref={tokenBox6Ref}
          onChange={e => handleOnChange(e, verifyButtonRef)}
          onFocus={handleOnFocus}
        />
      </div>

      <div className="row justify-center mt-10">
        <Error errors={verifyTwoFactorError} field="token" />
      </div>

      <div className="row justify-between mt-30">
        <Button
          id="regenerate-code"
          className="btn btn--outline-danger"
          onClick={handleRegenerateCode}
        >
          Regenerate QR code
        </Button>
        <Button
          id="verify"
          disabled={
            verifyTwoFactorLoading || verifyPostData?.token?.length !== 6
          }
          className="btn btn--info"
          innerRef={verifyButtonRef}
          onClick={() => verifyTwoFactor(verifyPostData)}
        >
          Verify and Activate Two Factor Auth
        </Button>
      </div>
    </div>
  );
};

TwoFactorVerify.propTypes = {
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func.isRequired,
  setBackupCodes: PropTypes.func.isRequired
};

export default TwoFactorVerify;
