import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";

// Custom Module
import * as baseAction from "store/base/base.action";
import DefaultLayout from "layouts/default/default.layout";
import * as plansAction from "store/plans/plans.action";
import TextInput from "components/form/inputs/text/text-input.component";
import DropDownSearch from "components/form/dropdown-search/dropdown-search.component";
import Radio from "components/form/radio/radio.component";
import Button from "components/form/buttons/button/button.component";
import notification from "helpers/notification/notification.helper";
import Loading from "components/loading/loading.component";

// Styles
import "./update-plan.styles.scss";

const ProfileEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [planData, setPlanData] = useState({});

  const planTypesOptions = [
    { id: 0, label: "Cloud", value: "Cloud" },
    { id: 1, label: "Premises", value: "Premises" }
  ];
  const filePreviewOptions = [
    { id: 0, label: "Standard", value: "Standard" },
    { id: 1, label: "Standard+", value: "Standard+" }
  ];
  const approvalsOptions = [
    { id: 0, label: "No", value: "No" },
    { id: 1, label: "Simple", value: "Simple" },
    { id: 2, label: "Simple+Advanced", value: "Simple+Advanced" }
  ];
  const reportsOptions = [
    { id: 0, label: "No", value: "No" },
    { id: 2, label: "Basic", value: "Basic" },
    { id: 3, label: "Advanced", value: "Advanced" }
  ];
  const supportOptions = [
    { id: 0, label: "Yes", value: "Yes" },
    { id: 1, label: "Self-Help Portal", value: "Self-Help Portal" }
  ];

  const planResponse = useSelector(state => state.plans[plansAction.GET_PLAN]);
  const isPlanLoading = useSelector(
    state => state.plans[plansAction.PLAN_LOADING]
  );
  const updatePlanResponse = useSelector(
    state => state.plans[plansAction.UPDATE_PLAN]
  );
  const isPlanUpdateLoading = useSelector(
    state => state.plans[plansAction.PLAN_UPDATING]
  );
  const planUpdateNotifications = useSelector(
    state => state.plans[plansAction.PLAN_UPDATED]
  );
  const updatePlanErrors = useSelector(
    state => state.plans[plansAction.UPDATE_PLAN_ERROR]
  );

  // Load plan and store it in state
  useEffect(() => {
    if (planResponse) {
      setPlanData(planResponse);
    }
    // eslint-disable-next-line
  }, [planResponse]);

  // When the plan update is successful
  useEffect(() => {
    if (planUpdateNotifications) {
      notification.success(planUpdateNotifications, {
        position: "top-right"
      });
      dispatch(plansAction.getPlan(params.id));
      dispatch(baseAction.reset(plansAction.PLAN_UPDATED));
      dispatch(baseAction.reset(plansAction.UPDATE_PLAN_ERROR));
    }
    // eslint-disable-next-line
  }, [planUpdateNotifications, dispatch, params]);

  // When there is an error updating the plan
  useEffect(() => {
    if (updatePlanErrors && !updatePlanResponse) {
      notification.error(updatePlanErrors, {
        position: "top-right"
      });
    }
    // eslint-disable-next-line
  }, [updatePlanErrors, dispatch]);

  // Fetch the plan from api
  useEffect(() => {
    if (params?.id) {
      dispatch(plansAction.getPlan(params.id));
    }
  }, [params, dispatch]);

  // When the component is unloaded, reset the error messages
  useEffect(() => {
    return () => {
      dispatch(baseAction.reset(plansAction.UPDATE_PLAN_ERROR));
    };
  }, [dispatch]);

  return (
    <DefaultLayout>
      <div className="container">
        {/* Content Header */}
        <div className="app__data--listing-header">
          <div className="app__data--listing-header-top mb-20">
            <div className="app__data--listing-header-left-col">
              <div className="app__data--listing-title">
                <Link
                  to={{
                    pathname: "/plans"
                  }}
                  className="mr-10"
                >
                  <i
                    className="fas fa-long-arrow-alt-left"
                    data-tip="Back to Plans"
                  ></i>
                </Link>
                Update Plan
              </div>
            </div>
          </div>
        </div>
        {/* Content Body */}
        <div className="app__user--account--wrapper mb-20 mt-20">
          <div className="card app__user--account-card">
            {/* Loading */}
            <div className="row justify-center">
              <Loading isLoading={isPlanLoading} />
            </div>
            <h4 className="ml-40">Plan</h4>

            <div className="app__plan-form">
              <div className="hr mt-20"></div>

              <div className="mt-30 col-2">
                <label
                  htmlFor="plan_type"
                  className="text-md mb-4 mr-20 text-right"
                >
                  Plan Type
                  <span className="text-danger">*</span>
                </label>
                <DropDownSearch
                  title={(() => {
                    let title = "Plan Type";
                    if (planData?.plan_type) {
                      title = planData.plan_type;
                    }
                    return title;
                  })()}
                  options={planTypesOptions}
                  onSelect={item => {
                    setPlanData({
                      ...planData,
                      plan_type: item.value
                    });
                  }}
                  showSearch={false}
                />
              </div>

              <div className="mt-30 col-2">
                <label
                  htmlFor="plan_name"
                  className="text-md mb-4 mr-20 text-right"
                >
                  Plan Name
                  <span className="text-danger">*</span>
                </label>
                <TextInput
                  type="text"
                  id="plan_name"
                  name="plan_name"
                  value={planData?.plan_name || ""}
                  className="input-lg pr-25"
                  errors={updatePlanErrors}
                  iconClass=""
                  iconPosition="right"
                  onChange={e =>
                    setPlanData({
                      ...planData,
                      [e.target.id]: e.target.value
                    })
                  }
                />
              </div>

              <div className="mt-30 col-2">
                <label
                  htmlFor="min_users"
                  className="text-md mb-4 mr-20 text-right"
                >
                  Min Users
                </label>
                <TextInput
                  type="number"
                  id="min_users"
                  name="min_users"
                  value={planData?.min_users || ""}
                  className="input-lg pr-25"
                  errors={updatePlanErrors}
                  iconClass="far fa-user"
                  iconPosition="right"
                  onChange={e =>
                    setPlanData({
                      ...planData,
                      [e.target.id]: e.target.value
                    })
                  }
                />
              </div>

              <div className="mt-30 col-2">
                <label
                  htmlFor="max_users"
                  className="text-md mb-4 mr-20 text-right"
                >
                  Max Users
                </label>
                <TextInput
                  type="number"
                  id="max_users"
                  name="max_users"
                  value={planData?.max_users || ""}
                  className="input-lg pr-25"
                  errors={updatePlanErrors}
                  iconClass="fas fa-user-tie"
                  iconPosition="right"
                  onChange={e =>
                    setPlanData({
                      ...planData,
                      [e.target.id]: e.target.value
                    })
                  }
                />
              </div>

              <div className="mt-30 col-2">
                <label
                  htmlFor="disk_space"
                  className="text-md mb-4 mr-20 text-right"
                >
                  Disk Space
                </label>
                <TextInput
                  type="text"
                  id="disk_space"
                  name="disk_space"
                  value={planData?.disk_space || ""}
                  className="input-lg pr-25"
                  errors={updatePlanErrors}
                  iconClass=""
                  iconPosition="right"
                  onChange={e =>
                    setPlanData({
                      ...planData,
                      [e.target.id]: e.target.value
                    })
                  }
                />
              </div>

              <div className="mt-30 col-2">
                <label
                  htmlFor="file_previews"
                  className="text-md mb-4 mr-20 text-right"
                >
                  File Previews
                </label>
                <DropDownSearch
                  title={(() => {
                    let title = "File Previews";
                    if (planData?.file_previews) {
                      title = planData.file_previews;
                    }
                    return title;
                  })()}
                  options={filePreviewOptions}
                  onSelect={item => {
                    setPlanData({
                      ...planData,
                      file_previews: item.value
                    });
                  }}
                  showSearch={false}
                  errors={updatePlanErrors}
                />
              </div>

              {/* Approvals */}
              <div className="mt-30 col-2">
                <label
                  htmlFor="approvals"
                  id="status"
                  className="text-md mb-4 mr-20 text-right"
                >
                  Approvals
                </label>
                <DropDownSearch
                  title={(() => {
                    let title = "Approvals";
                    if (planData?.approvals) {
                      title = planData.approvals;
                    }
                    return title;
                  })()}
                  options={approvalsOptions}
                  onSelect={item => {
                    setPlanData({
                      ...planData,
                      approvals: item.value
                    });
                  }}
                  showSearch={false}
                  errors={updatePlanErrors}
                />
              </div>

              <div className="mt-30 col-2">
                <label
                  htmlFor="reports"
                  id="status"
                  className="text-md mb-4 mr-20 text-right"
                >
                  Reports
                </label>
                <DropDownSearch
                  title={(() => {
                    let title = "Reports";
                    if (planData?.reports) {
                      title = planData.reports;
                    }
                    return title;
                  })()}
                  options={reportsOptions}
                  onSelect={item => {
                    setPlanData({
                      ...planData,
                      reports: item.value
                    });
                  }}
                  showSearch={false}
                  errors={updatePlanErrors}
                />
              </div>
              <div className="mt-30 col-2">
                <label
                  htmlFor="file_size_limit"
                  id="status"
                  className="text-md mb-4 mr-20 text-right"
                >
                  Upload Limit Individual File Size
                </label>
                <TextInput
                  type="text"
                  id="file_size_limit"
                  name="file_size_limit"
                  value={planData?.file_size_limit || ""}
                  className="input-lg pr-25"
                  errors={updatePlanErrors}
                  iconClass=""
                  iconPosition="right"
                  onChange={e =>
                    setPlanData({
                      ...planData,
                      [e.target.id]: e.target.value
                    })
                  }
                />
              </div>

              <div className="mt-30 col-2">
                <label
                  htmlFor="support"
                  id="status"
                  className="text-md mb-4 mr-20 text-right"
                >
                  Support
                </label>
                <DropDownSearch
                  title={(() => {
                    let title = "Support";
                    if (planData?.support) {
                      title = planData.support;
                    }
                    return title;
                  })()}
                  options={supportOptions}
                  onSelect={item => {
                    setPlanData({
                      ...planData,
                      support: item.value
                    });
                  }}
                  showSearch={false}
                  errors={updatePlanErrors}
                />
              </div>
              <div className="hr mt-20 mb-30"></div>
              <div className="row justify-between align-center mt-20 plan-grid">
                <div className="row justify-between align-center mt-20">
                  <label
                    className="text-md mb-4 mr-20 text-right"
                    id="external_sharing"
                  >
                    External Sharing
                  </label>
                  <Radio
                    defaultChecked={planData?.external_sharing}
                    handleOnClick={() => {
                      setPlanData({
                        ...planData,
                        external_sharing: !planData?.external_sharing
                      });
                    }}
                    hasTabIndex={true}
                    ariaLabelledBy="external_sharing"
                  />
                </div>
                <div className="row justify-between align-center mt-20">
                  <label
                    className="text-md mb-4 mr-20 text-right"
                    id="phone_apps"
                  >
                    Phone Apps
                  </label>
                  <Radio
                    defaultChecked={planData?.phone_apps}
                    handleOnClick={() => {
                      setPlanData({
                        ...planData,
                        phone_apps: !planData?.phone_apps
                      });
                    }}
                    hasTabIndex={true}
                    ariaLabelledBy="phone_apps"
                  />
                </div>
              </div>
              <div className="row justify-between  align-center mt-20 plan-grid">
                <div className="row justify-between  align-center mt-20">
                  <label
                    className="text-md mb-4 mr-20 text-right"
                    id="offline_sync"
                  >
                    OfflineSync
                  </label>
                  <Radio
                    defaultChecked={planData?.offline_sync}
                    handleOnClick={() => {
                      setPlanData({
                        ...planData,
                        offline_sync: !planData?.offline_sync
                      });
                    }}
                    hasTabIndex={true}
                    ariaLabelledBy="offline_sync"
                  />
                </div>
                <div className="row justify-between  align-center mt-20">
                  <label
                    className="text-md mb-4 mr-20 text-right"
                    id="version_control"
                  >
                    Version Control
                  </label>
                  <Radio
                    defaultChecked={planData?.version_control}
                    handleOnClick={() => {
                      setPlanData({
                        ...planData,
                        version_control: !planData?.version_control
                      });
                    }}
                    hasTabIndex={true}
                    ariaLabelledBy="version_control"
                  />
                </div>
              </div>
              <div className="row justify-between  align-center mt-20 plan-grid">
                <div className="row justify-between  align-center mt-20">
                  <label
                    className="text-md mb-4 mr-20 text-right"
                    id="document_tagging"
                  >
                    Document Tagging
                  </label>
                  <Radio
                    defaultChecked={planData?.document_tagging}
                    handleOnClick={() => {
                      setPlanData({
                        ...planData,
                        document_tagging: !planData?.document_tagging
                      });
                    }}
                    hasTabIndex={true}
                    ariaLabelledBy="document_tagging"
                  />
                </div>
                <div className="row justify-between  align-center mt-20">
                  <label
                    className="text-md mb-4 mr-20 text-right"
                    id="auto_document_categorisation"
                  >
                    Auto Document Categorsation
                  </label>
                  <Radio
                    defaultChecked={planData?.auto_document_categorisation}
                    handleOnClick={() => {
                      setPlanData({
                        ...planData,
                        auto_document_categorisation:
                          !planData?.auto_document_categorisation
                      });
                    }}
                    hasTabIndex={true}
                    ariaLabelledBy="auto_document_categorisation"
                  />
                </div>
              </div>
              <div className="row justify-between  align-center mt-20 plan-grid">
                <div className="row justify-between  align-center mt-20">
                  <label
                    className="text-md mb-4 mr-20 text-right"
                    id="two_factor"
                  >
                    Two Factor
                  </label>
                  <Radio
                    defaultChecked={planData?.two_factor}
                    handleOnClick={() => {
                      setPlanData({
                        ...planData,
                        two_factor: !planData?.two_factor
                      });
                    }}
                    hasTabIndex={true}
                    ariaLabelledBy="two_factor"
                  />
                </div>
                <div className="row justify-between  align-center mt-20">
                  <label
                    className="text-md mb-4 mr-20 text-right"
                    id="custom_document_categories"
                  >
                    Custom Document Categories
                  </label>
                  <Radio
                    defaultChecked={planData?.custom_document_categories}
                    handleOnClick={() => {
                      setPlanData({
                        ...planData,
                        custom_document_categories:
                          !planData?.custom_document_categories
                      });
                    }}
                    hasTabIndex={true}
                    ariaLabelledBy="custom_document_categories"
                  />
                </div>
              </div>
              <div className="row justify-between  align-center mt-20 plan-grid">
                <div className="row justify-between align-center mt-20">
                  <label className="text-md mb-4 mr-20 text-right" id="ocr">
                    OCR
                  </label>
                  <Radio
                    defaultChecked={planData?.ocr}
                    handleOnClick={() => {
                      setPlanData({
                        ...planData,
                        ocr: !planData?.ocr
                      });
                    }}
                    hasTabIndex={true}
                    ariaLabelledBy="ocr"
                  />
                </div>
                <div className="row justify-between  align-center mt-20">
                  <label
                    className="text-md mb-4 mr-20 text-right"
                    id="branding"
                  >
                    Branding
                  </label>
                  <Radio
                    defaultChecked={planData?.branding}
                    handleOnClick={() => {
                      setPlanData({
                        ...planData,
                        branding: !planData?.branding
                      });
                    }}
                    hasTabIndex={true}
                    ariaLabelledBy="branding"
                  />
                </div>
              </div>
              <div className="row justify-between align-center mt-20 plan-grid">
                <div className="row justify-between  align-center mt-20">
                  <label
                    className="text-md mb-4 mr-20 text-right"
                    id="offline_integration"
                  >
                    Office Integration
                  </label>
                  <Radio
                    defaultChecked={planData?.office_integration}
                    handleOnClick={() => {
                      setPlanData({
                        ...planData,
                        office_integration: !planData?.office_integration
                      });
                    }}
                    hasTabIndex={true}
                    ariaLabelledBy="office_integration"
                  />
                </div>
                <div className="row justify-between align-center mt-20">
                  <label
                    className="text-md mb-4 mr-20 text-right"
                    id="sso_integration"
                  >
                    SSO Integration
                  </label>
                  <Radio
                    defaultChecked={planData?.sso_integration}
                    handleOnClick={() => {
                      setPlanData({
                        ...planData,
                        sso_integration: !planData?.sso_integration
                      });
                    }}
                    hasTabIndex={true}
                    ariaLabelledBy="sso_integration"
                  />
                </div>
              </div>
              <div className="row justify-between  align-center mt-20 plan-grid">
                <div className="row justify-between  align-center mt-20">
                  <label
                    className="text-md mb-4 mr-20 text-right"
                    id="audit_trails"
                  >
                    Audit Trails
                  </label>
                  <Radio
                    defaultChecked={planData?.audit_trails}
                    handleOnClick={() => {
                      setPlanData({
                        ...planData,
                        audit_trails: !planData?.audit_trails
                      });
                    }}
                    hasTabIndex={true}
                    ariaLabelledBy="audit_trails"
                  />
                </div>
                <div className="row justify-between  align-center mt-20">
                  <label
                    className="text-md mb-4 mr-20 text-right"
                    id="custom_roles"
                  >
                    Custom Roles
                  </label>
                  <Radio
                    defaultChecked={planData?.custom_roles}
                    handleOnClick={() => {
                      setPlanData({
                        ...planData,
                        custom_roles: !planData?.custom_roles
                      });
                    }}
                    hasTabIndex={true}
                    ariaLabelledBy="custom_roles"
                  />
                </div>
              </div>
              <div className="row justify-between align-center mt-20 plan-grid">
                <div className="row justify-between  align-center mt-20">
                  <label className="text-md mb-4 mr-20 text-right" id="groups">
                    Groups
                  </label>
                  <Radio
                    defaultChecked={planData?.groups}
                    handleOnClick={() => {
                      setPlanData({
                        ...planData,
                        groups: !planData?.groups
                      });
                    }}
                    hasTabIndex={true}
                    ariaLabelledBy="groups"
                  />
                </div>
                <div className="row justify-between  align-center mt-20">
                  <label
                    className="text-md mb-4 mr-20 text-right"
                    id="document_subscription"
                  >
                    Document Subscription
                  </label>
                  <Radio
                    defaultChecked={planData?.document_subscription}
                    handleOnClick={() => {
                      setPlanData({
                        ...planData,
                        document_subscription: !planData?.document_subscription
                      });
                    }}
                    hasTabIndex={true}
                    ariaLabelledBy="document_subscription"
                  />
                </div>
              </div>
              <div className="row justify-between  align-center mt- plan-grid">
                <div className="row justify-between  align-center mt-20">
                  <label
                    className="text-md mb-4 mr-20 text-right"
                    id="pdf_annotations"
                  >
                    Pdf Annotations
                  </label>
                  <Radio
                    defaultChecked={planData?.pdf_annotations}
                    handleOnClick={() => {
                      setPlanData({
                        ...planData,
                        pdf_annotations: !planData?.pdf_annotations
                      });
                    }}
                    hasTabIndex={true}
                    ariaLabelledBy="pdf_annotations"
                  />
                </div>

                <div className="row justify-between  align-center mt-20">
                  <label
                    className="text-md mb-4 mr-20 text-right"
                    id="email_document_from_d_m_s"
                  >
                    Email Document from DMS
                  </label>
                  <Radio
                    defaultChecked={planData?.email_document_from_d_m_s}
                    handleOnClick={() => {
                      setPlanData({
                        ...planData,
                        email_document_from_d_m_s:
                          !planData?.email_document_from_d_m_s
                      });
                    }}
                    hasTabIndex={true}
                    ariaLabelledBy="email_document_from_d_m_s"
                  />
                </div>
              </div>
              <div className="row justify-between  align-center mt-20 plan-grid">
                <div className="row justify-between  align-center mt-20">
                  <label
                    className="text-md mb-4 mr-20 text-right"
                    id="email_document_to_d_m_s"
                  >
                    Email Document to DMS
                  </label>
                  <Radio
                    defaultChecked={planData?.email_document_to_d_m_s}
                    handleOnClick={() => {
                      setPlanData({
                        ...planData,
                        email_document_to_d_m_s:
                          !planData?.email_document_to_d_m_s
                      });
                    }}
                    hasTabIndex={true}
                    ariaLabelledBy="email_document_to_d_m_s"
                  />
                </div>
              </div>
              <div className="hr mt-20 mb-30"></div>
              <div className="mt-30 col-2">
                <label
                  htmlFor="account_suspension_in_case_of_no_usage"
                  id="account_suspension_in_case_of_no_usage"
                  className="text-md mb-4 mr-20 text-right"
                >
                  Account Suspension Days
                </label>
                <TextInput
                  type="number"
                  id="account_suspension_in_case_of_no_usage"
                  name="account_suspension_in_case_of_no_usage"
                  value={planData?.account_suspension_in_case_of_no_usage || ""}
                  className="input-lg pr-25"
                  errors={updatePlanErrors}
                  iconClass=""
                  iconPosition="right"
                  onChange={e =>
                    setPlanData({
                      ...planData,
                      [e.target.id]: e.target.value
                    })
                  }
                />
              </div>
              <div className="mt-30 col-2">
                <label
                  htmlFor="account_deletion_in_case_of_no_usage"
                  id="account_deletion_in_case_of_no_usage"
                  className="text-md mb-4 mr-20 text-right"
                >
                  Account Deletion Days
                </label>
                <TextInput
                  type="number"
                  id="account_deletion_in_case_of_no_usage"
                  name="account_deletion_in_case_of_no_usage"
                  value={planData?.account_deletion_in_case_of_no_usage || ""}
                  className="input-lg pr-25"
                  errors={updatePlanErrors}
                  iconClass=""
                  iconPosition="right"
                  onChange={e =>
                    setPlanData({
                      ...planData,
                      [e.target.id]: e.target.value
                    })
                  }
                />
              </div>
              <div className="mt-30 col-2">
                <label
                  htmlFor="trial_days"
                  id="trial_days"
                  className="text-md mb-4 mr-20 text-right"
                >
                  Trial Days
                </label>
                <TextInput
                  type="number"
                  id="trial_days"
                  name="trial_days"
                  value={planData?.trial_days || ""}
                  className="input-lg pr-25"
                  errors={updatePlanErrors}
                  iconClass=""
                  iconPosition="right"
                  onChange={e =>
                    setPlanData({
                      ...planData,
                      [e.target.id]: e.target.value
                    })
                  }
                />
              </div>
              <div className="hr mt-20 mb-30"></div>

              <Button
                id="update-plan"
                tabIndex="1"
                disabled={isPlanUpdateLoading}
                onClick={() => dispatch(plansAction.updatePlan(planData))}
                className="btn btn--info btn--lg fr"
              >
                Update Plan
              </Button>

              {/* End Form Wrapper */}
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default ProfileEdit;
