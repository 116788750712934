import React from "react";

// Styles
import "./drawer-modal-body.styles.scss";

const Body = ({ children, Footer, containerColor = "bg-white-100" }) => {
  return (
    <div className="h-100 col">
      <div className="app__drawer-modal--container mt-10">
        <div className={`app__drawer-modal--card mb-20 ${containerColor}`}>
          {children}
        </div>
      </div>
      {Footer && <Footer />}
    </div>
  );
};

export default Body;
