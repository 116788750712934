import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Custom Components
import LoginLayout from "layouts/login/login.layout";
import logo from "assets/img/logos/logo-md.png";
import TextInput from "components/form/inputs/text/text-input.component";
import notification from "helpers/notification/notification.helper";

// Hooks
import useHttp from "hooks/useHttp";

// Styles
import "../auth.styles.scss";

const VerifyToken = () => {
  const history = useHistory();

  const [token, setToken] = useState({"token":""});

  const {
    post: verifyToken,
    setPostData: setTokenPostData,
    isLoading: isVerifyTokenLoading,
    status: verifyTokenResponseStatus,
    setStatus: setVerifyTokenResponseStatus,
    error: verifyTokenError,
    setError: setVerifyTokenError
  } = useHttp(`/auth/verify-2factor`);

  //When the response is Success
  useEffect(() => {
    if (verifyTokenResponseStatus === 200) {
      return history.push("/manage-customers");
    }

    // Remove all error messages on unmount
    return () => setVerifyTokenResponseStatus(null);
    // eslint-disable-next-line
  }, [verifyTokenResponseStatus]);

  // When the response is Error
  useEffect(() => {
    if (verifyTokenError) {
      notification.error(verifyTokenError);
    }

   // Remove all error messages on unmount
   return () => setVerifyTokenError(null);
   // eslint-disable-next-line
  }, [verifyTokenError]);

  const handleSubmit = () => {
    verifyToken(token);
  };

  return (
    <LoginLayout>
      <div className="login__box--right">
        <div className="login__form">
          {/* Logo */}
          <div className="row justify-center">
            <img src={logo} className="mb-30" width="80" alt="EisenVault" />
          </div>

          <div className="login__forms-container">
            {/* Form Start */}
            <section className="login__form--signin">
              {/* Welcome Message */}
              <div className="row mb-30 login__text-welcome justify-center">
                Enter Verification Code
              </div>
              <p className="text-md text-muted mb-30">
                enter the verification code in the textbox below that is shown
                in your authenticator app .
              </p>
              {/* Form Elements */}
              <div className="col my-20">
                <TextInput
                  type="text"
                  id="token"
                  name="token"
                  className="input-lg pl-25"
                  errors={verifyTokenError}
                  iconClass="fas fa-key"
                  iconPosition="left"
                  value={token?.token || ""}
                  placeholder="XXXX-XXXX-XXXX"
                  onChange={e =>
                    setToken({
                      [e.target.id]: e.target.value
                    })
                  }
                />
              </div>
              <div className="row">
                <button
                  disabled={isVerifyTokenLoading}
                  onClick={() => handleSubmit()}
                  className="btn btn--info btn--block btn--lg"
                >
                  Verify Token
                </button>
              </div>
            </section>
            {/* End Form */}
          </div>
        </div>
      </div>
    </LoginLayout>
  );
};

export default VerifyToken;
