import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

// Custom Components
import LoginLayout from "layouts/login/login.layout";
import Loading from "components/loading/loading.component";
import TextInput from "components/form/inputs/text/text-input.component";
import Button from "components/form/buttons/button/button.component";
import logo from "assets/img/logos/logo-md.png";
import notification from "helpers/notification/notification.helper";
import NotificationMessage from "helpers/notification/notification-message.component";

// Hooks
import useHttp from "hooks/useHttp";

// Styles
import "../auth.styles.scss";

const ResetPassword = ({ match, history }) => {
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [postData , setPostData] = useState({})

  const {
    put: resetPassword,
    isLoading: isResetPasswordLoading,
    status: resetPasswordResponseStatus,
    responseNotifications,
    error: resetPasswordError
  } = useHttp("auth/reset-password");

  // Set the token on page load
  useEffect(() => {
    setPostData({
      token: match.params.token
    });
    // eslint-disable-next-line
  }, [match.params.token]);

  // On Successful password reset
  useEffect(() => {
    if (resetPasswordResponseStatus === 200 && responseNotifications) {
      notification.success(responseNotifications);
      setPasswordChanged(true);
    }
    // eslint-disable-next-line
  }, [resetPasswordResponseStatus, responseNotifications]);

  return (
    <LoginLayout>
      <div className="login__box--right">
        <div className="login__form">
          {/* Logo */}
          <div className="row justify-center">
            <Loading className="mb-30" width="75">
                <img src={logo} className="mb-30" width="80" alt="EisenVault" />
            </Loading>
          </div>

          <div className="login__forms-container">
            {/* Form Start */}
            <section
              className={classnames("login__form--signin", {
                hidden: passwordChanged
              })}
            >
              {/* Welcome Message */}
              <div className="row mb-30 login__text-welcome justify-center">
                Create a password
              </div>

              <p className="text-md text-muted mb-30">
                  You'll use this password to sign in to eisenvault
              </p>

              {/* Form Elements */}
              <div className="col my-20">
                <label>Token:</label>
                <TextInput
                  type="text"
                  disabled
                  id="token"
                  name="token"
                  className="input-lg"
                  value={match.params.token}
                  onChange={() => {}}
                  errors={resetPasswordError}
                />
              </div>

              <div className="col my-20">
                <label>New password:</label>
                <TextInput
                  type="password"
                  id="password"
                  name="password"
                  placeholder="password"
                  autoComplete="off"
                  className="input-lg"
                  errors={resetPasswordError}
                  onChange={e =>
                    setPostData({
                      token: match.params.token,
                      password: e.target.value
                    })
                  }
                />
              </div>

              <div>
                <Button
                  id="set-password"
                  disabled={isResetPasswordLoading}
                  onClick={() => resetPassword(postData)}
                  className="btn btn--info btn--block btn--lg btn--block"
                >
                  Set password
                </Button>
              </div>
            </section>
            {/* End Form */}

            <section
              className={classnames({
                hidden: !passwordChanged
              })}
            >
              <h5 className="mb-20">
                <NotificationMessage message={responseNotifications || ""} />
              </h5>
              <Button
                id="go-back-to-login"
                onClick={() => history.push("/")}
                className="btn btn--info btn--lg btn--block"
              >
                Click here to login
              </Button>
            </section>
          </div>
        </div>
      </div>
    </LoginLayout>
  );
};

ResetPassword.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default ResetPassword;
