import * as actions from "./tags.action";

const tagsReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.TAGS_LOADING:
      return {
        ...state,
        [actions.TAGS_LOADING]: action.payload
      };
    case actions.GET_TAGS:
      return {
        ...state,
        [actions.GET_TAGS]: action.payload
      };
    case actions.TAGS_ERRORS:
      return {
        ...state,
        [actions.TAGS_ERRORS]: action.payload
      };
    case actions.ADD_TAGS_LOADING:
      return {
        ...state,
        [actions.ADD_TAGS_LOADING]: action.payload
      };
    case actions.ADD_TAGS:
      return {
        ...state,
        [actions.ADD_TAGS]: action.payload
      };
    case actions.TAG_ADDED:
      return {
        ...state,
        [actions.TAG_ADDED]: action.payload
      };
    case actions.ADD_TAGS_ERRORS:
      return {
        ...state,
        [actions.ADD_TAGS_ERRORS]: action.payload
      };
    case actions.TAG_DELETING:
      return {
        ...state,
        [actions.TAG_DELETING]: action.payload
      };
    case actions.TAG_DELETED:
      return {
        ...state,
        [actions.TAG_DELETED]: action.payload
      };
    case actions.DELETE_TAG:
      return {
        ...state,
        [actions.DELETE_TAG]: action.payload
      };
    case actions.DELETE_TAG_ERROR:
      return {
        ...state,
        [actions.DELETE_TAG_ERROR]: action.payload
      };
    default:
      return state;
  }
};

export default tagsReducer;
