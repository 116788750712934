import React from "react";
import { useHistory } from "react-router-dom";

// Styles
import "./plan-card.styles.scss";
const Plans = ({ plan }) => {
  let history = useHistory();
  return (
    <div className="app__item--plan-card">
      <div className="app__item--plan-name">{plan.plan_name}</div>
      <div className="app__item--plan-type">{plan.plan_type}</div>
      <div className="app__item--plan-property">
        <div className="app__item--plan-property-value">{plan.min_users}</div>{" "}
        Min users
      </div>
      <div className="app__item--plan-property">
        <div className="app__item--plan-property-value">{plan.max_users}</div>{" "}
        Max users
      </div>
      <div className="app__item--plan-property">
        <div className="app__item--plan-property-value">
          {plan.disk_space} GB
        </div>{" "}
        Disk Space
      </div>
      <div className="app__item--plan-property">
        <div className="app__item--plan-property-value">
          {plan.file_previews}
        </div>{" "}
        File Previews
      </div>
      <div className="app__item--plan-property">
        <div className="app__item--plan-property-value">{plan.approvals}</div>{" "}
        Approvals
      </div>
      <div className="app__item--plan-property">
        <div className="app__item--plan-property-value">{plan.reports}</div>{" "}
        Reports
      </div>
      <div className="app__item--plan-property">
        <div className="app__item--plan-property-value">
          {plan.file_size_limit} MB
        </div>{" "}
        Upload limit- Individual File size
      </div>
      <div className="app__item--plan-property">
        {plan.support === "Yes" ? (
          <div className="app__item--plan-property-value">24 hours support</div>
        ) : (
          <div className="app__item--plan-property-value">{plan.support}</div>
        )}
      </div>
      <hr></hr>
      <div className="plan__properties">
        {plan.phone_apps ? (
          <div className="plan__property-true">
            <i className="far fa-check-circle right"></i>
          </div>
        ) : (
          <div className="plan__property-false">
            <i className="far fa-times-circle"></i>
          </div>
        )}{" "}
        Phone Apps
      </div>
      <div className="plan__properties">
        {plan.offline_sync ? (
          <div className="plan__property-true">
            <i className="far fa-check-circle right"></i>
          </div>
        ) : (
          <div className="plan__property-false">
            <i className="far fa-times-circle"></i>
          </div>
        )}{" "}
        OfflineSync
      </div>
      <div className="plan__properties">
        {plan.version_control ? (
          <div className="plan__property-true">
            <i className="far fa-check-circle right"></i>
          </div>
        ) : (
          <div className="plan__property-false">
            <i className="far fa-times-circle"></i>
          </div>
        )}{" "}
        Version Control
      </div>
      <div className="plan__properties">
        {plan.document_tagging ? (
          <div className="plan__property-true">
            <i className="far fa-check-circle right"></i>
          </div>
        ) : (
          <div className="plan__property-false">
            <i className="far fa-times-circle"></i>
          </div>
        )}{" "}
        Document Tagging
      </div>
      <div className="plan__properties">
        {plan.auto_document_categorisation ? (
          <div className="plan__property-true">
            <i className="far fa-check-circle right"></i>
          </div>
        ) : (
          <div className="plan__property-false">
            <i className="far fa-times-circle"></i>
          </div>
        )}{" "}
        Auto Document Categorisation
      </div>
      <div className="plan__properties">
        {plan.two_factor ? (
          <div className="plan__property-true">
            <i className="far fa-check-circle right"></i>
          </div>
        ) : (
          <div className="plan__property-false">
            <i className="far fa-times-circle"></i>
          </div>
        )}{" "}
        TwoFactor
      </div>
      <div className="plan__properties">
        {plan.custom_document_categories ? (
          <div className="plan__property-true">
            <i className="far fa-check-circle right"></i>
          </div>
        ) : (
          <div className="plan__property-false">
            <i className="far fa-times-circle"></i>
          </div>
        )}{" "}
        Custom Document Categories
      </div>
      <div className="plan__properties">
        {plan.ocr ? (
          <div className="plan__property-true">
            <i className="far fa-check-circle right"></i>
          </div>
        ) : (
          <div className="plan__property-false">
            <i className="far fa-times-circle"></i>
          </div>
        )}{" "}
        OCR
      </div>
      <div className="plan__properties">
        {plan.branding ? (
          <div className="plan__property-true">
            <i className="far fa-check-circle right"></i>
          </div>
        ) : (
          <div className="plan__property-false">
            <i className="far fa-times-circle"></i>
          </div>
        )}{" "}
        Branding
      </div>
      <div className="plan__properties">
        {plan.office_integration ? (
          <div className="plan__property-true">
            <i className="far fa-check-circle right"></i>
          </div>
        ) : (
          <div className="plan__property-false">
            <i className="far fa-times-circle"></i>
          </div>
        )}{" "}
        Office Integration
      </div>
      <div className="plan__properties">
        {plan.sso_integration ? (
          <div className="plan__property-true">
            <i className="far fa-check-circle right"></i>
          </div>
        ) : (
          <div className="plan__property-false">
            <i className="far fa-times-circle"></i>
          </div>
        )}{" "}
        SSO Integration
      </div>
      <div className="plan__properties">
        {plan.audit_trails ? (
          <div className="plan__property-true">
            <i className="far fa-check-circle right"></i>
          </div>
        ) : (
          <div className="plan__property-false">
            <i className="far fa-times-circle"></i>
          </div>
        )}{" "}
        Audit Trails
      </div>
      <div className="plan__properties">
        {plan.custom_roles ? (
          <div className="plan__property-true">
            <i className="far fa-check-circle right"></i>
          </div>
        ) : (
          <div className="plan__property-false">
            <i className="far fa-times-circle"></i>
          </div>
        )}{" "}
        Custom Roles
      </div>
      <div className="plan__properties">
        {plan.groups ? (
          <div className="plan__property-true">
            <i className="far fa-check-circle right"></i>
          </div>
        ) : (
          <div className="plan__property-false">
            <i className="far fa-times-circle"></i>
          </div>
        )}{" "}
        Groups
      </div>
      <div className="plan__properties">
        {plan.document_subscription ? (
          <div className="plan__property-true">
            <i className="far fa-check-circle right"></i>
          </div>
        ) : (
          <div className="plan__property-false">
            <i className="far fa-times-circle"></i>
          </div>
        )}{" "}
        Document Subscription
      </div>
      <div className="plan__properties">
        {plan.pdf_annotations ? (
          <div className="plan__property-true">
            <i className="far fa-check-circle right"></i>
          </div>
        ) : (
          <div className="plan__property-false">
            <i className="far fa-times-circle"></i>
          </div>
        )}{" "}
        PDF Annotations
      </div>
      <div className="plan__properties">
        {plan.email_document_from_d_m_s ? (
          <div className="plan__property-true">
            <i className="far fa-check-circle right"></i>
          </div>
        ) : (
          <div className="plan__property-false">
            <i className="far fa-times-circle"></i>
          </div>
        )}{" "}
        Email Document From DMS
      </div>
      <div className="plan__properties">
        {plan.email_document_to_d_m_s ? (
          <div className="plan__property-true">
            <i className="far fa-check-circle right"></i>
          </div>
        ) : (
          <div className="plan__property-false">
            <i className="far fa-times-circle"></i>
          </div>
        )}{" "}
        Email Document To DMS
      </div>
      <div className="plan__properties">
        {plan.external_sharing ? (
          <div className="plan__property-true">
            <i className="far fa-check-circle right"></i>
          </div>
        ) : (
          <div className="plan__property-false">
            <i className="far fa-times-circle"></i>
          </div>
        )}{" "}
        External Sharing
      </div>
      <button
        className="app__item--plan-button"
        onClick={() =>
          history.push({
            pathname: `/plans/${plan.id}/edit`
          })
        }
      >
        Update Plan
      </button>
      <hr></hr>

      {plan.trial_days ? (
        <div className="app__item--plan-info">
          Free trial of {plan.trial_days} Days
        </div>
      ) : null}
      {plan.account_suspension_in_case_of_no_usage ? (
        <div className="app__item--plan-info">
          Account will be suspended after{" "}
          {plan.account_suspension_in_case_of_no_usage} days of no usage
        </div>
      ) : null}
      {plan.account_deletion_in_case_of_no_usage ? (
        <div className="app__item--plan-info">
          Account will be deleted after{" "}
          {plan.account_deletion_in_case_of_no_usage} days of no usage
        </div>
      ) : null}
    </div>
  );
};

export default Plans;
