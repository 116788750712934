import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// Custom components
import * as baseAction from "store/base/base.action";
import * as tagsAction from "store/tags/tags.action";
import BasicModal from "components/modals/base/default/default-modal.component";
import OutsideClickHandler from "react-outside-click-handler";
import notification from "helpers/notification/notification.helper";
import ErrorLabel from "components/form/error-label/error-label.component";

// Styles
import "./manage-tags-modal.styles.scss";

const ManageTagsModal = ({ showManageTagsModal, setManageTagsModal }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [filter, setFilter] = useState({ name: "" });
  const [parameters, setParameters] = useState({
    name: "",
    page: 1,
    limit: 10
  });

  const tagsSuggestions = useSelector(state => state.tags[tagsAction.GET_TAGS]);
  const addTagsResponse = useSelector(state => state.tags[tagsAction.ADD_TAGS]);
  const isAddTagsLoading = useSelector(
    state => state.tags[tagsAction.ADD_TAGS_LOADING]
  );
  const addTagsNotifications = useSelector(
    state => state.tags[tagsAction.TAG_ADDED]
  );
  const addTagsError = useSelector(
    state => state.tags[tagsAction.ADD_TAGS_ERRORS]
  );

  // When the add tags is successful
  useEffect(() => {
    if (addTagsNotifications) {
      notification.success(addTagsNotifications, {
        position: "top-right"
      });
      setManageTagsModal(false);
    }
    dispatch(tagsAction.getTags(parameters));
    dispatch(baseAction.reset(tagsAction.TAG_ADDED));
    dispatch(baseAction.reset(tagsAction.ADD_TAGS_ERRORS));
    // eslint-disable-next-line
  }, [addTagsNotifications, dispatch]);

  // When there is an error deleting the tag
  useEffect(() => {
    if (addTagsError) {
      notification.error(addTagsError, {
        position: "top-right"
      });
    }
    dispatch(baseAction.reset(tagsAction.ADD_TAGS_ERRORS));
    // eslint-disable-next-line
  }, [addTagsError, dispatch]);

  const handleChange = e => {
    const targetValue = e.target.value;
    setFilter(prevState => ({
      ...prevState,
      name: targetValue
    }));
    dispatch(tagsAction.getTags(filter));
    if (e.key === "Enter" && e.target.value.length !== 0) {
      setValues([...values, { name: e.target.value }]);
      setShowDropdown(false);
      mainInput.value = "";
    }
  };

  const handleSubmit = () => {
    dispatch(tagsAction.addTags(values));
    setValues([]);
  };
  const handleCloseButton = () => {
    dispatch(tagsAction.getTags(parameters));
    setManageTagsModal(false)
  }
  return (
    <div className="manage__tags-modal">
      <BasicModal
        width="25vw"
        height="auto"
        handleCloseButton={handleCloseButton}
      >
        {/* title */}
        <h5 className="mb-20">Add Tags</h5>

        {/* input container */}
        <div className="input-group mt-10 w-100">
          <div className="manage__tags-input-container">
            {values.map(value => (
              <div
                key={value.name}
                className="manage__tags-input-list-item-container"
              >
                <li key={value.name} className="manage__tags-input-list-item">
                  {value.name}
                </li>
                <i
                  className={`far fa-times-circle manage__tags-input-list-item-icon`}
                  onClick={() =>
                    setValues(values.filter(item => item.name !== value.name))
                  }
                ></i>
              </div>
            ))}

            <input
              id="mainInput"
              className="manage__tags-input-field"
              placeholder="Add Tags..."
              onKeyUp={handleChange}
              onChange={() => setShowDropdown(true)}
              autoComplete="off"
            ></input>
            <ErrorLabel field="text" errors={addTagsError} />
          </div>

          {/* dropdown */}
          {showDropdown ? (
            <OutsideClickHandler onOutsideClick={() => setShowDropdown(false)}>
              <div className="manage__tags-dropdown-container">
                {tagsSuggestions?.data &&
                  tagsSuggestions.data.map(item => (
                    <li
                      key={item.id}
                      className="manage__tags-dropdown-list-item"
                    >
                      {item.name}
                    </li>
                  ))}
              </div>{" "}
            </OutsideClickHandler>
          ) : null}
        </div>

        {/* button */}
        <div className="input-group mt-10 w-100">
          <button
            className="btn btn--primary btn--xl btn--block"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </BasicModal>
    </div>
  );
};

ManageTagsModal.propTypes = {
  showManageTagsModal: PropTypes.bool.isRequired,
  setManageTagsModal: PropTypes.func.isRequired
};

export default ManageTagsModal;
