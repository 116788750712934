// Custom Components
import http from "helpers/http/http.helper";

// GET TIMEZONES
export const TIMEZONES_LOADING = "TIMEZONES_LOADING";
export const GET_TIMEZONES = "GET_TIMEZONES";
export const TIMEZONES_ERRORS = "TIMEZONES_ERRORS";

// TIMEZONES
export const getTimezones = params => async dispatch => {
  try {
    dispatch({
      type: TIMEZONES_LOADING,
      payload: true
    });
    const response = await http().get(`/timezones`, { params });
    dispatch({
      type: GET_TIMEZONES,
      payload: response.data
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: TIMEZONES_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: TIMEZONES_LOADING,
      payload: false
    });
  }
};
