import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

//Custom Components
import * as baseAction from "store/base/base.action";
import * as customersAction from "store/customers/customers.action";
import * as tagsAction from "store/tags/tags.action";
import * as domainsAction from "store/domains/domains.action";
import * as localesAction from "store/locales/locales.action";
import * as timezonesAction from "store/timezones/timezones.action";
import InputField from "components/input-field/input-field.component";
import DropDownSearch from "components/form/dropdown-search/dropdown-search.component";
import Tag from "components/form/dropdown-search/ dropdown-search-tag/dropdown-search-tag.component";
import Button from "components/form/buttons/button/button.component";
import TextInput from "components/form/inputs/text/text-input.component";

// Styles
import "./customer-info.styles.scss";

const CustomerInfo = ({
  customer,
  setCustomer,
  setShowAddContactPersonModal,
  handleRemoveContactPerson,
  handleCustomerFormSubmit,
  customerCreated
}) => {
  const dispatch = useDispatch();
  const [tag, setTagName] = useState();
  const [filter, setFilter] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);

  // Load the initial customer details in the local store
  useEffect(() => {
    dispatch(tagsAction.getTags(filter));
    dispatch(timezonesAction.getTimezones(filter));
    dispatch(localesAction.getLocales(filter));
    dispatch(domainsAction.getDomains(filter));
    // eslint-disable-next-line
  }, []);

  const addCustomerResponse = useSelector(
    state => state.customers[customersAction.ADD_CUSTOMER]
  );
  const isCustomerLoading = useSelector(
    state => state.customers[customersAction.CUSTOMER_ADD_LOADING]
  );
  const customerAddErrors = useSelector(
    state => state.customers[customersAction.CUSTOMER_ADD_ERROR]
  );
  const isCustomerUpdateLoading = useSelector(
    state => state.customers[customersAction.CUSTOMER_UPDATING]
  );
  const customerUpdatedResponse = useSelector(
    state => state.customers[customersAction.UPDATE_CUSTOMER]
  );
  const updateCustomerErrors = useSelector(
    state => state.customers[customersAction.UPDATE_CUSTOMER_ERROR]
  );
  const tags = useSelector(state => state.tags[tagsAction.GET_TAGS]);
  const getTagsError = useSelector(state => state.tags[tagsAction.TAGS_ERRORS]);
  const isTagsLoading = useSelector(
    state => state.tags[tagsAction.TAGS_LOADING]
  );
  const addTagsResponse = useSelector(state => state.tags[tagsAction.ADD_TAGS]);
  const timezones = useSelector(
    state => state.timezones[timezonesAction.GET_TIMEZONES]
  );
  const isTimezonesLoading = useSelector(
    state => state.timezones[timezonesAction.TIMEZONES_LOADING]
  );
  const domains = useSelector(
    state => state.domains[domainsAction.GET_DOMAINS]
  );
  const isDomainsLoading = useSelector(
    state => state.domains[domainsAction.DOMAINS_LOADING]
  );
  const locales = useSelector(
    state => state.locales[localesAction.GET_LOCALES]
  );
  const isLocalesLoading = useSelector(
    state => state.locales[localesAction.LOCALES_LOADING]
  );

  const handleAddTag = tag => {
    if (!tag) return;
    // Check if the tag already exists
    const tagExists = customer?.tags?.some(t => t.name === tag.name);
    if (tagExists) return;

    setCustomer({
      ...customer,
      tags: [...customer?.tags, tag]
    });
    setShowDropdown(false);
  };

  const handleRemoveTag = tag => {
    if (!tag) return;
    const newTags = customer?.tags?.filter(
      tagItem => tagItem.name !== tag.name
    );
    setCustomer({
      ...customer,
      tags: newTags
    });
  };

  const addNewTag = async value => {
    dispatch(tagsAction.addTags([{ name: value }]));
    setTagName(value);
    setShowDropdown(false);
  };

  useEffect(() => {
    if (addTagsResponse?.notifications) {
      handleAddTag({
        id: addTagsResponse?.data?.upserted[0]?.id,
        name: tag
      });
      dispatch(baseAction.reset(tagsAction.ADD_TAGS));
    }
  }, [addTagsResponse]);

  const handleChange = e => {
    const targetValue = e.target.value;
    setFilter(prevState => ({
      ...prevState,
      name: targetValue
    }));
    dispatch(tagsAction.getTags(filter));
    if (e.key === "Enter" && e.target.value.length !== 0) {
      addNewTag(e.target.value);
    }
    e.target.value = "";
  };

  return (
    <>
      {/* Contents */}
      <div className="customer">
        <div className="input-group mb-20">
          <div className="input-group mb-20">
            <label>
              Company Full Legal name
              <span className="text-danger">*</span>
            </label>
            <TextInput
              type="text"
              id="company_full_legal_name"
              name="company_full_legal_name"
              className="input-xxl pl-25"
              errors={customerAddErrors || updateCustomerErrors}
              iconClass="far fa-building"
              iconPosition="left"
              value={customer?.company_full_legal_name || ""}
              onChange={e => {
                const targetValue = e.target.value;
                setCustomer(prevState => ({
                  ...prevState,
                  company_full_legal_name: targetValue
                }));
              }}
            />
          </div>
          <div className="input-group mb-20">
            <label>
              Company Display Name
              <span className="text-danger">*</span>
            </label>
            <TextInput
              type="text"
              id="company_display_name"
              name="company_display_name"
              className="input-xxl pl-25"
              errors={customerAddErrors || updateCustomerErrors}
              iconClass="far fa-building"
              iconPosition="left"
              value={customer?.company_display_name || ""}
              onChange={e => {
                const targetValue = e.target.value;
                setCustomer(prevState => ({
                  ...prevState,
                  company_display_name: targetValue
                }));
              }}
            />
          </div>
          <div className="input-group mb-20">
            <label>
              Customer Subdomain
              <span className="text-danger">*</span>
            </label>
            <div className="customer__product-type">
              <div className="input-items">
                <TextInput
                  type="text"
                  id="customer_domain"
                  name="customer_domain"
                  className="input-xxl pl-25"
                  errors={customerAddErrors || updateCustomerErrors}
                  iconClass="fa fa-server"
                  readOnly={customerCreated}
                  iconPosition="left"
                  value={customer?.subdomain || ""}
                  onChange={e => {
                    const subdomain = e.target.value;
                    setCustomer(prevState => ({
                      ...prevState,
                      subdomain
                    }));
                  }}
                />
                <div className="text-muted">Cannot be changed later</div>
              </div>

              <div className="input-items">
                <DropDownSearch
                  title={(() => {
                    let title = "Domain";
                    if (customer?.domain) {
                      title = customer?.domain;
                    }
                    return title;
                  })()}
                  options={domains?.data?.map(domain => ({
                    id: domain.id,
                    label: domain.id,
                    value: domain.id,
                    data: domain
                  }))}
                  onSelect={item => {
                    const targetValue = item.value;
                    setCustomer(prevState => ({
                      ...prevState,
                      domain: targetValue
                    }));
                  }}
                  showSearch={false}
                  errorField="domain"
                  errors={customerAddErrors || updateCustomerErrors}
                />
              </div>
            </div>
          </div>
          <div className="input-group mb-20">
            <label>
              Primary Admin Email
              <span className="text-danger">*</span>
            </label>
            <div className="customer__dms-name">
              <TextInput
                type="text"
                id="primary_admin_email"
                name="primary_admin_email"
                className="input-xxl pl-25"
                errors={customerAddErrors || updateCustomerErrors}
                iconClass="far fa-envelope"
                iconPosition="left"
                readOnly={customerCreated}
                value={customer?.primary_admin_email || ""}
                onChange={e => {
                  const targetValue = e.target.value;
                  setCustomer(prevState => ({
                    ...prevState,
                    primary_admin_email: targetValue
                  }));
                }}
              />
              <div className="text-muted">
                Primary admin password will be autogenerated & emailed to user
              </div>
            </div>
          </div>
          <div className="input-group mb-20">
            <label>
              Primary Admin First Name
              <span className="text-danger">*</span>
            </label>
            <div className="customer__organisation">
              <TextInput
                type="text"
                id="primary_admin_firstname"
                name="primary_admin_firstname"
                className="input-xxl pl-25"
                errors={customerAddErrors || updateCustomerErrors}
                iconClass="far fa-user"
                iconPosition="left"
                value={customer?.primary_admin_firstname || ""}
                onChange={e => {
                  const targetValue = e.target.value;
                  setCustomer(prevState => ({
                    ...prevState,
                    primary_admin_firstname: targetValue
                  }));
                }}
              />
            </div>
          </div>
          <div className="input-group mb-20">
            <label>
              Primary Admin Last Name
              <span className="text-danger">*</span>
            </label>
            <div className="customer__website">
              <TextInput
                type="text"
                id="primary_admin_lastname"
                name="primary_admin_lastname"
                className="input-xxl pl-25"
                errors={customerAddErrors || updateCustomerErrors}
                iconClass="far fa-user"
                iconPosition="left"
                value={customer?.primary_admin_lastname || ""}
                onChange={e => {
                  const targetValue = e.target.value;
                  setCustomer(prevState => ({
                    ...prevState,
                    primary_admin_lastname: targetValue
                  }));
                }}
              />
            </div>
          </div>

          <div className="input-group mb-20">
            <label>
              Storage
              <span className="text-danger">*</span>
            </label>
            <div className="customer__website">
              <TextInput
                type="text"
                id="primary_admin_storage"
                name="primary_admin_storage"
                className="input-xxl pl-25"
                errors={customerAddErrors || updateCustomerErrors}
                iconClass="fa fa-database"
                iconPosition="left"
                value={customer?.storage_limit || ""}
                onChange={e => {
                  const targetValue = e.target.value;
                  setCustomer(prevState => ({
                    ...prevState,
                    storage_limit: targetValue
                  }));
                }}
              />
              <div className="text-muted">storage want to allocate in GB's</div>
            </div>
          </div>
        </div>
        <div className="input-group mb-20">
          <label>Default Time Zone</label>
          <DropDownSearch
            title={(() => {
              let title = "Default Time Zone";
              if (customer?.default_time_zone) {
                title = customer.default_time_zone;
              }
              return title;
            })()}
            onSearch={value =>
              dispatch(timezonesAction.getTimezones({ name: value }))
            }
            onBlur={() => dispatch(timezonesAction.getTimezones({}))}
            options={timezones?.data?.map(timezone => ({
              id: timezone.id,
              label: timezone.name,
              value: timezone.name,
              data: timezone
            }))}
            onSelect={item => {
              const targetValue = item.value;
              setCustomer(prevState => ({
                ...prevState,
                default_time_zone: targetValue
              }));
            }}
            showSearch={true}
            errorField="default_time_zone"
            errors={customerAddErrors || updateCustomerErrors}
          />
        </div>

        <div className="input-group mb-20">
          <label>Default Language</label>
          <DropDownSearch
            title={(() => {
              let title = "Default Language";
              if (customer?.default_language) {
                title = customer.default_language;
              }
              return title;
            })()}
            onSearch={value =>
              dispatch(localesAction.getLocales({ name: value }))
            }
            onBlur={() => dispatch(localesAction.getLocales({}))}
            options={locales?.data?.map(locale => ({
              id: locale.id,
              label: locale.name,
              value: locale.name,
              data: locale
            }))}
            onSelect={item => {
              const targetValue = item.value;
              setCustomer(prevState => ({
                ...prevState,
                default_language: targetValue
              }));
            }}
            errorField="default_language"
            errors={customerAddErrors || updateCustomerErrors}
          />
        </div>

        <div className="input-group mb-20">
          <label>Tags</label>
          <TextInput
            type="text"
            id="tags"
            name="primary_admin_lastname"
            className="input-xxl pl-25"
            errors={customerAddErrors || updateCustomerErrors}
            placeholder="Add Tags..."
            onKeyUp={handleChange}
            onChange={() => setShowDropdown(true)}
            autoComplete="off"
          />
          {showDropdown ? (
            <div className="add__customer-dropdown-container">
              {tags?.data &&
                tags?.data.map(item => (
                  <li
                    key={item.id}
                    className="add__customer-dropdown-list-item"
                    onClick={() => handleAddTag(item)}
                  >
                    {item.name}
                  </li>
                ))}
            </div>
          ) : null}
        </div>

        {customer?.tags?.length > 0 && (
          <div className="app__user--add-tags mt-20 mb-20">
            {customer?.tags?.map(tag => (
              <Tag
                key={tag?.id}
                label={tag?.name}
                tag={tag}
                onRemove={handleRemoveTag}
                wrapperClass="mr-10"
              />
            ))}
          </div>
        )}
        <div className="input-group mb-20">
          <label>Contact Persons</label>
          <InputField
            setShowAddContactPersonModal={setShowAddContactPersonModal}
            customer={customer}
            handleRemoveContactPerson={handleRemoveContactPerson}
            errors={customerAddErrors || updateCustomerErrors}
          />
        </div>
      </div>

      {/* End Body */}
      <div className="row justify-center mb-40 mt-40">
        <Button
          disabled={isCustomerLoading || isCustomerUpdateLoading}
          id="intro"
          className="btn btn--info"
          onClick={() => handleCustomerFormSubmit(customer, customerCreated)}
        >
          Add Subscription and Billing Information
        </Button>
      </div>
    </>
  );
};

CustomerInfo.propTypes = {
  customer: PropTypes.object.isRequired,
  setCustomer: PropTypes.func.isRequired,
  setShowAddContactPersonModal: PropTypes.func.isRequired,
  handleRemoveContactPerson: PropTypes.func.isRequired,
  handleCustomerFormSubmit: PropTypes.func.isRequired,
  customerCreated: PropTypes.bool.isRequired
};

export default CustomerInfo;
