import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";

// Custom Components
import * as usersAction from "store/users/users.action";
import IconAvatar from "components/icons/icon-avatar/icon-avatar.component";

// Hooks
import useHttp from "hooks/useHttp";
import useLocalStorage, {
  EV_USER,
  EV_REMEMBER_ME,
  EV_TOKEN
} from "hooks/useLocalStorage";
// Styles
import "./profile-menu.styles.scss";

const ProfileMenu = ({ showMenu }) => {
  const history = useHistory();
  const loggedUserProfile = useSelector(
    state => state.users[usersAction.LOGGED_USER_PROFILE]
  );
  const [, setLoggedUser] = useLocalStorage(EV_USER, null);
  const [, setRememberMe] = useLocalStorage(EV_REMEMBER_ME);
  const [, setToken] = useLocalStorage(EV_TOKEN);

  const {
    post: logout,
    isLoading: isLogoutLoading,
    status: logoutResponseStatus
  } = useHttp("/auth/logout");

  // On Successful logout
  useEffect(() => {
    if (logoutResponseStatus === 200) {
      setRememberMe(0);
      setToken("");
      setLoggedUser("");
      history.push("/");
    }
    // eslint-disable-next-line
  }, [logoutResponseStatus]);

  return (
    <div
      className={classnames("app__profile--menu", {
        hide: !showMenu
      })}
    >
      <div className="container1">
        <div className="app__profile--header-container">
          <div className="app__profile--avatar mr-10">
            <IconAvatar
              source={`${loggedUserProfile?.firstname}${loggedUserProfile?.lastname}`}
              alt={`${loggedUserProfile?.firstname}${loggedUserProfile?.lastname}`}
              size="md"
            />
          </div>
          <div className="app__profile--header-right">
            <div className="app__profile--header-name">{`${loggedUserProfile?.firstname} ${loggedUserProfile?.lastname}`}</div>
            <div className="app__profile--header-email">
              {loggedUserProfile?.email}
            </div>
          </div>
        </div>

        <div className="hr">&nbsp;</div>

        <ul className="app__profile-menu-list">
          <li>
            <Link to={`/user/${loggedUserProfile?.id}/edit`}>Edit Profile</Link>
          </li>
          <li>
            <Link to={`/user/${loggedUserProfile?.id}/change-password`}>
              Change Password
            </Link>
          </li>
          <li>
            <Link to={`/user/${loggedUserProfile?.id}/security`}>
              Two-Factor Authentication
            </Link>
          </li>
          <li>
            <Link to="#" onClick={() => logout()}>
              {isLogoutLoading ? "Logging out..." : "Logout"}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProfileMenu;
