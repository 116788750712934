import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import * as baseAction from "store/base/base.action";
import * as usersAction from "store/users/users.action";
import DrawerModal from "components/modals/base/drawer/drawer-modal.component";
import Radio from "components/form/radio/radio.component";
import TextInput from "components/form/inputs/text/text-input.component";
import UserAddFooter from "./user-add-footer.component";
import notification from "helpers/notification/notification.helper";
import { generatePassword } from "helpers/password/password.helper";

const UserAdd = ({
  showAddModal,
  setShowAddModal,
  userFilters,
  setUserFilters
}) => {
  const dispatch = useDispatch();

  // Initialize the user data
  const initUser = {
    email: "",
    firstname: "",
    lastname: "",
    job_title: "",
    password: generatePassword(16),
    autogenerate_password: true,
    is_two_factor_enabled: false
  };

  const [isSingleCreate, setIsSingleCreate] = useState(null);

  const [user, setUser] = useState({});

  // Load the initial user details in the local store
  useEffect(() => {
    setUser(initUser);
  }, []);

  const addUserResponse = useSelector(
    state => state.users[usersAction.ADD_USER]
  );
  const isUserLoading = useSelector(
    state => state.users[usersAction.USER_LOADING]
  );
  const userErrors = useSelector(
    state => state.users[usersAction.USER_ADD_ERROR]
  );

  // When the user creation is successful
  useEffect(() => {
    if (addUserResponse?.notifications) {
      notification.success(addUserResponse.notifications, {
        position: "top-left"
      });
      setUserFilters(prevState => ({
        ...prevState,
        page: 1
      }));

      // Reset the form
      setUser(initUser);

      if (isSingleCreate) {
        setShowAddModal(false);
      } else if (isSingleCreate === false) {
        // For multi user create just reset the value
        setUser(prevState => ({
          ...prevState,
          password: generatePassword(16)
        }));
      }
    }
    dispatch(baseAction.reset(usersAction.ADD_USER));
    // eslint-disable-next-line
  }, [addUserResponse]);

  // When there is an error updating the profile
  useEffect(() => {
    if (userErrors && !addUserResponse) {
      notification.error(userErrors, {
        position: "top-left"
      });
    }
    // eslint-disable-next-line
  }, [userErrors, dispatch]);

  const handleCreateOne = () => {
    dispatch(usersAction.addUser(user));
    setIsSingleCreate(true);
  };

  const handleCreateMultiple = () => {
    dispatch(usersAction.addUser(user));
    setIsSingleCreate(false);
  };

  const handleCancel = () => {
    setUser(initUser);
    dispatch(baseAction.reset(usersAction.USER_ADD_ERROR));
    setShowAddModal(false);
  };

  return (
    <div>
      <DrawerModal.Dialog
        title="Add new user"
        showModal={showAddModal}
        setShowModal={setShowAddModal}
      >
        <DrawerModal.Body
          Footer={() =>
            UserAddFooter({
              isUserLoading,
              handleCreateOne,
              handleCreateMultiple,
              handleCancel,
              showAddModal
            })
          }
        >
          {/* Firstname */}
          <label htmlFor="firstname" className="mt-20 mb-5 block text-md">
            First name
          </label>
          <TextInput
            type="text"
            id="firstname"
            name="firstname"
            className="input-lg pl-25"
            errors={userErrors}
            iconClass="far fa-user"
            iconPosition="left"
            value={user?.firstname || ""}
            onChange={e => {
              const targetValue = e.target.value;
              setUser(prevState => ({
                ...prevState,
                firstname: targetValue
              }));
            }}
          />

          {/* Lastname */}
          <label htmlFor="lastname" className="mt-20 mb-5 block text-md">
            Last name
          </label>
          <TextInput
            type="text"
            id="lastname"
            name="lastname"
            className="input-lg pl-25"
            errors={userErrors}
            iconClass="far fa-user"
            iconPosition="left"
            value={user?.lastname || ""}
            onChange={e => {
              const targetValue = e.target.value;
              setUser(prevState => ({
                ...prevState,
                lastname: targetValue
              }));
            }}
          />

          {/* Email */}
          <label htmlFor="email" className="mt-20 mb-5 block text-md">
            Email
          </label>
          <TextInput
            type="text"
            id="email"
            name="email"
            className="input-lg pl-25"
            errors={userErrors}
            iconClass="fas fa-at"
            iconPosition="left"
            value={user?.email || ""}
            onChange={e => {
              const targetValue = e.target.value;
              setUser(prevState => ({
                ...prevState,
                email: targetValue
              }));
            }}
          />
          <div className="help text-muted">
            The user will use this to login to their account
          </div>

          {/* title */}
          <label htmlFor="job_title" className="mt-20 mb-5 block text-md">
            Job Title
          </label>
          <TextInput
            type="text"
            id="job_title"
            name="job_title"
            className="input-lg pl-25"
            errors={userErrors}
            iconClass="fas fa-user-tie"
            iconPosition="left"
            value={user?.job_title || ""}
            onChange={e => {
              const targetValue = e.target.value;
              setUser(prevState => ({
                ...prevState,
                job_title: targetValue
              }));
            }}
          />

          {/* Password */}
          <label htmlFor="quota" className="mt-20 mb-5 block text-md">
            Password
          </label>
          <TextInput
            type={user?.autogenerate_password ? "text" : "password"}
            id="password"
            name="password"
            className="input-lg pl-25"
            errors={userErrors}
            iconClass="fas fa-key"
            iconPosition="left"
            value={user?.password || ""}
            onChange={e => {
              const targetValue = e.target.value;
              setUser(prevState => ({
                ...prevState,
                password: targetValue
              }));
            }}
          />

          {/* Line Break */}
          <div className="hr my-20"></div>

          <div className="row justify-between align-center mt-20">
            <label className="text-md" id="autogeneratePassword">
              Autogenerate Password:
            </label>
            <Radio
              defaultChecked={user.autogenerate_password}
              handleOnClick={checked => {
                setUser(prevState => ({
                  ...prevState,
                  password: checked ? "" : generatePassword(16),
                  autogenerate_password: !user.autogenerate_password
                }));
              }}
              hasTabIndex={showAddModal}
              ariaLabelledBy="autogeneratePassword"
            />
          </div>
        </DrawerModal.Body>
      </DrawerModal.Dialog>
    </div>
  );
};

UserAdd.propTypes = {
  showAddModal: PropTypes.bool,
  setShowAddModal: PropTypes.func,
  userFilters: PropTypes.object,
  setUserFilters: PropTypes.func
};

export default UserAdd;
