import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Link, NavLink, useHistory, useParams } from "react-router-dom";

// Custom Components
import * as baseAction from "store/base/base.action";
import * as subscriptionsAction from "store/subscriptions/subscriptions.action";
import DefaultLayout from "layouts/default/default.layout";
import SubscriptionAndBillingInfo from "../add-customer/subscription-and-billing-info/subscription-and-billing-info.container";
import notification from "helpers/notification/notification.helper";
import useLocalStorage, { EV_CUSTOMER_ID } from "hooks/useLocalStorage";

const AddSubscription = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const customerId = params.id;

  //Initial Subscription And Billing Data
  const initSubscriptionAndBillingInfo = {
    customer: customerId,
    plan: "",
    currency: "",
    subscription_status: "",
    start_date: "",
    end_date: "",
    increment_percentage: "",
    amount: "",
    payment_mode: "",
    payment_mode_details: "",
    coupon: ""
  };

  const [localCustomerId, setCustomerId] = useLocalStorage(
    EV_CUSTOMER_ID,
    null
  );
  const [subscriptionAndBilling, setSubscriptionAndBilling] = useState(
    initSubscriptionAndBillingInfo
  );

  const addSubscriptionResponse = useSelector(
    state => state.subscriptions[subscriptionsAction.ADD_SUBSCRIPTION]
  );
  const isSubscriptionLoading = useSelector(
    state => state.subscriptions[subscriptionsAction.SUBSCRIPTION_ADD_LOADING]
  );
  const subscriptionErrors = useSelector(
    state => state.subscriptions[subscriptionsAction.SUBSCRIPTION_ADD_ERROR]
  );

  // When the component is unloaded, reset the error messages
  useEffect(() => {
    return () => {
      dispatch(baseAction.reset(subscriptionsAction.ADD_SUBSCRIPTION));
      dispatch(baseAction.reset(subscriptionsAction.SUBSCRIPTION_ADD_ERROR));
    };
  }, [dispatch]);

  // When the subscription creation is successful
  useEffect(() => {
    if (addSubscriptionResponse?.notifications) {
      notification.success(addSubscriptionResponse.notifications, {
        position: "top-left"
      });
      if (customerId == localCustomerId) {
        setCustomerId(null);
      }
      dispatch(baseAction.reset(subscriptionsAction.ADD_SUBSCRIPTION));
      dispatch(baseAction.reset(subscriptionsAction.SUBSCRIPTION_ADD_ERROR));
      history.push(`/manage-customers/${customerId}/profile`);
    }
    // eslint-disable-next-line
  }, [addSubscriptionResponse, dispatch]);

  // When there is an error adding the subscription
  useEffect(() => {
    if (subscriptionErrors && !addSubscriptionResponse) {
      notification.error(subscriptionErrors, {
        position: "top-left"
      });
    }
    // eslint-disable-next-line
  }, [subscriptionErrors, dispatch]);

  // When the component is unloaded, reset the error messages
  useEffect(() => {
    return () => {
      dispatch(baseAction.reset(subscriptionsAction.ADD_SUBSCRIPTION));
      dispatch(baseAction.reset(subscriptionsAction.SUBSCRIPTION_ADD_ERROR));
    };
  }, [dispatch]);

  return (
    <DefaultLayout>
      <ReactTooltip />
      <div className="container">
        {/* Content Header */}
        <div className="app__data--listing-header">
          <div className="app__data--listing-header-top mb-20">
            <div className="app__data--listing-header-left-col">
              <div className="app__data--listing-title">
                <Link to="/manage-customers" className="mr-10">
                  <i
                    className="fas fa-long-arrow-alt-left"
                    data-tip="Back to Manage Customers"
                  ></i>
                </Link>
                Add Subscription
              </div>
            </div>
          </div>
        </div>

        <div className="customer-info card">
          {/* Tabs */}
          <div className="add-customer__tabs">
            <ul>
              <li>
                <NavLink
                  exact
                  activeClassName="add-customer__active"
                  to="/manage-customers/subscription-and-billing-info"
                >
                  Subscription And Billing Information
                </NavLink>
              </li>
            </ul>
          </div>
          <SubscriptionAndBillingInfo
            subscriptionAndBilling={subscriptionAndBilling}
            setSubscriptionAndBilling={setSubscriptionAndBilling}
          />
        </div>
      </div>
    </DefaultLayout>
  );
};

export default AddSubscription;
