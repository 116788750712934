import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";

import { TAB_VERIFY, TAB_SETUP } from "../user-2fa.container";
import TwoFactorTwo from "assets/img/illustrations/2fa-2.jpg";
import Button from "components/form/buttons/button/button.component";

const TwoFactorSetup = ({ activeTab, setActiveTab }) => {
  return (
    <div
      className={classnames(
        "app__2fa--content",
        {
          "app__2fa--content-active": activeTab === TAB_SETUP
        },
        {
          "app__2fa--content-inactive": activeTab === TAB_VERIFY
        }
      )}
    >
      <img className="hero" src={TwoFactorTwo} alt="Two Factor Step 2" />

      <p className="app__2fa--content-text mt-30">
        You'll need an authenticator app on your mobile phone to generate your authentication codes.
        We recommend
        {" "}
        <Link
          target="_blank"
          to="//support.google.com/accounts/answer/1066447?hl=en"
        >
          Google Authenticator
        </Link>
        ,
        A free app available for most phones.
      </p>

      <div className="row justify-center mt-40">
        <Button
          id="setup"
          className="btn btn--info"
          onClick={() => setActiveTab(TAB_VERIFY)}
        >
          Ready To Setup
        </Button>
      </div>
    </div>
  );
};

TwoFactorSetup.propTypes = {
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func.isRequired
};

export default TwoFactorSetup;
