import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import classnames from "classnames";

// Styles
import "./dropdown.styles.scss";

export const CHECKBOX = "checkbox";
export const RADIO = "radio";
export const NONE = "none";

const DropDown = ({
  title = "Filter",
  disabled = false,
  options = [],
  handleFilter,
  inputType = CHECKBOX
}) => {
  const [visible, setVisible] = useState(false);

  // Add eventlistener to close the dropdown
  useEffect(() => {
    const handleCloseComponent = e => {
      const TARGET_CLASSNAME = "app__dropdown";
      if (
        typeof e.target.className.includes === "function" &&
        !e.target.className.includes(TARGET_CLASSNAME)
      ) {
        setVisible(false);
      }
    };

    window.document.addEventListener("click", handleCloseComponent);

    return () =>
      window.document.removeEventListener("click", handleCloseComponent);
  }, []);

  const onKeyDownHandler = e => {
    if (e.which === 13) {
      setVisible(!visible);
    }
  };

  return (
    <div
      className={classnames("app__dropdown", {
        disabled: disabled
      })}
      tabIndex="0"
      role="button"
      aria-haspopup="true"
      aria-expanded={visible ? "true" : "false"}
      onKeyDown={onKeyDownHandler}
    >
      <div
        className="app__dropdown--title cp"
        onClick={() => {
          if (disabled) {
            setVisible(false);
          } else {
            setVisible(!visible);
          }
        }}
      >
        <div className="app__dropdown--inner">
          <i className="fas fa-filter app__dropdown--icon-left mr-5"></i>
          {title}
        </div>
        <i className="fas fa-caret-down ml-7 app__dropdown--icon-right"></i>
      </div>

      <motion.ul
        className="app__dropdown-menu-list mt-5"
        animate={{
          opacity: visible ? 1 : 0,
          visibility: visible ? "visible" : "hidden"
        }}
        onKeyDown={e => (e.which === 27 ? setVisible(false) : null)}
      >
        {options &&
          options.map(item => (
            <li key={item.value}>
              <div className="app__dropdown--form row cp">
                <label
                  className="app__dropdown--form light cp"
                  htmlFor={item.value}
                >
                  {inputType === NONE ? null : (
                    <input
                      type={inputType}
                      className="mr-5 app__dropdown--form"
                      value={item.value}
                      checked={item.checked}
                      onClick={e =>
                        handleFilter({
                          ...item,
                          checked: e.target.checked
                        })
                      }
                      id={item.value}
                      name={title}
                      onChange={() => {}}
                    />
                  )}

                  {item.label}
                </label>
              </div>
            </li>
          ))}
      </motion.ul>
    </div>
  );
};

export default DropDown;
