import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Custom Components
import http from "helpers/http/http.helper";
import useLocalStorage, {
  EV_REMEMBER_ME,
  EV_TOKEN
} from "hooks/useLocalStorage";

const useToken = () => {
  const history = useHistory();
  const [rememberMe] = useLocalStorage(EV_REMEMBER_ME);
  const [token] = useLocalStorage(EV_TOKEN, null);
  const [isTokenLoading, setIsTokenLoading] = useState(false);
  const [tokenResponse, setTokenResponse] = useState(null);
  const [nextPath, setNextPath] = useState(undefined);
  const [isTokenInvalid, setTokenInvalid] = useState(null);

  // Initiate token authentication
  useEffect(() => {
    _handleTokenAuthentication();
    // eslint-disable-next-line
  }, []);

  // Make request to authenticate the saved token
  useEffect(() => {
    if (rememberMe === true && token) {
      _handleTokenAuthentication();
    } else {
      setTokenResponse(null);
      setNextPath(null);
    }
    // eslint-disable-next-line
  }, [rememberMe, token]);

  // When we have a token response from the api server
  useEffect(() => {
    if (rememberMe === true && tokenResponse?.status === 200) {
      if (tokenResponse?.data?.two_factor_profile_locked === false) {
        setNextPath("/manage-customers");
      } else {
        setNextPath("/");
      }
    } else if (tokenResponse?.status === 400) {
      setNextPath(null);
    }
    // eslint-disable-next-line
  }, [tokenResponse]);

  async function _handleTokenAuthentication() {
    try {
      setIsTokenLoading(true);
      const response = await http().post("/auth/token", {
        token
      });
      setTokenResponse(response.data);
    } catch (error) {
      setNextPath(null);
      // If its an unauthorized request, redirect to login page
      if (error?.response?.status === 421) {
        history.push("/");
      } else if (error?.response?.status === 401) {
        setTokenInvalid(true);
      }
    } finally {
      setIsTokenLoading(false);
    }
  }

  return {
    isTokenLoading,
    isTokenInvalid,
    tokenResponse,
    nextPath
  };
};

export default useToken;
