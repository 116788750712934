import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Protected Routes
import ProtectedRoute from "hoc/protected-routes/protected-routes.hoc";

// Containers
import LoginContainer from "containers/auth/login/login.container";
import VerifyTokenContainer from "containers/auth/verify-token/verify-token.container";
import ResetPasswordContainer from "containers/auth/reset-password/reset-password.container";

import CustomersContainer from "containers/customers/customers.container";
import CustomerUsersContainer from "./containers/customer-users/customer-users.container";
import AccountContainer from "containers/customers/customer-account/customer-account.container";
import AccountUsageContainer from "containers/account-usage/account-usage.container";
import PlansContainer from "containers/plans/plans.container";
import UpdatePlanContainer from "containers/plans/update-plan/update-plan.container";
import AddTagsContainer from "./containers/manage-tags/add-tags/add-tags.container";
import UserAccountContainer from "containers/users/user-account/user-account.container";
import UsersListingContainer from "containers/users/user-listing/user-listing.container";
import AddCustomerContainer from "containers/customers/add-customer/add-customer.container";
import AddSubscriptionContainer from "containers/customers/add-subscription/add-subscription.container"
import EditCustomerContainer from "containers/customers/edit-customer/edit-customer.container";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LoginContainer} />
        <Route
          path="/reset-password/:token"
          component={ResetPasswordContainer}
        />
        <Route exact path="/verify-token" component={VerifyTokenContainer} />
        <ProtectedRoute
          exact
          path="/manage-customers"
          component={CustomersContainer}
        />
        <ProtectedRoute path="/manage-tags" component={AddTagsContainer} />
        <ProtectedRoute exact path="/user" component={UsersListingContainer} />
        <ProtectedRoute
          path="/manage-customers/add-customer-info"
          component={AddCustomerContainer}
        />
        <ProtectedRoute
          path="/manage-customers/subscription-and-billing-info"
          component={AddCustomerContainer}
        />
        <ProtectedRoute
          path="/manage-customers/:id/users"
          component={CustomerUsersContainer}
        />
        <ProtectedRoute
          exact
          path="/manage-customers/:id/profile"
          component={AccountContainer}
        />
        <ProtectedRoute
          path="/manage-customers/:id/contacts"
          component={AccountContainer}
        />
        <ProtectedRoute
          path="/manage-customers/:id/emails"
          component={AccountContainer}
        />
        <ProtectedRoute
          path="/manage-customers/:id/subscription-history"
          component={AccountContainer}
        />
        <ProtectedRoute
          path="/manage-customers/:id/account-usage-users"
          component={AccountUsageContainer}
        />
        <ProtectedRoute
          path="/manage-customers/:id/account-usage-activities"
          component={AccountUsageContainer}
        />
        <ProtectedRoute
          path="/user/:id/security"
          component={UserAccountContainer}
        />
        <ProtectedRoute
          path="/user/:id/view"
          component={UserAccountContainer}
        />
        <ProtectedRoute
          path="/user/:id/change-password"
          component={UserAccountContainer}
        />
        <ProtectedRoute
          path="/user/:id/edit"
          component={UserAccountContainer}
        />
        <ProtectedRoute exact path="/plans" component={PlansContainer} />
        <ProtectedRoute
          path="/plans/:id/edit"
          component={UpdatePlanContainer}
        />
        <ProtectedRoute
          path="/manage-customers/:id/add-subscription"
          component={AddSubscriptionContainer}
        />
        <ProtectedRoute
          path="/manage-customers/:id/edit-customer"
          component={EditCustomerContainer}
        />
      </Switch>
    </Router>
  );
};

export default App;
