import React from "react";
import { NavLink } from "react-router-dom";

// Styles
import "./add-customer-tabs.styles.scss";

const AddCustomerTabs = () => {
  return (
    <div className="add-customer__tabs">
      <ul>
        <li>
          <NavLink
            exact
            activeClassName="add-customer__active"
            to="/manage-customers/add-customer-info"
          >
            Customer Information
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            activeClassName="add-customer__active"
            to="/manage-customers/subscription-and-billing-info"
          >
            Subscription And Billing Information
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default AddCustomerTabs;
