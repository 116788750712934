import React from "react";


const Button = ({  icon ,color, label, handleButton }) => {


  return (
    <div className="btn--dropdown-wrapper">
      <div >
        <button
          className={`btn--dropdown-form btn btn--${color} btn--icon`}
          onClick={() => handleButton(label)}
        >
        <i className={`btn--dropdown-form ${icon} btn--icon`}></i>
          {label}
        </button>
      </div>
    </div>
  );
};

export default Button;