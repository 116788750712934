import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

// Custom Components
import * as usersAction from "store/users/users.action";
import IconAvatar from "components/icons/icon-avatar/icon-avatar.component";

// Hooks
import useDate from "hooks/useDate";

// Styles
import "./user-account-sidebar.styles.scss";

const UserAccountSideBar = () => {
  const moment = useDate();
  const userProfile = useSelector(
    state => state.users[usersAction.USER_PROFILE]
  );

  return (
    <>
      <div className="app__user--account-sidebar-top">
        <IconAvatar
          source={`${userProfile?.firstname} ${userProfile?.lastname}`}
          alt={`${userProfile?.firstname} ${userProfile?.lastname}`}
          size="xl"
          classes="avatar__border"
        />

        <div className="app__user--account-sidebar-name mt-10">
          {`${userProfile?.firstname} ${userProfile?.lastname}`}
        </div>
        <div className="app__user--account-sidebar-title mt-5">
          {userProfile?.job_title || "No Job Title"}
        </div>
        <div className="app__user--account-sidebar-email mt-10">
          {userProfile?.email}
        </div>
        <div
          data-tip={
            userProfile?.last_login_at
              ? moment(userProfile?.last_login_at).format("LLLL")
              : "--"
          }
          className="app__user--account-sidebar-email-last-logged text-sm mt-15"
        >
          Last logged in:{" "}
          {userProfile?.last_login_at
            ? moment(userProfile?.last_login_at).format("LL")
            : "--"}
        </div>
      </div>

      {/* Bottom Navigations */}
      <div className="app__user--account-sidebar-bottom mt-20">
        <ul>
          <li>
            <NavLink
              to={`/user/${userProfile?.id}/view`}
              activeClassName="app__user--account-sidebar-active"
            >
              <i className="fas fa-user"></i> <span>Profile</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/user/${userProfile?.id}/edit`}
              activeClassName="app__user--account-sidebar-active"
            >
              <i className="fas fa-pen"></i> <span>Edit Profile</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/user/${userProfile?.id}/change-password`}
              activeClassName="app__user--account-sidebar-active"
            >
              <i className="fas fa-key"></i> <span>Change Password</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/user/${userProfile?.id}/security`}
              activeClassName="app__user--account-sidebar-active"
            >
              <i className="fas fa-lock"></i> <span>Security</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default UserAccountSideBar;
