import React, { useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ReactTooltip from "react-tooltip";

// Custom Components
import debounce from "helpers/debounce/debounce";

// Styles
import "./search-collapsable.styles.scss";

const SearchCollapsable = ({
  showSearch,
  setShowSearch = () => {},
  state = null,
  defaultValue = "",
  onChange = () => {},
  dataTip = "Search",
  placeholder = "Type to search..."
}) => {
  const searchInput = useRef("searchInput");

  // Add eventlistener to close the dropdown
  useEffect(() => {
    const handleCloseComponent = e => {
      const TARGET_CLASSNAME = "app__data--search";
      if (
        typeof e.target.className.includes === "function" &&
        !e.target.className.includes(TARGET_CLASSNAME) &&
        searchInput.current.value === ""
      ) {
        setShowSearch(false);
      }
    };

    window.document.addEventListener("click", handleCloseComponent);

    return () =>
      window.document.removeEventListener("click", handleCloseComponent);
  }, [setShowSearch]);

  const handleSearch = useCallback(
    debounce((targetValue, state) => {
      onChange(targetValue, state);
    }, 700),
    []
  );

  return (
    <div className="app__data--search-wrapper">
      <ReactTooltip />
      <i
        className={classnames(
          "fas fa-search cp app__data--search-icon-primary",
          {
            hidden: showSearch
          }
        )}
        data-tip={dataTip}
        onClick={() => setShowSearch(!showSearch)}
        tabIndex="0"
        aria-label={placeholder}
      ></i>

      <div
        className={classnames("app__data--search-input-wrapper", {
          "app__data--search-input-wrapper-show": showSearch
        })}
      >
        <i className="fas fa-search app__data--search-icon"></i>
        <input
          type="search"
          ref={searchInput}
          className="app__data--search-input input-lg"
          placeholder={placeholder}
          onKeyDown={e => (e.which === 27 ? setShowSearch(false) : null)}
          onChange={e => handleSearch(e.target.value, state)}
          defaultValue={defaultValue || ""}
          tabIndex={showSearch ? "0" : "-1"}
        />
      </div>
    </div>
  );
};

SearchCollapsable.propTypes = {
  showSearch: PropTypes.bool.isRequired,
  setShowSearch: PropTypes.func,
  onChange: PropTypes.func,
  state: PropTypes.any,
  dataTip: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
};

export default SearchCollapsable;