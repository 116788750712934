import React from "react";
import PropTypes from "prop-types";

// Images
import ImageNoGroups from "assets/img/illustrations/no-data/no-groups.svg";
import ImageNoUsers from "assets/img/illustrations/no-data/no-users.svg";
import ImageNoProfile from "assets/img/illustrations/no-data/no-profile.svg";
import ImageNoFiles from "assets/img/illustrations/no-data/no-files.svg";
import ImageNoFolders from "assets/img/illustrations/no-data/no-folders.svg";
import ImageNoDepartments from "assets/img/illustrations/no-data/no-departments.svg";
import ImageNoSearchResults from "assets/img/illustrations/no-data/no-search-results.svg";
import ImageEmptyBox from "assets/img/illustrations/no-data/empty-box.svg";
import ImageTrash from "assets/img/illustrations/no-data/trash.svg";
import ImageNotifications from "assets/img/illustrations/no-data/no-notifications.svg";
import ImageInternalServerError from "assets/img/illustrations/no-data/internal-server-error.svg";
import Button from "components/form/buttons/button/button.component";

// Styles
import "./no-data.styles.scss";

export const NoGroups = ImageNoGroups;
export const NoProfile = ImageNoProfile;
export const NoUsers = ImageNoUsers;
export const NoFiles = ImageNoFiles;
export const NoFolders = ImageNoFolders;
export const NoTrash = ImageTrash;
export const NoDepartments = ImageNoDepartments;
export const NoSearchResults = ImageNoSearchResults;
export const EmptyBox = ImageEmptyBox;
export const NoNotifications = ImageNotifications;
export const InternalServerError = ImageInternalServerError;

const NoDataFound = ({
  title,
  body,
  errors = [],
  wrapperClass = "",
  btnLabel = "",
  btnCallback = () => {},
  imageClass = "hrm-12",
  imageComponent = ImageEmptyBox
}) => {
  return (
    <div className={`app__no-data-found ${wrapperClass}`}>
      <img src={imageComponent} alt="No Data Found" className={imageClass} />
      {title && <h3 className="mt-20">{title}</h3>}
      {body && <p className="mt-20">{body}</p>}
      {btnLabel && (
        <Button
          id="labelAction"
          tabIndex="0"
          onClick={btnCallback}
          className="btn btn--outline-dark mt-20"
        >
          {btnLabel}
        </Button>
      )}

      {errors?.length > 0 && (
        <div className="w-100 text-center">
          <h5 className="mt-30">"error_message_s_received_from_the_server":</h5>
          <ul className="app__no-data-found-errors mt-10">
            {errors.map(error => (
              <li key={error.message} className="text-muted">
                <i className="fas fa-angle-double-right"></i> {error.message}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

NoDataFound.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  errors: PropTypes.array,
  wrapperClass: PropTypes.string,
  btnLabel: PropTypes.string,
  btnCallback: PropTypes.func,
  imageClass: PropTypes.string,
  imageComponent: PropTypes.string
};

export default NoDataFound;
