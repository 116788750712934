import * as actions from "./subscription-status.action";

const subscriptionStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.SUBSCRIPTIONSTATUS_LOADING:
      return {
        ...state,
        [actions.SUBSCRIPTIONSTATUS_LOADING]: action.payload
      };
    case actions.GET_SUBSCRIPTIONSTATUS:
      return {
        ...state,
        [actions.GET_SUBSCRIPTIONSTATUS]: action.payload
      };
    case actions.SUBSCRIPTIONSTATUS_ERRORS:
      return {
        ...state,
        [actions.SUBSCRIPTIONSTATUS_ERRORS]: action.payload
      };
    default:
      return state;
  }
};

export default subscriptionStatusReducer;
