// Custom Components
import http from "helpers/http/http.helper";

// GET SUBSCRIPTIONSTATUS
export const SUBSCRIPTIONSTATUS_LOADING = "SUBSCRIPTIONSTATUS_LOADING";
export const GET_SUBSCRIPTIONSTATUS = "GET_SUBSCRIPTIONSTATUS";
export const SUBSCRIPTIONSTATUS_ERRORS = "SUBSCRIPTIONSTATUS_ERRORS";

// SUBSCRIPTIONSTATUS
export const getSubscriptionStatus = () => async dispatch => {
  try {
    dispatch({
      type: SUBSCRIPTIONSTATUS_LOADING,
      payload: true
    });
    const response = await http().get(`/subscription-status`);
    dispatch({
      type: GET_SUBSCRIPTIONSTATUS,
      payload: response.data
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: SUBSCRIPTIONSTATUS_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: SUBSCRIPTIONSTATUS_LOADING,
      payload: false
    });
  }
};

