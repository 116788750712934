import React, { useState } from "react";
import classnames from "classnames";

// Custom Components
import SearchMenu from "./search-menu/search-menu.component";

// Styles
import "./search.styles.scss";

const Search = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuToggle = () => {
    setShowMenu((prevState) => !prevState);
  };

  const hideMenu = () => setShowMenu(false);

  return (
    <>
      <i
        className="fas fa-search app__header--search-icon"
        id="searchPreviewBtnHeader"
        onClick={handleMenuToggle}
      ></i>
      <input
        type="search"
        onBlur={hideMenu}
        onKeyDown={(e) => {
          if (e.which === 27) {
            hideMenu();
          }
        }}
        className={classnames("app__header--search-form", {
          "app__header--search-form-show": showMenu,
        })}
        id="searchBoxHeader"
        placeholder="Search Master Console"
      />

      {/* Search Box Menu */}
      <SearchMenu showMenu={showMenu} />
    </>
  );
};

export default Search;
