import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

// Custom Components
import * as usersAction from "store/users/users.action";
import DefaultLayout from "layouts/default/default.layout";
import UserAccountSideBar from "./user-account-sidebar/user-account-sidebar.container";
import UserProfileView from "./user-profile-view/user-profile-view.container";
import UserProfileEdit from "./user-profile-edit/user-profile-edit.container";
import TwoFactorAuthentication from "./user-2fa/user-2fa.container";
import ChangePassword from "./user-password/user-password.container";
import Loading from "components/loading/loading.component";

// Styles
import "./user-account.styles.scss";

const UserAccount = ({ location: { state } }) => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const isUserProfileLoading = useSelector(
    state => state.users[usersAction.USER_LOADING]
  );
  const userProfile = useSelector(
    state => state.users[usersAction.USER_PROFILE]
  );

  // Fetch the user profile from api
  useEffect(() => {
    if (params?.id) {
      dispatch(usersAction.getUserProfile(params.id));
    }
  }, [params, dispatch]);

  const loadComponent = () => {
    const { pathname } = history.location;

    if (pathname.search("view") !== -1) {
      return <UserProfileView />;
    } else if (pathname.search("edit") !== -1) {
      return <UserProfileEdit />;
    } else if (pathname.search("security") !== -1) {
      return <TwoFactorAuthentication />;
    } else if (pathname.search("change-password") !== -1) {
      return <ChangePassword />;
    }
  };

  return (
    <DefaultLayout>
      <div className="container">
        {/* Content Header */}
        <div className="app__data--listing-header">
          <div className="app__data--listing-header-top mb-20">
            <div className="app__data--listing-header-left-col">
              <div className="app__data--listing-title">
                <Link
                  to={{
                    pathname: "/user",
                    state
                  }}
                  className="mr-10"
                >
                  <i
                    className="fas fa-long-arrow-alt-left"
                    data-tip="Back to Users"
                  ></i>
                </Link>
                account
              </div>
            </div>
          </div>
        </div>

        {/* Content Body */}
        <div className="app__user--account--wrapper mb-20 mt-20">
          <div className="card app__user--account-card">
            {/* Loading */}
            <div className="row justify-center">
              <Loading isLoading={isUserProfileLoading} />
            </div>

            {/* If the profile is available */}
            {!isUserProfileLoading && userProfile && (
              <div className="app__user--account--container">
                {/* Left Col */}
                <div className="app__user--account-left-col">
                  <UserAccountSideBar />
                </div>
                {/* Right Col */}
                <div className="app__user--account-right-col">
                  {loadComponent()}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* End Container */}
      </div>
    </DefaultLayout>
  );
};

export default UserAccount;
