// Custom Components
import http from "helpers/http/http.helper";

// GET TAGS
export const TAGS_LOADING = "TAGS_LOADING";
export const GET_TAGS = "GET_TAGS";
export const TAGS_ERRORS = "TAGS_ERRORS";

// ADD TAGS
export const ADD_TAGS_LOADING = "ADD_TAG_LOADING";
export const TAG_ADDED = "TAG_ADDED";
export const ADD_TAGS = "ADD_TAGS";
export const ADD_TAGS_ERRORS = "ADD_TAGS_ERRORS";

// DELETE TAGS
export const TAG_DELETING = "TAG_DELETING";
export const TAG_DELETED = "TAG_DELETED";
export const DELETE_TAG = "DELETE_TAG";
export const DELETE_TAG_ERROR = "DELETE_TAG_ERROR";

// Plans
export const getTags = params => async dispatch => {
  try {
    dispatch({
      type: TAGS_LOADING,
      payload: true
    });
    const response = await http().get(`/tags`, { params });
    dispatch({
      type: GET_TAGS,
      payload: response.data
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: TAGS_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: TAGS_LOADING,
      payload: false
    });
  }
};

export const deleteTag = id => async dispatch => {
  try {
    dispatch({
      type: TAG_DELETING,
      payload: true
    });
    const response = await http().delete(`/tags/${id}`);
    dispatch({
      type: DELETE_TAG,
      payload: response.data.data
    });
    dispatch({
      type: TAG_DELETED,
      payload: response.data.notifications
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: DELETE_TAG_ERROR,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: TAG_DELETING,
      payload: false
    });
  }
};

export const addTags = postData => async dispatch => {
  try {
    dispatch({
      type: ADD_TAGS_LOADING,
      payload: true
    });
    const response = await http().post("/tags", postData);
    dispatch({
      type: ADD_TAGS,
      payload: response.data
    });
    dispatch({
      type: TAG_ADDED,
      payload: response.data.notifications
    });
    dispatch(getTags({}));
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: ADD_TAGS_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: ADD_TAGS_LOADING,
      payload: false
    });
  }
};
