import React from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";

// Custom Components
import IconAvatar from "components/icons/icon-avatar/icon-avatar.component";

// Styles
import "./user-card.styles.scss";

const UserCard = ({ user, parameters }) => {
  const history = useHistory();

  return (
    <div className="app__user--card">
      {/* Actions */}
      <div className={classnames("app__user--card-actions")}>
        <Link
          to={{
            pathname: `/user/${user.id}/edit`,
            state: parameters
          }}
        >
          <i className="fas fa-pencil-alt app__user--card-edit cp"></i>
        </Link>
      </div>

      <div className="app__user--card-header mt-15">
        <IconAvatar
          source={`${user?.firstname} ${user?.lastname}`}
          alt={`${user?.firstname} ${user?.lastname}`}
          size="xxl"
          classes="app__user--card-avatar"
          handleOnClick={() =>
            history.push({
              pathname: `/user/${user.id}/view`,
              state: parameters
            })
          }
        />
        <div className="app__user--card-title mt-15">{`${user?.firstname} ${user?.lastname}`}</div>
        <div className="app__user--card-subtitle mt-8 text-muted">
          {user?.job_title || "no_job_title"}
        </div>
      </div>

      {/* Footer */}
      <div className="app__user--card-footer">
        <div className="hr my-10"></div>
        <ul className="app__user--card-details">
          <li className="mb-7">
            <i className="fas fa-at app__user--card-label"></i>
            <span className="app__user--card-value ml-5">{user?.email}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
  parameters: PropTypes.object
};

export default UserCard;
