import * as actions from "./locales.action";

const localesReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.LOCALES_LOADING:
      return {
        ...state,
        [actions.LOCALES_LOADING]: action.payload
      };
    case actions.GET_LOCALES:
      return {
        ...state,
        [actions.GET_LOCALES]: action.payload
      };
    case actions.LOCALES_ERRORS:
      return {
        ...state,
        [actions.LOCALES_ERRORS]: action.payload
      };
    default:
      return state;
  }
};

export default localesReducer;
