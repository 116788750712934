import * as actions from "./timezones.action";

const timezonesReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.TIMEZONES_LOADING:
      return {
        ...state,
        [actions.TIMEZONES_LOADING]: action.payload
      };
    case actions.GET_TIMEZONES:
      return {
        ...state,
        [actions.GET_TIMEZONES]: action.payload
      };
    case actions.TIMEZONES_ERRORS:
      return {
        ...state,
        [actions.TIMEZONES_ERRORS]: action.payload
      };
    default:
      return state;
  }
};

export default timezonesReducer;
