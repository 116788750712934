import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

//Custom components
import * as baseAction from "store/base/base.action";
import * as customersAction from "store/customers/customers.action";
import DefaultLayout from "layouts/default/default.layout";
import Button from "components/form/buttons/icon-button/icon-button.component";
import SearchCollapsable from "components/form/search/search-collapsable/search-collapsable.component";
import Pagination from "components/pagination/pagination.component";
import DropDown from "components/form/dropdown/dropdown.component";
import DropDownFilter from "components/form/dropdown-filter/dropdown-filter.component";
import Loading from "components/loading/loading.component";
import notification from "helpers/notification/notification.helper";
import TagsModal from "../customers/modals/tags/tags-modal.component";
import TextInput from "components/form/inputs/text/text-input.component";

//hooks
import useDate from "hooks/useDate";

// Styles
import "./customers.styles.scss";

export const ADD_CUSTOMER = "Add Customer";
export const ADD_NEWS_NOTIFICATION = "Add News Notification";
export const EDIT_CUSTOMER = "Edit Customer";
export const CREATE_USER = "Create User";
export const EXPORT_SELECTED = "Export Selected";
export const EXPORT_ALL = "Export All";
export const EXPORT_FILTERED = "Export Filtered";
export const SUSPEND_INSTANCE = "Suspend Instance";
export const TERMINATE_INSTANCE = "Terminate Instance";

const Customer = ({ location: { state } }) => {
  const moment = useDate();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showSearch, setShowSearch] = useState(false);
  const dateFilters = [
    {
      label: "Expired",
      key: "Expired",
      value: "Expired",
      data: { expiry_date: "Expired" },
      checked: false
    },
    {
      label: "Expire within 7 days",
      key: "7 days",
      value: "7 days",
      data: { expiry_date: "7 days" },
      checked: false
    },
    {
      label: "Expire within 15 days",
      key: "15 days",
      value: "15 days",
      data: { expiry_date: "15 days" },
      checked: false
    },
    {
      label: "Expire within 30 days",
      key: "30 days",
      value: "30 days",
      data: { expiry_date: "30 days" },
      checked: false
    },
    {
      label: "Expire within 60 days",
      key: "60 days",
      value: "60 days",
      data: { expiry_date: "60 days" },
      checked: false
    },
    {
      label: "Custom Date",
      key: "Custom Date",
      value: "Custom Date",
      data: { expiry_date: "Custom Date" },
      checked: false
    }
  ];
  const statusFilters = [
    {
      label: "Active",
      key: "Active",
      value: "Active",
      data: { status: "Active" },
      checked: true
    },
    {
      label: "Suspended",
      key: "Suspended",
      value: "Suspended",
      data: { status: "Suspended" },
      checked: true
    },
    {
      label: "Terminated",
      key: "Terminated",
      value: "Terminated",
      data: { status: "Terminated" },
      checked: true
    },
    {
      label: "Instance not created",
      key: "Instance not created",
      value: "Instance not created",
      data: { status: "Instance not created" },
      checked: true
    }
  ];
  const [parameters, setParameters] = useState({
    name: "",
    statusFilters: statusFilters,
    dateFilters: dateFilters,
    page: 1,
    limit: 15,
    startDate: "",
    endDate: ""
  });
  const [customers, setCustomers] = useState([]);
  const exportSelected = [];
  const [tags, setTags] = useState([]);
  const [showTagsModal, setShowTagsModal] = useState(false);

  const customersResponse = useSelector(
    state => state.customers[customersAction.GET_CUSTOMERS]
  );
  const isCustomersLoading = useSelector(
    state => state.customers[customersAction.CUSTOMERS_LOADING]
  );
  const customersErrors = useSelector(
    state => state.customers[customersAction.CUSTOMERS_ERRORS]
  );
  const suspendCustomerResponse = useSelector(
    state => state.customers[customersAction.SUSPEND_CUSTOMER]
  );
  const terminateCustomerResponse = useSelector(
    state => state.customers[customersAction.TERMINATE_CUSTOMER]
  );
  const exportCustomersResponse = useSelector(
    state => state.customers[customersAction.EXPORT_CUSTOMERS]
  );
  const isexportCustomersLoading = useSelector(
    state => state.customers[customersAction.EXPORT_CUSTOMERS_LOADING]
  );
  const exportCustomersErrors = useSelector(
    state => state.customers[customersAction.EXPORT_CUSTOMERS_ERRORS]
  );
  const exportSelectedCustomersResponse = useSelector(
    state => state.customers[customersAction.EXPORT_SELECTED_CUSTOMERS]
  );
  const isexportSelectedCustomersLoading = useSelector(
    state => state.customers[customersAction.EXPORT_SELECTED_CUSTOMERS_LOADING]
  );
  const exportSelectedCustomersErrors = useSelector(
    state => state.customers[customersAction.EXPORT_SELECTED_CUSTOMERS_ERRORS]
  );
  const exportFilteredCustomersResponse = useSelector(
    state => state.customers[customersAction.EXPORT_FILTERED_CUSTOMERS]
  );
  const isexportFilteredCustomersLoading = useSelector(
    state => state.customers[customersAction.EXPORT_FILTERED_CUSTOMERS_LOADING]
  );
  const exportFilteredCustomersErrors = useSelector(
    state => state.customers[customersAction.EXPORT_FILTERED_CUSTOMERS_ERRORS]
  );
  const handleSuspendCustomer = () => {
    customers.forEach(customer => {
      if (customer?.select) {
        dispatch(customersAction.suspendCustomer(customer.id));
      }
    });
  };

  const handleTerminateCustomer = () => {
    customers.forEach(customer => {
      if (customer?.select) {
        dispatch(customersAction.terminateCustomer(customer.id));
      }
    });
  };
  const exportCustomers = () => {
    dispatch(customersAction.exportCustomers());
  };
  const exportSelectedCustomers = () => {
    customers.map(data => {
      if (data.select === true) {
        exportSelected.push(data);
      }
    });
    if (exportSelected.length > 0) {
      dispatch(customersAction.exportSelectedCustomers(exportSelected));
    }
  };
  const exportFilteredCustomers = () => {
    let statusFilters = [];
    let dateFilters = [];
    for (const filter of parameters.statusFilters) {
      if (filter.checked) {
        statusFilters.push(filter.data);
      }
    }
    for (const filter of parameters.dateFilters) {
      if (filter.checked) {
        dateFilters.push(filter.data);
      }
    }
    const newParameters = { ...parameters };
    newParameters.statusFilters = statusFilters;
    newParameters.dateFilters = dateFilters;
    dispatch(customersAction.exportFilteredCustomers(newParameters));
  };
  const handleButton = value => {
    switch (value) {
      case ADD_CUSTOMER:
        history.push("/manage-customers/add-customer-info");
        break;
      case ADD_NEWS_NOTIFICATION:
        history.push("/manage-customers/news-notifications");
        break;
      case SUSPEND_INSTANCE:
        handleSuspendCustomer();
        break;
      case TERMINATE_INSTANCE:
        handleTerminateCustomer();
        break;
      case EXPORT_ALL:
        exportCustomers();
        break;
      case EXPORT_SELECTED:
        exportSelectedCustomers();
        break;
      case EXPORT_FILTERED:
        exportFilteredCustomers();
        break;
      default:
        break;
    }
  };

  // If a state is found, restore it in the local state store
  useEffect(() => {
    if (state) {
      setParameters(state);
    }
  }, [state]);

  // Load the user list on paginate click
  useEffect(() => {
    let statusFilters = [];
    let dateFilters = [];
    for (const filter of parameters.statusFilters) {
      if (filter.checked) {
        statusFilters.push(filter.data);
      }
    }
    for (const filter of parameters.dateFilters) {
      if (filter.checked) {
        dateFilters.push(filter.data);
      }
    }
    const newParameters = { ...parameters };
    newParameters.statusFilters = statusFilters;
    newParameters.dateFilters = dateFilters;
    dispatch(customersAction.getCustomers(newParameters));
    // eslint-disable-next-line
  }, [parameters, suspendCustomerResponse, terminateCustomerResponse]);

  useEffect(() => {
    setCustomers(
      customersResponse?.data.map(customer => {
        return {
          ...customer,
          select: false
        };
      })
    );
  }, [customersResponse]);

  useEffect(() => {
    if (isexportCustomersLoading || isexportFilteredCustomersLoading) {
      notification.success(["Downloading..."], {
        position: "top-left"
      });
    }
  }, [isexportCustomersLoading, isexportFilteredCustomersLoading]);

  const donwloadCSV = response => {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Customers.csv`);
    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  useEffect(() => {
    if (exportCustomersResponse) {
      donwloadCSV(exportCustomersResponse.data);
    }
  }, [exportCustomersResponse]);

  useEffect(() => {
    if (exportSelectedCustomersResponse) {
      donwloadCSV(exportSelectedCustomersResponse.data);
    }
  }, [exportSelectedCustomersResponse]);

  useEffect(() => {
    if (exportFilteredCustomersResponse) {
      donwloadCSV(exportFilteredCustomersResponse.data);
    }
  }, [exportFilteredCustomersResponse]);

  useEffect(() => {
    if (exportSelectedCustomersErrors && !exportSelectedCustomersResponse) {
      notification.error(exportSelectedCustomersErrors, {
        position: "top-left"
      });
    }
    // eslint-disable-next-line
  }, [
    exportSelectedCustomersErrors,
    exportSelectedCustomersResponse,
    dispatch
  ]);

  // When the component is unloaded, reset the response and error messages
  useEffect(() => {
    return () => {
      dispatch(baseAction.reset(customersAction.EXPORT_CUSTOMERS_LOADING));
      dispatch(baseAction.reset(customersAction.EXPORT_CUSTOMERS));
      dispatch(
        baseAction.reset(customersAction.EXPORT_SELECTED_CUSTOMERS_LOADING)
      );
      dispatch(baseAction.reset(customersAction.EXPORT_SELECTED_CUSTOMERS));
      dispatch(
        baseAction.reset(customersAction.EXPORT_SELECTED_CUSTOMERS_ERRORS)
      );
      dispatch(
        baseAction.reset(customersAction.EXPORT_FILTERED_CUSTOMERS_LOADING)
      );
      dispatch(baseAction.reset(customersAction.EXPORT_FILTERED_CUSTOMERS));
      dispatch(baseAction.reset(customersAction.SUSPEND_CUSTOMER));
      dispatch(baseAction.reset(customersAction.TERMINATE_CUSTOMER));
    };
  }, [dispatch]);

  const handleStatusFilter = item => {
    const newFilters = [...parameters.statusFilters];
    for (let [idx, filter] of newFilters.entries()) {
      if (filter.value === item.value) {
        filter = item;
        newFilters[idx] = item;
      }
    }
    setParameters({
      ...parameters,
      statusFilters: newFilters
    });
  };

  const handleDateFilter = item => {
    const newFilters = [...parameters.dateFilters];
    for (let [idx, filter] of newFilters.entries()) {
      if (filter.value === item.value) {
        filter = item;
        newFilters[idx] = item;
      } else {
        filter.checked = false;
      }
    }
    setParameters({
      ...parameters,
      dateFilters: newFilters
    });
  };

  const actionOptons = [
    {
      label: EXPORT_SELECTED,
      value: EXPORT_SELECTED
    },
    {
      label: EXPORT_FILTERED,
      value: EXPORT_FILTERED
    },
    {
      label: EXPORT_ALL,
      value: EXPORT_ALL
    },
    {
      label: SUSPEND_INSTANCE,
      value: SUSPEND_INSTANCE
    },
    {
      label: TERMINATE_INSTANCE,
      value: TERMINATE_INSTANCE
    }
  ];

  const handleShowTags = tags => {
    setTags(tags);
    if (tags?.length > 0) {
      setShowTagsModal(true);
    }
  };

  return (
    <DefaultLayout>
      {showTagsModal && (
        <TagsModal
          showTagsModal={showTagsModal}
          setShowTagsModal={setShowTagsModal}
          tags={tags}
        />
      )}
      <div className="container">
        {/* Content Header */}
        <div className="app__data--listing-header">
          <div className="app__data--listing-header-top mb-20">
            <div className="app__data--listing-header-left-col">
              <div className="app__data--listing-title">
                <i className={`fas fa-users app__navigation--item-icon`}></i>{" "}
                Manage Customers
              </div>
            </div>
            <div className="app__data--listing-header-right-col">
              {/* Button */}
              <div className="app__data--button mr-15">
                <Button
                  icon="fas fa-user-plus"
                  color="info"
                  label={ADD_CUSTOMER}
                  handleButton={handleButton}
                ></Button>
              </div>
              {/* Button */}
              <div>
                <Button
                  icon="fas fa-newspaper"
                  color="info"
                  label={ADD_NEWS_NOTIFICATION}
                  handleButton={handleButton}
                ></Button>
              </div>
            </div>
          </div>
          {/* Filters & Search */}
          <div className="app__data--listing--filter mt-20">
            <div className="app__data--listing--filter-menu">
              <div className="app__data--listing--filter-items">
                <div className="app__data--listing--filter-item mr-15">
                  <DropDown
                    title="Actions on Customer(s)"
                    options={actionOptons}
                    handleFilter={handleButton}
                  />
                </div>
                <div className="app__data--listing--filter-item mr-15">
                  <DropDownFilter
                    title="Filter by Status"
                    options={parameters.statusFilters}
                    handleFilter={handleStatusFilter}
                  />
                </div>
                <div className="app__data--listing--filter-item mr-15">
                  <DropDownFilter
                    title="Filter by Date"
                    options={parameters.dateFilters}
                    handleFilter={handleDateFilter}
                    inputType={"RADIO"}
                  />
                </div>
              </div>
              <SearchCollapsable
                dataTip="Search Customer"
                defaultValue={location?.state?.name}
                showSearch={location?.state?.name ? true : showSearch}
                setShowSearch={setShowSearch}
                placeholder="Search by Name"
                state={parameters}
                onChange={(targetValue, state) => {
                  setParameters({
                    ...state,
                    page: 1,
                    name: targetValue
                  });
                }}
              />
            </div>
          </div>
          {parameters?.dateFilters.map(filter =>
            filter.label === "Custom Date" && filter.checked === true ? (
              <div
                className="input-group mb-20 filter-input-flex"
                key={filter.label}
              >
                <div className="input-items mr-15">
                  <label>Start Date</label>
                  <TextInput
                    type="date"
                    id="start_date"
                    name="start_date"
                    className="input-xxl pl-25"
                    iconPosition="left"
                    value={parameters?.startDate || ""}
                    onChange={e => {
                      const targetValue = e.target.value;
                      setParameters(prevState => ({
                        ...prevState,
                        startDate: targetValue
                      }));
                    }}
                  />
                </div>
                <div className="input-items">
                  <label>End date</label>
                  <TextInput
                    type="date"
                    id="end_date"
                    name="end_date"
                    className="input-xxl pl-25"
                    iconClass=""
                    iconPosition="left"
                    value={parameters?.endDate || ""}
                    onChange={e => {
                      const targetValue = e.target.value;
                      setParameters(prevState => ({
                        ...prevState,
                        endDate: targetValue
                      }));
                    }}
                  />
                </div>
              </div>
            ) : null
          )}
        </div>
        {/* Content Body */}
        <Loading isLoading={isCustomersLoading} wrapperClassName="">
          <table className="table mt-20">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    className="mr-15"
                    id=""
                    onChange={e => {
                      let checked = e.target.checked;
                      setCustomers(
                        customers?.map(d => {
                          d.select = checked;
                          return d;
                        })
                      );
                    }}
                  />
                  Company Display Name
                </th>
                <th>Contact Name</th>
                <th>Contact Phone</th>
                <th>Contact Email</th>
                <th>Tags</th>
                <th>Last Login</th>
                <th>Expiry Date</th>
              </tr>
            </thead>
            <tbody>
              {/* Rows*/}
              {customers?.length
                ? customers?.map(customer => (
                    <tr key={customer?.id}>
                      <td>
                        <ReactTooltip />

                        <div className="table__col--main">
                          <div className="mr-10">
                            <input
                              checked={customer?.select}
                              type="checkbox"
                              className="mr-15"
                              id="test"
                              onChange={event => {
                                let checked = event.target.checked;
                                setCustomers(
                                  customers.map(data => {
                                    if (customer.id === data.id) {
                                      data.select = checked;
                                    }
                                    return data;
                                  })
                                );
                              }}
                            />
                          </div>
                          {customer?.status?.type === "Active" ? (
                            <div className="mr-5 cp" data-tip="Active">
                              🟢
                            </div>
                          ) : null}
                          {customer?.status?.type === "Suspended" ? (
                            <div className="mr-5 cp" data-tip="Suspended">
                              🟡
                            </div>
                          ) : null}
                          {customer?.status?.type === "Terminated" ? (
                            <div className="mr-5 cp" data-tip="Terminated">
                              🔴
                            </div>
                          ) : null}
                          {customer?.status?.type === "Instance not created" ? (
                            <div
                              className="mr-5 cp"
                              data-tip="Instance not created"
                            >
                              🔵
                            </div>
                          ) : null}
                          <div className="title-wrapper">
                            <Link to="/manage-customers/1/users">
                              {customer?.company_display_name}
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td className="text-muted">
                        {customer?.contact_persons.map(contactPerson =>
                          contactPerson?.primary === true
                            ? contactPerson?.name
                            : null
                        )}
                      </td>
                      <td className="text-muted">
                        {customer?.contact_persons.map(contactPerson =>
                          contactPerson?.primary === true
                            ? contactPerson?.phone
                            : null
                        )}
                      </td>
                      <td className="text-muted">
                        {customer?.contact_persons.map(contactPerson =>
                          contactPerson?.primary === true
                            ? contactPerson?.email
                            : null
                        )}
                      </td>
                      <td className="text-muted">
                        {customer?.tags.slice(0, 3).map(tag => (
                          <div className="text-muted tag" key={tag?.id}>
                            {tag?.name}
                          </div>
                        ))}
                        {customer?.tags.length > 3 ? (
                          <div onClick={() => handleShowTags(customer?.tags)}>
                            <Link to="#">more</Link>
                          </div>
                        ) : null}
                      </td>
                      {customer?.last_login ? (
                        <td className="text-muted">
                          {moment(customer?.last_login).format("LL")}
                        </td>
                      ) : (
                        <td className="text-muted">--</td>
                      )}
                      {customer?.expiry_date ? (
                        <td className="text-muted">
                          {moment(customer?.expiry_date).format("LL")}
                        </td>
                      ) : (
                        <td className="text-muted">--</td>
                      )}
                      <td>
                        <div className="table__action--button-wrapper">
                          <ul>
                            <li className="table__action--button">
                              <Link to="#">Export Users</Link>
                            </li>
                            <li className="table__action--button-divider">|</li>
                            <li className="table__action--button">
                              <Link to="/manage-customers/1/account-usage-users">
                                Account Usage
                              </Link>
                            </li>
                            <li className="table__action--button-divider">|</li>
                            <li className="table__action--button">
                              <Link
                                to={`/manage-customers/${customer?.id}/edit-customer`}
                              >
                                Edit
                              </Link>
                            </li>
                            <li className="table__action--button-divider">|</li>
                            <li className="table__action--button">
                              <Link
                                to={`/manage-customers/${customer?.id}/profile`}
                              >
                                Profile
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </Loading>
        {/* Pagination */}
        {!isCustomersLoading && customersResponse?.meta ? (
          <Pagination
            meta={customersResponse?.meta}
            wrapperClass="mt-50"
            onClick={page => {
              setParameters({
                ...parameters,
                page: +page.selected + 1
              });
            }}
          />
        ) : null}
        {/* End Container */}
      </div>
    </DefaultLayout>
  );
};
export default Customer;
