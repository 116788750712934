import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// Custom Module
import * as baseAction from "store/base/base.action";
import * as usersAction from "store/users/users.action";
import useLocalStorage, { EV_USER } from "hooks/useLocalStorage";
import TextInput from "components/form/inputs/text/text-input.component";
import Button from "components/form/buttons/button/button.component";
import Switch from "components/form/switch/switch.component";
import notification from "helpers/notification/notification.helper";

// Styles
import "./user-profile-edit.styles.scss";

const ProfileEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [loggedUserLS, setLoggedUser] = useLocalStorage(EV_USER, null);

  const loggedUserProfile = useSelector(
    state => state.users[usersAction.LOGGED_USER_PROFILE]
  );
  const userProfile = useSelector(
    state => state.users[usersAction.USER_PROFILE]
  );
  const isUpdateProfileLoading = useSelector(
    state => state.users[usersAction.USER_UPDATE_LOADING]
  );
  const profileUpdatedResponse = useSelector(
    state => state.users[usersAction.USER_PROFILE_UPDATED]
  );
  const updateProfileErrors = useSelector(
    state => state.users[usersAction.USER_UPDATE_ERROR]
  );

  const [postData, setPostData] = useState({});

  // Load user's profile and store it in state
  useEffect(() => {
    if (userProfile) {
      setPostData(userProfile);
    }
    // eslint-disable-next-line
  }, [userProfile]);

  // When the profile has been updated
  useEffect(() => {
    if (profileUpdatedResponse) {
      notification.success(profileUpdatedResponse);
      dispatch(baseAction.reset(usersAction.USER_PROFILE_UPDATED));
      dispatch(baseAction.reset(usersAction.USER_UPDATE_ERROR));
    }
  }, [profileUpdatedResponse, dispatch]);

  // When there is an error updating the profile
  useEffect(() => {
    if (updateProfileErrors && !profileUpdatedResponse) {
      notification.error(updateProfileErrors);
    }
    // eslint-disable-next-line
  }, [updateProfileErrors]);

  // Update the state for the logged user
  useEffect(() => {
    // If the profile belongs to the logged user
    if (profileUpdatedResponse && loggedUserProfile.id === userProfile.id) {
      setLoggedUser(userProfile);
      dispatch(usersAction.setLoggedUserProfile(userProfile));
    }
    // eslint-disable-next-line
  }, [profileUpdatedResponse]);

  // When the component is unloaded, reset the error messages
  useEffect(() => {
    return () => {
      dispatch(baseAction.reset(usersAction.USER_UPDATE_ERROR));
    };
  }, [dispatch]);

  return (
    <>
      <h4>Profile</h4>
      <div className="hr mt-20"></div>
      <h5 className="app__user--account-subtitle">Personal Information</h5>

      <div className="app__user--profile-form">
        {params.id !== loggedUserProfile?.id ? (
          <>
            <div className="hr mt-20"></div>

            <div className="mt-30 col-2">
              <label
                htmlFor="status"
                id="status"
                className="text-md mb-4 mr-20 text-right"
              >
                Active
              </label>
              <Switch
                checked={postData?.is_active}
                setChecked={isChecked => {
                  dispatch(usersAction.enableDisableUser(params.id, isChecked));
                  setPostData({
                    ...postData,
                    is_active: isChecked
                  });
                }}
                ariaLabelledBy="status"
              />
            </div>
          </>
        ) : null}

        <div className="hr mt-20"></div>

        <div className="mt-30 col-2">
          <label htmlFor="email" className="text-md mb-4 mr-20 text-right">
            Email Address
          </label>
          <TextInput
            type="email"
            id="email"
            name="email"
            value={postData?.email || ""}
            className="input-lg pr-25"
            errors={updateProfileErrors}
            iconClass="fas fa-at"
            iconPosition="right"
            onChange={e =>
              setPostData({
                ...postData,
                [e.target.id]: e.target.value
              })
            }
          />
        </div>

        <div className="mt-30 col-2">
          <label htmlFor="firstname" className="text-md mb-4 mr-20 text-right">
            Firstname
          </label>
          <TextInput
            type="text"
            id="firstname"
            name="firstname"
            value={postData?.firstname || ""}
            className="input-lg pr-25"
            errors={updateProfileErrors}
            iconClass="far fa-user"
            iconPosition="right"
            onChange={e =>
              setPostData({
                ...postData,
                [e.target.id]: e.target.value
              })
            }
          />
        </div>

        <div className="mt-30 col-2">
          <label htmlFor="lastname" className="text-md mb-4 mr-20 text-right">
            Lastname
          </label>
          <TextInput
            type="text"
            id="lastname"
            name="lastname"
            value={postData?.lastname || ""}
            className="input-lg pr-25"
            errors={updateProfileErrors}
            iconClass="far fa-user"
            iconPosition="right"
            onChange={e =>
              setPostData({
                ...postData,
                [e.target.id]: e.target.value
              })
            }
          />
        </div>

        <div className="mt-30 col-2">
          <label htmlFor="job_title" className="text-md mb-4 mr-20 text-right">
            Job Title
          </label>
          <TextInput
            type="text"
            id="job_title"
            name="job_title"
            value={postData?.job_title || ""}
            className="input-lg pr-25"
            errors={updateProfileErrors}
            iconClass="fas fa-user-tie"
            iconPosition="right"
            onChange={e =>
              setPostData({
                ...postData,
                [e.target.id]: e.target.value
              })
            }
          />
        </div>

        <div className="hr my-30"></div>

        <Button
          id="update-profile"
          disabled={isUpdateProfileLoading}
          onClick={() => dispatch(usersAction.updateUserProfile(postData))}
          className="btn btn--info btn--lg fr"
        >
          Update Profile
        </Button>

        {/* End Form Wrapper */}
      </div>
    </>
  );
};

export default ProfileEdit;
