import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

// Custom Components
import Loader, { loaderTypes } from "components/loading/loading.component";

const Button = ({
  icon,
  id = "",
  innerRef = React.createRef(),
  children,
  className = "",
  loaderClassName = "",
  dataTip = "",
  disabled = false,
  isLoading = false,
  ariaLabel = "",
  ariaDescribedBy = "",
  onClick = () => {}
}) => {
  return (
    <>
      <Loader
        isLoading={isLoading}
        loaderType={loaderTypes.CircleLoader}
        width="40"
        wrapperClassName={loaderClassName}
      >
        <ReactTooltip />
        <button
          id={id}
          ref={innerRef}
          disabled={disabled}
          className={`btn ${className}`}
          onClick={onClick}
          data-tip={dataTip}
          aria-label={ariaLabel}
          aria-describedby={ariaDescribedBy}
        >
          <i className={`button ${icon} btn--icon`}></i>
          {children}
        </button>
      </Loader>
    </>
  );
};

Button.propTypes = {
  id: PropTypes.string.isRequired,
  ref: PropTypes.object,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  loaderClassName: PropTypes.string,
  dataTip: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  ariaLabel: PropTypes.string,
  ariaDescribedBy: PropTypes.string,
  onClick: PropTypes.func
};

export default Button;
