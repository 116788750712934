import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

// Custom Components
import DepartmentMenu from "./navigation-item-menu/department-menu/department-menu.component";

// Styles
import "./navigation-item.styles.scss";

const NavigationItem = ({
  to,
  iconClassName,
  tip,
  menu = null,
  onClick = () => {}
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(prevState => !prevState);
  };

  const renderIcon = () => {
    switch (menu) {
      case "departments":
        return (
          <Link
            to={to}
            onClick={toggleMenu}
            className={showMenu ? "active" : null}
            data-tip={tip}
          >
            <ReactTooltip />
            <i className={`${iconClassName} app__navigation--item-icon`}></i>
          </Link>
        );
      default:
        return (
          <NavLink to={to} data-tip={tip} onClick={onClick}>
            <ReactTooltip />
            <i className={`${iconClassName} app__navigation--item-icon`}></i>
          </NavLink>
        );
    }
  };

  const renderMenu = () => {
    switch (menu) {
      case "departments":
        return <DepartmentMenu setShowMenu={setShowMenu} showMenu={showMenu} />;
      default:
        break;
    }
  };

  return (
    <div className="app__navigation--item">
      {renderIcon()}

      {/* List Menu (if any) */}
      {renderMenu()}
    </div>
  );
};

NavigationItem.propTypes = {
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  tip: PropTypes.string.isRequired,
  iconClassName: PropTypes.string.isRequired,
  menu: PropTypes.string
};

export default NavigationItem;
