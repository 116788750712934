import React from "react";

// Styles
import "./drawer-modal-header.styles.scss";

const Header = ({ title, setShowModal }) => {
  return (
    <div className="app__drawer-modal--header">
      <h5 className="ml-10">{title}</h5>
      <i
        className="fas fa-times close--icon mr-10 cp"
        id="newsMenuCloseBtn"
        onClick={() => setShowModal(false)}
      ></i>
    </div>
  );
};

export default Header;
