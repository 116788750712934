import React, { useState } from "react";

//barcharts
import BarChart from "components/charts/bar-charts/bar-charts.component";

const Users = () => {

    {/*Labels and title for charts */}
    const documentAccessed = {
        labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        datasets: [
          {
            label: 'Document Accessed',
            backgroundColor: '#17a2b8',
            borderColor: '#17a2b8',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: [0, 59, 80, 81, 56, 55, 40]
          }
        ],
    }

    const options =  {
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: "No. of docs"
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Days'
            }
          }],
        }     
      }

      const editOptions =  {
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: "No. of edits"
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Days'
            }
          }],
        }     
      }

      const approvalOptions =  {
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: "No. of approvals"
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Days'
            }
          }],
        }     
      }

    const documentAdded = {
        labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        datasets: [
          {
            label: 'Document Added',
            backgroundColor: '#17a2b8',
            borderColor: '#17a2b8',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: [0, 59, 80, 81, 56, 55, 40]
          }
        ]
    }

    const documentDownloads= {
      labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      datasets: [
        {
          label: 'Document Downloads',
          backgroundColor: '#17a2b8',
          borderColor: '#17a2b8',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: [0, 59, 80, 81, 56, 55, 40]
        }
      ]
  }

  const documentShared = {
    labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    datasets: [
      {
        label: 'Document Shared',
        backgroundColor: '#17a2b8',
        borderColor: '#17a2b8',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: [0, 59, 80, 81, 56, 55, 40]
      }
    ]
}

const documentEdits = {
  labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  datasets: [
    {
      label: 'Online Edits',
      backgroundColor: '#17a2b8',
      borderColor: '#17a2b8',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
      hoverBorderColor: 'rgba(255,99,132,1)',
      data: [0, 59, 80, 81, 56, 55, 40]
    }
  ]
}

const documentApprovals = {
  labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  datasets: [
    {
      label: 'Approvals Initiated',
      backgroundColor: '#17a2b8',
      borderColor: '#17a2b8',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
      hoverBorderColor: 'rgba(255,99,132,1)',
      data: [0, 59, 80, 81, 56, 55, 40]
    }
  ]
}
    return (
        <div className="app__user--account-usage-body">
          {/*container body */}
            <BarChart state={documentAccessed} title="Document Accessed" options={options}/> 
            <BarChart state={documentAdded} title="Document Added" options={options}/> 
            <BarChart state={documentDownloads} title="Document Downloads" options={options}/> 
            <BarChart state={documentShared} title="Document Shared" options={options}/> 
            <BarChart state={documentEdits} title="Online Edits" options={editOptions}/> 
            <BarChart state={documentApprovals} title="Approvals Initiated" options={approvalOptions}/>
        </div>
    )
}

export default Users;