import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//Custom Components
import NoData, { EmptyBox } from "components/no-data/no-data.component";
import dialog from "helpers/dialog/dialog.helper";
import Button from "components/form/buttons/button/button.component";
import ButtonIcon from "components/form/buttons/icon-button/icon-button.component";
import notification from "helpers/notification/notification.helper";
import * as baseAction from "store/base/base.action";
import * as customersAction from "store/customers/customers.action";
import * as subscriptionsAction from "store/subscriptions/subscriptions.action";

// Hooks
import useDate from "hooks/useDate";

//styles
import "./customer-subscription-history.styles.scss";

export const ADD_SUBSCRIPTION = "Add Subscription";
export const CANCELLED = "Cancelled";
export const ACTIVE = "Active";

const UserSubscriptionHistory = () => {
  const moment = useDate();
  const history = useHistory();
  const dispatch = useDispatch();

  const subscriptionHistory = useSelector(
    state => state.subscriptions[subscriptionsAction.GET_SUBSCRIPTIONS]
  );
  const deleteSubscriptionResponse = useSelector(
    state => state.subscriptions[subscriptionsAction.DELETE_SUBSCRIPTION]
  );
  const subscriptionErrors = useSelector(
    state => state.subscriptions[subscriptionsAction.SUBSCRIPTION_DELETE_ERROR]
  );
  const customerProfile = useSelector(
    state => state.customers[customersAction.GET_CUSTOMER]
  );

  const handleCancelSubscription = id => {
    dialog
      .confirm("Are you sure you want to cancel the subscription", function () {
        dispatch(subscriptionsAction.deleteSubscription(id));
      })
      .set("labels", { ok: "Yes", cancel: "No" })
      .setHeader("Confirmation");
  };
  const handleButton = value => {
    switch (value) {
      case ADD_SUBSCRIPTION:
        history.push(
          `/manage-customers/${customerProfile.id}/add-subscription`
        );
        break;
      default:
        break;
    }
  };

  // When the customer creation is successful
  useEffect(() => {
    if (deleteSubscriptionResponse?.notifications) {
      notification.success(deleteSubscriptionResponse.notifications, {
        position: "top-left"
      });
      dispatch(subscriptionsAction.getSubscriptionHistory(customerProfile.id));
      dispatch(baseAction.reset(subscriptionsAction.SUBSCRIPTION_DELETE_ERROR));
    }
    // eslint-disable-next-line
  }, [deleteSubscriptionResponse, dispatch]);

  // When there is an error adding the customer
  useEffect(() => {
    if (subscriptionErrors && !deleteSubscriptionResponse) {
      notification.error(subscriptionErrors, {
        position: "top-left"
      });
    }
  }, [subscriptionErrors, dispatch]);

  // When the component is unloaded, reset the error messages
  useEffect(() => {
    return () => {
      dispatch(baseAction.reset(subscriptionsAction.DELETE_SUBSCRIPTION));
      dispatch(baseAction.reset(subscriptionsAction.SUBSCRIPTION_DELETE_ERROR));
    };
  }, [dispatch]);

  return (
    <div>
      <div className="app__user--profile-form-heading-container">
        <h4 className="app__user--profile-form-heading">
          Subscription History
        </h4>
        <ButtonIcon
          color="info"
          label={ADD_SUBSCRIPTION}
          handleButton={handleButton}
        ></ButtonIcon>
      </div>
      {subscriptionHistory?.data?.length !== 0 ? (
        <div className="app__user--profile-form">
          <table className="table mt-20">
            <thead>
              <tr>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Plan</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              {/* Row */}
              {subscriptionHistory?.data.map(subscription => (
                <tr key={subscription?.id}>
                  <td>
                    {subscription?.start_date
                      ? moment(subscription?.start_date).format("LL")
                      : "--"}
                  </td>
                  <td>
                    {subscription?.end_date
                      ? moment(subscription?.end_date)?.format("LL")
                      : "--"}
                  </td>
                  <td className="text-muted">
                    {subscription?.plan?.plan_name}
                  </td>
                  <td className="text-muted">
                    {subscription?.amount ? subscription?.amount : "--"}
                  </td>
                  <td className="text-muted">
                    {subscription?.subscription_status?.type
                      ? subscription?.subscription_status?.type
                      : "--"}
                  </td>
                  <td>
                    <Button
                      id={subscription?.id}
                      disabled={
                        subscription?.subscription_status?.type === CANCELLED
                      }
                      className="danger"
                      onClick={() => handleCancelSubscription(subscription?.id)}
                    >
                      Cancel Subscription
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="hr my-30"></div>

          {/* End Form Wrapper */}
        </div>
      ) : (
        <NoData
          title={"No Subscription found"}
          body={"There seems to be no Subscription"}
          wrapperClass="mt-20"
          imageClass="w-50"
          imageComponent={EmptyBox}
        />
      )}
    </div>
  );
};

export default UserSubscriptionHistory;
