import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";
import packageJson from "../../../../package.json";

// Custom Components
import LoaderLayout from "layouts/blank/blank.layout";
import Loading, { loaderTypes } from "components/loading/loading.component";
import LoginLayout from "layouts/login/login.layout";
import logo from "assets/img/logos/logo-md.png";
import ForgotPassword from "containers/auth/forgot-password/forgot-password.container";
import TextInput from "components/form/inputs/text/text-input.component";
import notification from "helpers/notification/notification.helper";

// Hooks
import useAuth from "hooks/useAuthenticate";
import useToken from "hooks/useToken";

// Styles
import "../auth.styles.scss";

const Login = ({ history }) => {
  const [showSignIn, setShowSignIn] = useState(true);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [postData, setPostData] = useState({ email: "", password: "" });

  const { isTokenLoading, nextPath } = useToken();

  const {
    setLocale: setAuthLocale,
    handleAuthentication,
    user: loggedUser,
    authResponse,
    isAuthLoading,
    authError,
    setAuthError
  } = useAuth();

  // Determine the next path based on token authentication response
  useEffect(() => {
    if (nextPath) {
      history.push(nextPath);
    }
    // eslint-disable-next-line
  }, [nextPath]);

  // If the user login is successful
  useEffect(() => {
    if (authResponse?.status === 200) {
      if (loggedUser?.is_two_factor_enabled === true) {
        return history.push("/verify-token");
      }
      return history.push("/manage-customers");
    }
    // eslint-disable-next-line
  }, [authResponse, loggedUser]);

  // When the response is Error
  useEffect(() => {
    if (authError) {
      notification.error(authError);
    }
    // eslint-disable-next-line
  }, [authError]);

  const handleLogin = () => {
    handleAuthentication(postData);
  };

  useEffect(() => {
    // Remove all error messages on unmount
    return () => setAuthError(null);
  }, []);

  // Auto logging the user in using saved token
  if (isTokenLoading || typeof nextPath === "undefined") {
    return (
      <LoaderLayout>
        <Loading isLoading={true} loaderType={loaderTypes.InfiniteLoader} />
      </LoaderLayout>
    );
  }

  return (
    <LoginLayout>
      <div className="login__box--right">
        <div className="login__form">
          {/* Logo */}
          <div className="row justify-center">
            <img src={logo} className="mb-30" width="80" alt="EisenVault" />
          </div>

          <div className="login__forms-container">
            {/* The sign-in Form */}
            <section
              className={classnames("login__form--signin", {
                "login__form--signin-hide": !showSignIn
              })}
            >
              {/* Welcome Message */}
              <div className="row mb-30 login__text-welcome">
                Login to Master Console
              </div>

              {/* Form Elements */}
              <div className="col">
                <TextInput
                  type="text"
                  id="email"
                  name="email"
                  className="input-lg pl-25"
                  errors={authError}
                  iconClass="fas fa-envelope"
                  iconPosition="left"
                  value={postData?.email || ""}
                  onChange={e =>
                    setPostData({
                      ...postData,
                      [e.target.id]: e.target.value
                    })
                  }
                />
              </div>
              <div className="col my-20">
                <TextInput
                  type="password"
                  id="password"
                  name="password"
                  className="input-lg pl-25"
                  errors={authError}
                  iconClass="fas fa-key"
                  iconPosition="left"
                  value={postData?.password || ""}
                  onChange={e =>
                    setPostData({
                      ...postData,
                      [e.target.id]: e.target.value
                    })
                  }
                />
              </div>

              <div className="row">
                <button
                  onClick={handleLogin}
                  className="btn btn--info btn--block btn--xxl"
                >
                  {isAuthLoading ? "Logging In" : "Login"}
                </button>
              </div>
              {/* End Login Form */}

              {/* Remember me and Forgot Password */}
              <div className="row justify-end mt-20">
                <Link
                  to="#"
                  onClick={() => {
                    setShowSignIn(false);
                    setShowForgotPassword(true);
                  }}
                  id="forgotPasswordBtn"
                  className="login__link text-sm"
                >
                  Forgot password
                </Link>
              </div>
            </section>

            {/* Forgot Password Form */}
            <ForgotPassword
              showForgotPassword={showForgotPassword}
              setShowSignIn={setShowSignIn}
              setShowForgotPassword={setShowForgotPassword}
            />
            {/* End Forgot Password Form */}
          </div>
          <div className="mt-20">Version {packageJson.version}</div>
        </div>
      </div>
    </LoginLayout>
  );
};

Login.propTypes = {
  history: PropTypes.object.isRequired
};

export default Login;
