import Dialog from "./drawer-modal-dialog/drawer-modal-dialog.component";
import Body from "./drawer-modal-body/drawer-modal-body.component";
import Footer from "./drawer-modal-footer/drawer-modal-footer.component";

const DrawerModal = {};

DrawerModal.Dialog = Dialog;
DrawerModal.Body = Body;
DrawerModal.Footer = Footer;

export default DrawerModal;
