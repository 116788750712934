import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

// Styles
import "./search-menu.styles.scss";

const Search = ({showMenu}) => {
  return (
    <div id="searchMenu" className={classnames("app__search--menu", {
      "app__search--menu-show": showMenu,
      
    })}>
      <div className="container">
        <div className="app__search--menu-title text-muted mt-10">
          Recent Searches
        </div>
        <div className="hr mt-10 mb-5 w-95"></div>

        {/* Recent Search History */}
        <ul className="app__search--menu-items mb-5">
          <li>
            <Link to="/">Customer1</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Search;
