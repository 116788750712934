import React from "react";
import { ToastContainer } from 'react-toastify';   
import "react-toastify/dist/ReactToastify.css";


// Styles
import "./login.styles.scss"

const Login = ({ children }) => {
  return (
    <div className="login__wrapper">
      {/* Login Form */}
      {children}
      <ToastContainer draggable={true}/> 
    </div>
  );
};

export default Login;