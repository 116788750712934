import React from "react";
import PropTypes from "prop-types";

// Custom components
import BasicModal from "components/modals/base/default/default-modal.component";

// Styles
import "./tags-modal.styles.scss";

const TagsModal = ({ showTagsModal, setShowTagsModal, tags }) => {
  const handleCloseButton = () => {
    setShowTagsModal(false);
  };
  return (
    <div className="manage__tags-modal">
      <BasicModal
        width="25vw"
        height="auto"
        handleCloseButton={handleCloseButton}
      >
        {/* title */}
        <h5 className="mb-20">Tags</h5>
        <div className="tag-container">
            {tags.map(tag => (
              <div className="text-muted tag-item" key={tag.id}>{tag?.name}</div>
             
            ))}
         
        </div>
      </BasicModal>
    </div>
  );
};

TagsModal.propTypes = {
  showTagsModal: PropTypes.bool,
  setShowTagsModal: PropTypes.func,
  tags: PropTypes.array
};

export default TagsModal;
