import React from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";

// Styles
import "./pagination.styles.scss";

const Pagination = ({
  meta,
  onClick = () => {},
  isLoading = false,
  wrapperClass = ""
}) => {
  if (!meta || isLoading) return "";

  return (
    <div className={`col align-center justify-between ${wrapperClass}`}>
      <ReactPaginate
        forcePage={meta.current_page - 1}
        previousLabel={<i className="fas fa-angle-left cp"></i>}
        nextLabel={<i className="fas fa-angle-right cp"></i>}
        breakLabel={"..."}
        breakClassName={"break-me cp"}
        pageCount={meta?.total_pages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={onClick}
        containerClassName={`app__pagination`}
        pageClassName="app__pagination--item"
        previousClassName="app__pagination--item"
        nextClassName="app__pagination--item"
        breakLinkClassName="app__pagination--item"
        activeClassName="active"
        disabledClassName="disabled"
      />

      <div className="mt-20 text-dark text-md">
        Total {meta.total_records} entries
      </div>
    </div>
  );
};

Pagination.propTypes = {
  meta: PropTypes.object,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  wrapperClass: PropTypes.string
};

export default Pagination;
