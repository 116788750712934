import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import classnames from "classnames";

// Custom Components
import * as usersAction from "store/users/users.action";
import TextInput from "components/form/inputs/text/text-input.component";
import Button from "components/form/buttons/button/button.component";
import notification from "helpers/notification/notification.helper";

// Hooks
import useHttp from "hooks/useHttp";

// Styles
import "./user-password.styles.scss";

const ChangePassword = () => {
  const params = useParams();
  const loggedUserProfile = useSelector(
    state => state.users[usersAction.LOGGED_USER_PROFILE]
  );

  const [passwordStrength, setPasswordStrength] = useState({
    minChars: false,
    minNumber: false,
    minSpecialChars: false,
    mixedCaseChars: false
  });
  const [postData, setPostData] = useState({});

  const {
    put: changePassword,
    isLoading: isChangePasswordLoading,
    responseNotifications,
    error: changePasswordError
  } = useHttp(`users/${params.id}/password`);

  // Password strength meter
  useEffect(() => {
    const newPasswordStrength = {
      minChars: false,
      minNumber: false,
      minSpecialChars: false,
      mixedCaseChars: false
    };

    if (postData?.password?.length >= 8) {
      newPasswordStrength.minChars = true;
    }

    // If the password contains number
    if (/\d/.test(postData?.password)) {
      newPasswordStrength.minNumber = true;
    }

    // If the password contains special characters
    if (/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(postData?.password)) {
      newPasswordStrength.minSpecialChars = true;
    }

    // If the password contains mixed case characters
    if (/[a-z]/.test(postData?.password) && /[A-Z]/.test(postData?.password)) {
      newPasswordStrength.mixedCaseChars = true;
    }

    setPasswordStrength(newPasswordStrength);

    // eslint-disable-next-line
  }, [postData]);

  // When the password update is successful
  useEffect(() => {
    if (responseNotifications) {
      notification.success(responseNotifications);
    }
    // eslint-disable-next-line
  }, [responseNotifications]);

  // When the password update has errors
  useEffect(() => {
    if (changePasswordError) {
      notification.error(changePasswordError);
    }
    // eslint-disable-next-line
  }, [changePasswordError]);

  const handleSubmit = () => {
    changePassword(postData);
  };

  return (
    <>
      <h4>Change Password</h4>

      <div className="hr mt-20"></div>

      <div className="app__user--password-wrapper">
        <div className="app__user--password-box">
          <div className="app__user--password-left">
            <div className="app__user--password-title mb-15">
              Your password must contain:
            </div>
            <ul>
              <li>
                <i
                  className={classnames(`fas fa-check mr-5 mb-15 `, {
                    "app__user--password-check": passwordStrength.minChars,
                    "text-success": passwordStrength.minChars,
                    "text-muted": !passwordStrength.minChars
                  })}
                ></i>{" "}
                At least 8 characters
              </li>
              <li>
                <i
                  className={classnames(`fas fa-check mr-5 mb-15 `, {
                    "app__user--password-check": passwordStrength.minNumber,
                    "text-success": passwordStrength.minNumber,
                    "text-muted": !passwordStrength.minNumber
                  })}
                ></i>{" "}
                At least 1 number
              </li>
              <li>
                <i
                  className={classnames(`fas fa-check mr-5 mb-15 `, {
                    "app__user--password-check":
                      passwordStrength.minSpecialChars,
                    "text-success": passwordStrength.minSpecialChars,
                    "text-muted": !passwordStrength.minSpecialChars
                  })}
                ></i>{" "}
                At least 1 special character
              </li>
              <li>
                <i
                  className={classnames(`fas fa-check mr-5 mb-15 `, {
                    "app__user--password-check":
                      passwordStrength.mixedCaseChars,
                    "text-success": passwordStrength.mixedCaseChars,
                    "text-muted": !passwordStrength.mixedCaseChars
                  })}
                ></i>{" "}
                Mixed case characters
              </li>
            </ul>
          </div>

          <div className="app__user--password-right">
            <div
              className={classnames("col mb-20", {
                hidden: loggedUserProfile?.id !== params.id
              })}
            >
              <label htmlFor="current_password">Current password</label>
              <TextInput
                type="password"
                id="old_password"
                name="old_password"
                className="input-lg pl-25"
                errors={changePasswordError}
                iconClass="fas fa-lock"
                iconPosition="left"
                value={postData?.old_password || ""}
                onChange={e =>
                  setPostData({
                    ...postData,
                    [e.target.id]: e.target.value
                  })
                }
              />
            </div>
            <div className="col mb-20">
              <label htmlFor="new_password">New password</label>
              <TextInput
                type="password"
                id="password"
                name="password"
                className="input-lg pl-25"
                errors={changePasswordError}
                iconClass="fas fa-lock"
                iconPosition="left"
                value={postData?.password || ""}
                onChange={e => {
                  setPostData({
                    ...postData,
                    [e.target.id]: e.target.value
                  });
                }}
              />
            </div>
            <div className="col mb-20">
              <label htmlFor="password_confirmation">
                Confirm new password
              </label>
              <TextInput
                type="password"
                id="password_confirmation"
                name="password_confirmation"
                className="input-lg pl-25"
                errors={changePasswordError}
                iconClass="fas fa-lock"
                iconPosition="left"
                value={postData?.password_confirmation || ""}
                onChange={e =>
                  setPostData({
                    ...postData,
                    [e.target.id]: e.target.value
                  })
                }
              />
            </div>

            <div className="row fr">
              <Button
                id="change-password"
                disabled={isChangePasswordLoading}
                onClick={() => handleSubmit()}
                className="btn btn--info"
              >
                Change password
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
