import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Custom Components
import * as usersAction from "store/users/users.action";
import useLocalStorage, { EV_USER } from "hooks/useLocalStorage";
import IconAvatar from "components/icons/icon-avatar/icon-avatar.component";
import ProfileMenu from "./profile-menu/profile-menu.component";

// Styles
import "./profile.styles.scss";

const Profile = () => {
  const dispatch = useDispatch();
  const loggedUserProfile = useSelector(
    state => state.users[usersAction.LOGGED_USER_PROFILE]
  );

  const [loggedUser] = useLocalStorage(EV_USER, null);
  const [showMenu, setShowMenu] = useState(false);

  // If the logged user profile could load from the state, try re-fetching from server
  useEffect(() => {
    if (!loggedUserProfile && loggedUser?.id) {
      dispatch(usersAction.getLoggedUserProfile(loggedUser.id));
    }
  }, [loggedUserProfile, loggedUser, dispatch]);

  // Window event listener
  useEffect(() => {
    function closeMenu(e) {
      if (!e.target.classList.contains("avatar--md")) {
        setShowMenu(false);
      }
    }
    window.addEventListener("click", closeMenu);

    return () => window.removeEventListener("click", closeMenu);
  }, [setShowMenu]);

  const handleMenuToggle = () => {
    setShowMenu(prevState => !prevState);
  };

  return (
    <div className="app__header--user-avatar mr-20" tabIndex="0">
      <IconAvatar
        source={`${loggedUserProfile?.firstname} ${loggedUserProfile?.lastname}`}
        alt={`${loggedUserProfile?.firstname} ${loggedUserProfile?.lastname}`}
        size="md"
        handleOnClick={handleMenuToggle}
      />

      {/* Profile Menu  */}
      <ProfileMenu showMenu={showMenu} setShowMenu={setShowMenu} />
    </div>
  );
};

export default Profile;

