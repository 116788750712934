import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

// Styles
import "./text-input.styles.scss";

function TextInput(props) {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Set error messages etc
  useEffect(() => {
    setHasError(false);
    setErrorMessage("");
    if (props?.errors?.length) {
      props.errors.forEach(error => {
        if (error.field === props.id) {
          setHasError(true);
          setErrorMessage(error.message);
        }
      });
    }
  }, [props.id, props.errors]);

  const renderInputType = () => {
    switch (props.type) {
      case "textarea":
        return (
          <textarea
            id={props.id}
            name={props.name}
            type={props.type}
            errors={props.errors}
            placeholder={props.placeholder}
            autoComplete={props.autoComplete}
            value={props.value}
            onKeyUp={e => {
              // If user presses the backspace to delete chars
              if (e.keyCode === 8) {
                props.onChange(e);
                setHasError(false);
              }
              if (e.keyCode === 13) {
                props.onKeyUp(e);
                setHasError(false);
              }
            }}
            onChange={e => {
              props.onChange(e);
              setHasError(false);
            }}
            onKeyPress={e => {
              props.onKeyPress(e);
              setHasError(false);
            }}
            className={classnames(props.className, {
              danger: hasError
            })}
            tabIndex={props.hasTabIndex ? "0" : "-1"}
            aria-labelledby={props.ariaLabelledby}
            readOnly={props.readOnly}
          />
        );
      default:
        return (
          <input
            id={props.id}
            name={props.name}
            type={props.type}
            errors={props.errors}
            placeholder={props.placeholder}
            autoComplete={props.autoComplete}
            value={props.value}
            onKeyUp={e => {
              // If user presses the backspace to delete chars
              if (e.keyCode === 8) {
                props.onChange(e);
                setHasError(false);
              }
              if (e.keyCode === 13) {
                props.onKeyUp(e);
                setHasError(false);
              }
            }}
            readOnly={props.readOnly}
            onChange={e => {
              props.onChange(e);
              setHasError(false);
            }}
            onKeyPress={e => {
              props.onKeyPress(e);
              setHasError(false);
            }}
            className={classnames(props.className, {
              danger: hasError
            })}
            tabIndex={props.hasTabIndex ? "0" : "-1"}
            aria-labelledby={props.ariaLabelledby}
          />
        );
    }
  };

  return (
    <div className="text__input--wrapper">
      <div className="text__input">
        {props?.iconClass && props.iconPosition === "left" && (
          <i
            className={`text__input--icon text__input--icon-left ${props.iconClass}`}
          ></i>
        )}

        {/* Render input based on its type */}
        {renderInputType()}

        {props?.iconClass && props.iconPosition === "right" && (
          <i
            className={`text__input--icon text__input--icon-right ${props.iconClass}`}
          ></i>
        )}
      </div>
      {hasError && (
        <div className="text-danger text-md mt-3" id="errorMessage">
          {errorMessage}
        </div>
      )}
    </div>
  );
}

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  errors: PropTypes.array,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  iconClass: PropTypes.string,
  iconPosition: PropTypes.string,
  hasTabIndex: PropTypes.bool,
  ariaLabelledby: PropTypes.string,
  readOnly: PropTypes.bool
};

TextInput.defaultProps = {
  autoComplete: "off",
  hasTabIndex: true,
  onKeyPress: () => {},
  onKeyDown: () => {},
  onKeyUp: () => {}
};

export default TextInput;
