import { useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// Custom Components
import http from "helpers/http/http.helper";

const useHttp = url => {
  const history = useHistory();
  const [status, setStatus] = useState(0);
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(null);
  const [postData, setPostData] = useState(null);
  const [responseNotifications, setResponseNotifications] = useState(null);

  async function get(params = {}) {
    try {
      setIsLoading(true);
      setResponse(null);
      setStatus(0);
      setResponseNotifications(null);
      setHasError(false);
      setError(null);
      const response = await http().get(url, {
        params
      });
      setStatus(response?.data?.status);
      setResponse(response);
    } catch (error) {
      setHasError(true);
      if (error?.response?.data?.errors) {
        setError(error.response.data.errors);
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function post(postData) {
    try {
      setIsLoading(true);
      setResponse(null);
      setStatus(0);
      setResponseNotifications(null);
      setHasError(false);
      setError(null);
      const response = await http().post(url, postData);
      setStatus(response?.data?.status);
      setResponseNotifications(response?.data?.notifications);
      setResponse(response);
    } catch (error) {
      setHasError(true);
      if (error?.response?.data?.errors) {
        setError(error.response.data.errors);
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function destroy(data) {
    try {
      setIsLoading(true);
      setResponse(null);
      setStatus(0);
      setResponseNotifications(null);
      setHasError(false);
      setError(null);
      const response = await http().delete(url, { data });
      setStatus(response?.data?.status);
      setResponseNotifications(response?.data?.notifications);
      setResponse(response);
    } catch (error) {
      setHasError(true);
      if (error?.response?.data?.errors) {
        setError(error.response.data.errors);
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function put(postData) {
    try {
      setIsLoading(true);
      setResponse(null);
      setStatus(0);
      setResponseNotifications(null);
      setHasError(false);
      setError(null);
      const response = await http().put(url, postData);
      setStatus(response?.data?.status);
      setResponseNotifications(response?.data?.notifications);
      setResponse(response);
    } catch (error) {
      setHasError(true);
      if (error?.response?.data?.errors) {
        setError(error.response.data.errors);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return {
    get,
    post,
    destroy,
    put,
    setError,
    setPostData,
    status,
    setStatus,
    response,
    responseNotifications,
    isLoading,
    hasError,
    error
  };
};

useHttp.propTypes = {
  url: PropTypes.string.isRequired
};

export default useHttp;
