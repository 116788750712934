import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import {
  TAB_INTRO,
  TAB_SETUP,
  TAB_VERIFY,
  TAB_CONFIRM
} from "../user-2fa.container";


// Styles
import "./2fa-tabs.styles.scss";

const TwoFactorTabs = ({ activeTab }) => {
  return (
    <div
      className={classnames("app__2fa--tabs", {
        hidden: ![TAB_INTRO, TAB_SETUP, TAB_VERIFY, TAB_CONFIRM].includes(
          activeTab
        )
      })}
    >
      <div
        className={classnames("app__2fa--tab", {
          "app__2fa--tab-active": activeTab === TAB_INTRO
        })}
      >
        Get Started
      </div>
      <div
        className={classnames("app__2fa--tab", {
          "app__2fa--tab-active": activeTab === TAB_SETUP
        })}
      >
        Setup
      </div>
      <div
        className={classnames("app__2fa--tab", {
          "app__2fa--tab-active": activeTab === TAB_VERIFY
        })}
      >
        Verify Code
      </div>
      <div
        className={classnames("app__2fa--tab", {
          "app__2fa--tab-active": activeTab === TAB_CONFIRM
        })}
      >
       Confirmation
      </div>
    </div>
  );
};

TwoFactorTabs.propTypes = {
  activeTab: PropTypes.string
};

export default TwoFactorTabs;
