import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

// Custom Components
import * as usersAction from "store/users/users.action";
import BasicModal from "components/modals/base/default/default-modal.component";
import Button from "components/form/buttons/button/button.component";
import TextInput from "components/form/inputs/text/text-input.component";
import notification from "helpers/notification/notification.helper";

// Hooks
import useHttp from "hooks/useHttp";

// Styles
import "./confirm-disable-modal.styles.scss";

function ConfirmDisableModal({ setShowModal }) {
  const params = useParams();
  const dispatch = useDispatch();
  const [postData, setPostData] = useState({ token: "", password: "" });

  const {
    destroy: disableTwoFactor,
    isLoading: disableTwoFactorLoading,
    response,
    responseNotifications,
    error: disableTwoFactorError,
    setError,
    status
  } = useHttp(`users/${params.id}/two-factor`);

  // If the disable was successful
  useEffect(() => {
    if (responseNotifications) {
      notification.success(responseNotifications);
      setShowModal(false);
    }
    // eslint-disable-next-line
  }, [responseNotifications]);

  // There was an error while disabling the 2fa
  useEffect(() => {
    if (disableTwoFactorError) {
      notification.error(disableTwoFactorError);
    }
    // eslint-disable-next-line
  }, [disableTwoFactorError]);

  // Fetch the user profile when the component unloads
  useEffect(() => {
    return () => {
      dispatch(usersAction.getUserProfile(params.id));
    };
    // eslint-disable-next-line
  }, [dispatch, params]);

  const handleSubmit = () => {
    disableTwoFactor(postData);
  };

  return (
    <BasicModal
      title="disable_two_factor_authentication_form"
      setShowModal={setShowModal}
      width="auto"
      height="auto"
    >
      <div className="app__form-container">
        <div className="col mb-20 wrm-30">
          <label htmlFor="password">Password</label>
          <TextInput
            type="password"
            id="password"
            name="password"
            className="input-lg pl-25"
            errors={disableTwoFactorError}
            iconClass="fas fa-key"
            iconPosition="left"
            value={postData?.password || ""}
            placeholder="Password"
            onChange={e =>
              setPostData({
                ...postData,
                [e.target.id]: e.target.value
              })
            }
          />

          <div className="text-help text-grey-900 mt-5">
            Enter your current password
          </div>
        </div>
        <div className="col mb-20 wrm-30">
          <label htmlFor="token">Token</label>
          <TextInput
            type="text"
            id="token"
            name="token"
            className="input-lg pl-25"
            errors={disableTwoFactorError}
            iconClass="fas fa-key"
            iconPosition="left"
            value={postData?.token || ""}
            placeholder="Token"
            onChange={e =>
              setPostData({
                ...postData,
                [e.target.id]: e.target.value
              })
            }
          />
          <div className="text-help text-grey-900 mt-5">
            Enter the verification code that is shown in your authenticator app
            or a backup code
          </div>
        </div>
        <div className="col wrm-30">
          <Button
            id="submit"
            onClick={() => handleSubmit()}
            disabled={disableTwoFactorLoading}
            className="btn btn--primary btn--block"
          >
            Disable two factor authentication
          </Button>
        </div>
      </div>
    </BasicModal>
  );
}

ConfirmDisableModal.propTypes = {
  setShowModal: PropTypes.func.isRequired
};

export default ConfirmDisableModal;
