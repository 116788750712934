import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

// Styles
import "./radio.styles.scss";

function Radio({
  defaultChecked = false,
  ariaLabel = "",
  ariaLabelledBy = "",
  hasTabIndex,
  handleOnClick = () => {}
}) {
  const variants = {
    open: { opacity: 1, scale: 1 },
    closed: { opacity: 0, scale: 0 }
  };
  const onKeyDownHandler = e => {
    if (e.which === 13) {
      handleOnClick(defaultChecked);
    }
  };
  return (
    <div
      className="radio__wrapper"
      onClick={() => {
        handleOnClick(defaultChecked);
      }}
    >
      <div className="radio__circle">
        <motion.div
          animate={defaultChecked ? "open" : "closed"}
          variants={variants}
          transition={{ duration: 0.2 }}
          className="radio__circle-checked"
        >
          <i
            className="fas fa-check"
            id={`radio-${ariaLabelledBy}`}
            role="radio"
            tabIndex={hasTabIndex ? "0" : "-1"}
            aria-checked={defaultChecked ? "true" : "false"}
            aria-label={ariaLabel}
            aria-labelledby={ariaLabelledBy}
            onKeyDown={onKeyDownHandler}
          ></i>
        </motion.div>
      </div>
    </div>
  );
}

Radio.propTypes = {
  defaultChecked: PropTypes.bool,
  ariaLabel: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
  hasTabIndex: PropTypes.bool,
  handleOnClick: PropTypes.func
};

Radio.defaultProps = {
  hasTabIndex: true
};
export default Radio;
