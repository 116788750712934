import React from "react";

import Search from "./search/search.component";
import Profile from "./profile/profile.component";

// Styles
import "./header.styles.scss";

const Header = () => {
  return (
    <div className="app__header text-md">
      {/* Header Left Col  */}
      <div className="app__header--left-col ml-20 ">
        {/* Search Box  */}
        <Search />
      </div>

      {/* Header Right Column  */}
      <div className="app__header--right-col">
        {/* Profile */}
        <Profile/>
      </div>
    </div>
  );
};

export default Header;
