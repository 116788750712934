function debounce(fn, wait) {
    let timer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => fn.apply(context, args), wait);
    };
  }
  
  export default debounce;
  