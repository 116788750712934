// Custom Components
import http from "helpers/http/http.helper";

// GET DOMAINS
export const DOMAINS_LOADING = "DOMAINS_LOADING";
export const GET_DOMAINS = "GET_DOMAINS";
export const DOMAINS_ERRORS = "DOMAINS_ERRORS";

// DOMAINS
export const getDomains = () => async dispatch => {
  try {
    dispatch({
      type: DOMAINS_LOADING,
      payload: true
    });
    const response = await http().get(`/domains`);
    dispatch({
      type: GET_DOMAINS,
      payload: response.data
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: DOMAINS_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: DOMAINS_LOADING,
      payload: false
    });
  }
};
