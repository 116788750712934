import React from "react";
import PropTypes from "prop-types";

function ErrorLabel({ errors, field } = {}) {
  return (
    <>
      {errors &&
        errors?.length > 0 &&
        errors.map(error => {
          if (error?.field === field) {
            return (
              <div key={field} className="text-danger text-md">
                {error?.message}
              </div>
            );
          }

          return null;
        })}
    </>
  );
}

ErrorLabel.propTypes = {
  errors: PropTypes.array,
  field: PropTypes.string.isRequired
};

export default ErrorLabel;