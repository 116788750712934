import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Custom Components
import NotificationMessage from "./notification-message.component";

// Styles
import "./notification.styles.scss";

const Notification = {
  success: (message, { position = "top-right", autoClose = 5000 } = {}) => {
    if (!message) return;
    toast.info(<NotificationMessage message={message} />, {
      position,
      autoClose,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 1,
      progress: undefined
    });
  },
  error: (
    message,
    { position = "bottom-right", autoClose = 5000, onClick = () => {} } = {}
  ) => {
    if (!message) return;
    toast.error(<NotificationMessage message={message} />, {
      position,
      autoClose,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 1,
      progress: undefined,
      onClick: onClick
    });
  }
};

export default Notification;