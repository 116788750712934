import React from "react";
import classnames from "classnames";

import Header from "../drawer-modal-header/drawer-modal-header.component";

// Styles
import "./drawer-modal-dialog.styles.scss";

const drawerModal = ({
  title,
  showModal,
  setShowModal,
  children,
  styles = {},
}) => {
  return (
    <div className="app__drawer-modal--wrapper">
      {showModal && <div className="overlay"></div>}
      <div
        className={classnames("app__drawer-modal--menu", {
          "app__drawer-modal--menu-show": showModal,
        })}
        style={{ ...styles }}
      >
        {/* Header */}
        <Header title={title} setShowModal={setShowModal} />

        {children}
      </div>
    </div>
  );
};

export default drawerModal;
