import React from "react";
import PropTypes from "prop-types";

// Images
import LogoLoader from "assets/img/logos/logo-md.png";
import CircleLoader from "assets/img/loaders/circle.svg";
import InfiniteLoader from "assets/img/loaders/infinite.svg";
import EllipsisLoader from "assets/img/loaders/ellipsis.svg";

export const loaderTypes = {
  LogoLoader,
  CircleLoader,
  InfiniteLoader,
  EllipsisLoader
};

function Loading({
  isLoading,
  children,
  width = "100",
  loaderType = InfiniteLoader,
  className = "",
  wrapperClassName = "",
  imageClassName = "row justify-center align-center w-100 h-100"
}) {
  return (
    <div className={wrapperClassName}>
      {isLoading && (
        <div className={imageClassName}>
          <img
            alt="Loading..."
            className={`display-block ${className}`}
            width={width}
            src={loaderType}
          />
        </div>
      )}
      {!isLoading && children}
    </div>
  );
}

Loading.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.any,
  width: PropTypes.string,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string
};

export default Loading;
