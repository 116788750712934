import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

// Styles
import "./switch.styles.scss";

const Switch = ({
  checked,
  setChecked,
  disabled = false,
  ariaLabelledBy = ""
}) => {
  const handleToggleOption = () => {
    const isChecked = !checked;
    setChecked(isChecked);
  };

  const onKeyDownHandler = e => {
    if (e.which === 13) {
      setChecked(!checked);
    }
  };
  return (
    <div
      className={classnames("switch__wrapper", {
        disabled
      })}
    >
      <div
        className="switch__container"
        tabIndex="0"
        role="switch"
        aria-checked={checked ? "true" : "false"}
        aria-labelledby={ariaLabelledBy}
        onKeyDown={onKeyDownHandler}
      >
        <div
          className={classnames("switch__option switch__option--bg-yes", {
            "switch__option--yes": checked
          })}
          onClick={handleToggleOption}
        >
          <i className="fas fa-check"></i>
        </div>
        <div
          className="switch__option switch__option--white"
          onClick={handleToggleOption}
        >
          &nbsp;
        </div>
        <div
          className={classnames("switch__option switch__option--bg-no", {
            "switch__option--no": !checked
          })}
          onClick={handleToggleOption}
        >
          <i className="fas fa-ban"></i>
        </div>
      </div>
    </div>
  );
};

Switch.propTypes = {
  setChecked: PropTypes.func,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  disabled: PropTypes.bool,
  ariaLabelledBy: PropTypes.string
};

export default Switch;