import React from "react";

import DefaultLayout from "layouts/default/default.layout";

// Styles
import "./manage-tags.styles.scss";

const ManageTags = ({ children }) => {
  return (
    <DefaultLayout>
      <div className="container">
        {/* Content Header */}
        <div className="app__data--listing-header">
          <div className="app__data--listing-header-top mb-20">
            <div className="app__data--listing-header-left-col">
              <div className="app__data--listing-title">
                <i className={`fas fa-tags `}></i> Manage Tags
              </div>
            </div>
          </div>
        </div>

        {/* Content Body */}
        <div className="app__item--account--wrapper mb-20 mt-20">
          <div className="card app__item--account-card">
            <div className="app__item--account--container">
              <div className="app__item--title">TAGS</div>
              <div className="app__item--description">View and Manage Tags</div>
              {children}
            </div>
          </div>
        </div>

        {/* End Container */}
      </div>
    </DefaultLayout>
  );
};

export default ManageTags;
