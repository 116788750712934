import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// Custom Components
import * as usersAction from "store/users/users.action";
import TwoFactorTabs from "./2fa-tabs/2fa-tabs.component";
import TwoFactorIntro from "./2fa-intro/2fa-intro.container";
import TwoFactorSetup from "./2fa-setup/2fa-setup.container";
import TwoFactorVerify from "./2fa-verify/2fa-verify.container";
import TwoFactorConfirmation from "./2fa-confirmation/2fa-confirmation.container";
import TwoFactorDisable from "./2fa-disable/2fa-disable.container";

// Styles
import "./user-2fa.styles.scss";

// Constants
export const TAB_INTRO = "intro";
export const TAB_SETUP = "setup";
export const TAB_VERIFY = "verify";
export const TAB_CONFIRM = "confirm";
export const TAB_DISABLE_2FA = "disable_2fa";

const TwoFactorAuthentication = () => {
  const [activeTab, setActiveTab] = useState(TAB_INTRO);
  const [backupCodes, setBackupCodes] = useState([]);
  
  const userProfile = useSelector(
    state => state.users[usersAction.USER_PROFILE]
  );
    // Set an active tab based on the two factor is enabled/disabled
    useEffect(() => {
      if (userProfile?.is_two_factor_enabled) {
        setActiveTab(TAB_DISABLE_2FA);
      } else {
        setActiveTab(TAB_INTRO);
      }
    }, [userProfile]);

  return (
    <>
      <h4>Two factor Authentication</h4>

      <div className="hr mt-20"></div>

      <div className="app__2fa--wrapper mt-20">
        {/* Tabs */}
        <TwoFactorTabs activeTab={activeTab} />

        {/* Tab Content */}
        <div className="app__2fa--content-wrapper">
          {/* Intro */}
          <TwoFactorIntro activeTab={activeTab} setActiveTab={setActiveTab} />
          {/* Setup */}
          <TwoFactorSetup activeTab={activeTab} setActiveTab={setActiveTab} />
          {/* Verify */}
          <TwoFactorVerify
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            setBackupCodes={setBackupCodes}
          />
          {/* Confirm */}
          <TwoFactorConfirmation
            activeTab={activeTab}
            backupCodes={backupCodes}
          />
          {/* Disable Two Factor */}
          {activeTab === TAB_DISABLE_2FA && <TwoFactorDisable />}
          {/* End content wrapper */}
        </div>
      </div>
    </>
  );
};

export default TwoFactorAuthentication;