import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Custom Module
import * as customersAction from "store/customers/customers.action";
import * as subscriptionsAction from "store/subscriptions/subscriptions.action";

// Hooks
import useDate from "hooks/useDate";

// Styles
import "./customer-profile.styles.scss";

export const CANCELLED = "Cancelled";
export const ACTIVE = "Active";

const Profile = () => {
  const moment = useDate();
  const subscriptionHistory = useSelector(
    state => state.subscriptions[subscriptionsAction.GET_SUBSCRIPTIONS]
  );
  const customerProfile = useSelector(
    state => state.customers[customersAction.GET_CUSTOMER]
  );
  const [licenseValidity, setLicenseValidity] = useState("");
  const [activeSubscriptionType, setActiveSubscriptionType] = useState("");
  const [licenseLastUpdated, setLicenseLastUpdated] = useState("");
  
  useEffect(() => {
    for (let i = 0; i < subscriptionHistory?.data.length; i++) {
      if (subscriptionHistory?.data[i].subscription_status?.type === ACTIVE) {
        setLicenseValidity(subscriptionHistory?.data[i]?.end_date);
        setActiveSubscriptionType(
          subscriptionHistory?.data[i]?.plan?.plan_name
        );
        setLicenseLastUpdated(subscriptionHistory?.data[i]?.start_date);
        break;
      }
    }
  }, [subscriptionHistory]);

  return (
    <div>
      <h4 className="app__user--profile-form-heading">Configuration</h4>
      {/*profile info container */}
      <div className="app__user--profile-form">
        <div className="hr mt-20"></div>

        <div className="mt-30 col-2">
          <label htmlFor="email" className="text-md mr-20 text-right">
            URL
          </label>
          <div className="app__user--label-entry">
            {customerProfile?.customer_domain}
          </div>
        </div>

        <div className="mt-30 col-2">
          <label htmlFor="firstname" className="text-md  mr-20 text-right">
            Server
          </label>
          <div className="app__user--label-entry">
            {customerProfile?.server ? customerProfile?.server : "--"}
          </div>
        </div>

        <div className="mt-30 col-2">
          <label htmlFor="lastname" className="text-md  mr-20 text-right">
            License Validity
          </label>
          <div className="app__user--label-entry">
            {licenseValidity ? moment(licenseValidity).format("LL") : "--"}
          </div>
        </div>

        <div className="mt-30 col-2">
          <label htmlFor="title" className="text-md  mr-20 text-right">
            Active Subscription Type
          </label>
          <div className="app__user--label-entry">
            {activeSubscriptionType ? activeSubscriptionType : "--"}
          </div>
        </div>

        <div className="mt-30 col-2">
          <label htmlFor="title" className="text-md  mr-20 text-right">
            Last Login
          </label>
          <div className="app__user--label-entry">
            {customerProfile?.last_login
              ? moment(customerProfile?.last_login).format("LL")
              : "--"}
          </div>
        </div>

        <div className="mt-30 col-2">
          <label htmlFor="title" className="text-md  mr-20 text-right">
            Last Login User
          </label>
          <div className="app__user--label-entry">
            {customerProfile?.last_login
              ? customerProfile?.last_login_user
              : "--"}
          </div>
        </div>

        <div className="mt-30 col-2">
          <label htmlFor="title" className="text-md  mr-20 text-right">
            No. of Users
          </label>
          <div className="app__user--label-entry">
            {" "}
            {customerProfile?.no_of_users ? customerProfile?.no_of_users : "--"}
          </div>
        </div>

        <div className="mt-30 col-2">
          <label htmlFor="title" className="text-md  mr-20 text-right">
            License Last Updated
          </label>
          <div className="app__user--label-entry">
            {" "}
            {licenseLastUpdated
              ? moment(licenseLastUpdated).format("LL")
              : "--"}
          </div>
        </div>
        <div className="mt-30 col-2">
          <label htmlFor="title" className="text-md  mr-20 text-right">
            Point of Contact
          </label>
          <div className="app__user--label-entry">
            {customerProfile?.primary_admin_firstname}
          </div>
        </div>
        <div className="hr my-30"></div>

        {/* End Form Wrapper */}
      </div>
    </div>
  );
};

export default Profile;
