import axios from "axios";
import PropTypes from "prop-types";

const http = (useAuthorization = true) => {
  let locale = window.localStorage.getItem("EV_LOCALE");
  let token = window.localStorage.getItem("EV_TOKEN");

  let tokenHeaders = {};
  if (token !== null && useAuthorization) {
    tokenHeaders = {
      authorization: JSON.parse(token)
    };
  }

  const http = axios.create({
    baseURL: "/api",
    timeout: 10000,
    headers: {
      accepts: "application/json",
      "Content-Type": "application/json",
      "Accept-Language": "en",
      "Access-Control-Allow-Methods": "POST, GET , PUT , PATCH , DELETE",
      "Access-Control-Allow-Headers": "Content-Type",
      "x-portal": "web",
      "x-customer-hostname": window.location.origin,
      "x-service-name": "service-master-console",
      ...tokenHeaders
    }
  });

  return http;
};

http.propTypes = {
  service: PropTypes.string.isRequired
};

export default http;
