import * as actions from "./subscriptions.action";

const subscriptionsReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.SUBSCRIPTIONS_LOADING:
      return {
        ...state,
        [actions.SUBSCRIPTIONS_LOADING]: action.payload
      };
    case actions.GET_SUBSCRIPTIONS:
      return {
        ...state,
        [actions.GET_SUBSCRIPTIONS]: action.payload
      };
    case actions.SUBSCRIPTIONS_ERRORS:
      return {
        ...state,
        [actions.SUBSCRIPTIONS_ERRORS]: action.payload
      };
    case actions.SUBSCRIPTION_ADD_LOADING:
      return {
        ...state,
        [actions.SUBSCRIPTION_ADD_LOADING]: action.payload
      };
    case actions.ADD_SUBSCRIPTION:
      return {
        ...state,
        [actions.ADD_SUBSCRIPTION]: action.payload
      };
    case actions.SUBSCRIPTION_ADD_ERROR:
      return {
        ...state,
        [actions.SUBSCRIPTION_ADD_ERROR]: action.payload
      };
    case actions.SUBSCRIPTION_DELETE_LOADING:
      return {
        ...state,
        [actions.SUBSCRIPTION_DELETE_LOADING]: action.payload
      };
    case actions.DELETE_SUBSCRIPTION:
      return {
        ...state,
        [actions.DELETE_SUBSCRIPTION]: action.payload
      };
    case actions.SUBSCRIPTION_DELETE_ERROR:
      return {
        ...state,
        [actions.SUBSCRIPTION_DELETE_ERROR]: action.payload
      };
    default:
      return state;
  }
};

export default subscriptionsReducer;
