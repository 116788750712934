import React from "react";

// Styles
import "./drawer-modal-footer.styles.scss";

const Footer = ({ children }) => {
  return <div className="app__drawer-modal--footer">{children}</div>;
};

export default Footer;
