// Custom Components
import http from "helpers/http/http.helper";

// GET CUSTOMERS
export const CUSTOMERS_LOADING = "CUSTOMERS_LOADING";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const CUSTOMERS_ERRORS = "CUSTOMERS_ERRORS";

// GET CUSTOMER
export const CUSTOMER_LOADING = "CUSTOMER_LOADING";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const CUSTOMER_ERRORS = "CUSTOMER_ERRORS";

// UPDATE CUSTOMERS
export const CUSTOMER_UPDATING = "CUSTOMER_UPDATING";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const CUSTOMER_UPDATED = "CUSTOMER_UPDATED";
export const UPDATE_CUSTOMER_ERROR = "UPDATE_CUSTOMER_ERROR";

// ADD CUSTOMER
export const CUSTOMER_ADD_LOADING = "CUSTOMER_ADD_LOADING";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const CUSTOMER_ADD_ERROR = "CUSTOMER_ADD_ERROR";

// SUSPEND CUSTOMER
export const SUSPEND_CUSTOMER_LOADING = "SUSPEND_CUSTOMER_LOADING";
export const SUSPEND_CUSTOMER = "SUSPEND_CUSTOMER";
export const SUSPEND_CUSTOMER_ERRORS = "SUSPEND_CUSTOMER_ERRORS";

// TERMINATE CUSTOMER
export const TERMINATE_CUSTOMER_LOADING = "TERMINATE_CUSTOMER_LOADING";
export const TERMINATE_CUSTOMER = "TERMINATE_CUSTOMER";
export const TERMINATE_CUSTOMER_ERRORS = "TERMINATE_CUSTOMER_ERRORS";

// EXPORT CUSTOMERS
export const EXPORT_CUSTOMERS_LOADING = "EXPORT_CUSTOMERS_LOADING";
export const EXPORT_CUSTOMERS = "EXPORT_CUSTOMERS";
export const EXPORT_CUSTOMERS_ERRORS = "EXPORT_CUSTOMERS_ERRORS";

// EXPORT SELECTED CUSTOMERS
export const EXPORT_SELECTED_CUSTOMERS_LOADING = "EXPORT_SELECTED_CUSTOMERS_LOADING";
export const EXPORT_SELECTED_CUSTOMERS = "EXPORT_SELECTED_CUSTOMERS";
export const EXPORT_SELECTED_CUSTOMERS_ERRORS = "EXPORT_SELECTED_CUSTOMERS_ERRORS";

// EXPORT FILTERED CUSTOMERS
export const EXPORT_FILTERED_CUSTOMERS_LOADING = "EXPORT_FILTERED_CUSTOMERS_LOADING";
export const EXPORT_FILTERED_CUSTOMERS = "EXPORT_FILTERED_CUSTOMERS";
export const EXPORT_FILTERED_CUSTOMERS_ERRORS = "EXPORT_FILTERED_CUSTOMERS_ERRORS";

// Customers
export const getCustomers = params => async dispatch => {
  try {
    dispatch({
      type: CUSTOMERS_LOADING,
      payload: true
    });
    const response = await http().get(`/customers`, { params });
    dispatch({
      type: GET_CUSTOMERS,
      payload: response.data
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: CUSTOMERS_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: CUSTOMERS_LOADING,
      payload: false
    });
  }
};

export const updateCustomer = customer => async dispatch => {
  try {
    dispatch({
      type: CUSTOMER_UPDATING,
      payload: true
    });
    const finalPostData = {
      ...customer,
      customer_domain: `${customer.subdomain}${customer.domain}`
    };
    finalPostData.tags = finalPostData.tags.map(tag => tag.id);
    const response = await http().put(
      `/customers/${customer.id}`,
      finalPostData
    );
    dispatch({
      type: UPDATE_CUSTOMER,
      payload: response.data
    });
    dispatch({
      type: CUSTOMER_UPDATED,
      payload: response.data
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: UPDATE_CUSTOMER_ERROR,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: CUSTOMER_UPDATING,
      payload: false
    });
  }
};

export const getCustomer = customerId => async dispatch => {
  try {
    dispatch({
      type: CUSTOMER_LOADING,
      payload: true
    });
    const response = await http().get(`/customers/${customerId}`);
    dispatch({
      type: GET_CUSTOMER,
      payload: response.data.data
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: CUSTOMER_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: CUSTOMER_LOADING,
      payload: false
    });
  }
};

export const addCustomer = postData => async dispatch => {
  try {
    dispatch({
      type: CUSTOMER_ADD_LOADING,
      payload: true
    });
    const finalPostData = {
      ...postData,
      customer_domain: `${postData.subdomain}${postData.domain}`
    };
    finalPostData.tags = finalPostData.tags.map(tag => tag.id);
    const response = await http().post("/customers", finalPostData);
    dispatch({
      type: ADD_CUSTOMER,
      payload: response.data
    });
    dispatch(getCustomers());
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: CUSTOMER_ADD_ERROR,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: CUSTOMER_ADD_LOADING,
      payload: false
    });
  }
};

export const suspendCustomer = (customerId) => async dispatch => {
  try {
    dispatch({
      type: SUSPEND_CUSTOMER_LOADING,
      payload: true
    });
    const response = await http().put(`/customers/${customerId}/suspend`);
    dispatch({
      type: SUSPEND_CUSTOMER,
      payload: response.data.data
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: SUSPEND_CUSTOMER_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: SUSPEND_CUSTOMER_LOADING,
      payload: false
    });
  }
};

export const terminateCustomer = (customerId) => async dispatch => {
  try {
    dispatch({
      type: TERMINATE_CUSTOMER_LOADING,
      payload: true
    });
    const response = await http().put(`/customers/${customerId}/terminate`);
    dispatch({
      type: TERMINATE_CUSTOMER,
      payload: response.data.data
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: TERMINATE_CUSTOMER_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: TERMINATE_CUSTOMER_LOADING,
      payload: false
    });
  }
};

export const exportCustomers = () => async dispatch => {
  try {
    dispatch({
      type: EXPORT_CUSTOMERS_LOADING,
      payload: true
    });
    const response = await http().post(`/customers/export`, {});
    dispatch({
      type: EXPORT_CUSTOMERS,
      payload: response
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: EXPORT_CUSTOMERS_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: EXPORT_CUSTOMERS_LOADING,
      payload: false
    });
  }
};

export const exportSelectedCustomers = postData => async dispatch => {
  try {
    dispatch({
      type: EXPORT_SELECTED_CUSTOMERS_LOADING,
      payload: true
    });
    const response = await http().post(`/customers/export-selected`, {
      postData
    });
    dispatch({
      type: EXPORT_SELECTED_CUSTOMERS,
      payload: response
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: EXPORT_SELECTED_CUSTOMERS_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: EXPORT_SELECTED_CUSTOMERS_LOADING,
      payload: false
    });
  }
};

export const exportFilteredCustomers = params => async dispatch => {
  try {
    dispatch({
      type: EXPORT_FILTERED_CUSTOMERS_LOADING,
      payload: true
    });
    const response = await http().post(`/customers/export-filtered-customers`, { params });
    dispatch({
      type: EXPORT_FILTERED_CUSTOMERS,
      payload: response
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: EXPORT_FILTERED_CUSTOMERS_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: EXPORT_FILTERED_CUSTOMERS_LOADING,
      payload: false
    });
  }
};
