import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

//Styles
import "./input-field.styles.scss";
const InputField = ({
  setShowAddContactPersonModal,
  customer,
  handleRemoveContactPerson,
  errors
}) => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // Set error messages etc
  useEffect(() => {
    setHasError(false);
    setErrorMessage("");
    if (errors?.length) {
      errors.forEach(error => {
        if (error.field.startsWith("contact_persons")) {
          setHasError(true);
          setErrorMessage(error.message);
        }
      });
    }
  }, [errors]);

  useEffect(() => {
    if (customer?.contact_persons?.length) {
      setHasError(false);
    }
  },[customer]);

  return (
    <div className="input-group mb-20">
      <div className="customer__input-items">
        <input
          type="email"
          className="input-xxl customer__input"
          id="contact-email"
        />
        <i
          className={`fas fa-plus customer__input-icon`}
          onClick={() => setShowAddContactPersonModal(true)}
        ></i>
      </div>
      {customer?.contact_persons?.length > 0 && (
        <div className="customer__input-item-container">
          <div className="customer__input-item" key="contactPerson-heading">
            <div>Name</div>
            <div>Phone</div>
            <div>Email</div>
            <div>Delete Item</div>
          </div>
          {customer?.contact_persons?.map(contactPerson => (
            <div className="customer__input-item" key={contactPerson.name}>
              <div>
                {contactPerson.primary ? "*" : null}
                {contactPerson.name}
              </div>
              <div>{contactPerson.phone}</div>
              <div>{contactPerson.email}</div>

              <i
                className={`fas fa-trash customer__input-item-icon`}
                onClick={() => {
                  handleRemoveContactPerson(contactPerson);
                  setHasError(false);
                }}
              ></i>
            </div>
          ))}
        </div>
      )}
      {hasError && (
        <div className="text-danger text-md mt-3" id="errorMessage">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

InputField.propTypes = {
  setShowAddContactPersonModal: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  handleRemoveContactPerson: PropTypes.func.isRequired,
  errors: PropTypes.array
};

export default InputField;
