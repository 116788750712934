import * as actions from "./plans.action";

const plansReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.PLANS_LOADING:
      return {
        ...state,
        [actions.PLANS_LOADING]: action.payload
      };
    case actions.GET_PLANS:
      return {
        ...state,
        [actions.GET_PLANS]: action.payload
      };
    case actions.PLANS_ERRORS:
      return {
        ...state,
        [actions.PLANS_ERRORS]: action.payload
      };
    case actions.PLAN_LOADING:
      return {
        ...state,
        [actions.PLAN_LOADING]: action.payload
      };
    case actions.GET_PLAN:
      return {
        ...state,
        [actions.GET_PLAN]: action.payload
      };
    case actions.PLAN_ERRORS:
      return {
        ...state,
        [actions.PLAN_ERRORS]: action.payload
      };
    case actions.PLAN_UPDATING:
      return {
        ...state,
        [actions.PLAN_UPDATING]: action.payload
      };
    case actions.UPDATE_PLAN:
      return {
        ...state,
        [actions.UPDATE_PLAN]: action.payload
      };
    case actions.PLAN_UPDATED:
      return {
        ...state,
        [actions.PLAN_UPDATED]: action.payload
      };
    case actions.UPDATE_PLAN_ERROR:
      return {
        ...state,
        [actions.UPDATE_PLAN_ERROR]: action.payload
      };

    default:
      return state;
  }
};

export default plansReducer;
