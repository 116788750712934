import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

// Custom Module
import * as customersAction from "store/customers/customers.action";

import IconAvatar from "components/icons/icon-avatar/icon-avatar.component";

// Styles
import "./customer-account-sidebar.styles.scss";

const UserAccountSideBar = () => {
  const customerProfile = useSelector(
    state => state.customers[customersAction.GET_CUSTOMER]
  );
  return (
    <>
      <div className="app__user--account-sidebar-top">
        <IconAvatar
          source={`${customerProfile?.company_display_name}`}
          alt={`${customerProfile?.company_display_name}`}
          size="xl"
          classes="avatar__border"
        />

        <div className="app__user--account-sidebar-name mt-10">{`${customerProfile?.company_full_legal_name}`}</div>
      </div>

      {/* Bottom Navigations */}
      <div className="app__user--account-sidebar-bottom mt-20">
        <ul>
          <li>
            <NavLink
              to={`/manage-customers/${customerProfile?.id}/profile`}
              activeClassName="app__user--account-sidebar-active"
            >
              <i className="fas fa-cog"></i> <span>Configuration</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/manage-customers/${customerProfile?.id}/contacts`}
              activeClassName="app__user--account-sidebar-active"
            >
              <i className="fas fa-phone"></i> <span>Contacts</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/manage-customers/${customerProfile?.id}/subscription-history`}
              activeClassName="app__user--account-sidebar-active"
            >
              <i className="fas fa-history"></i>{" "}
              <span>Subscription History</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default UserAccountSideBar;
