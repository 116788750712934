import React from "react";
import { useSelector } from "react-redux";

// Custom Module
import NoData, { EmptyBox } from "components/no-data/no-data.component";
import * as customersAction from "store/customers/customers.action";

const UserContacts = () => {
  const customerProfile = useSelector(
    state => state.customers[customersAction.GET_CUSTOMER]
  );

  return (
    <div>
      <h4 className="app__user--profile-form-heading">Contact Numbers</h4>
      {customerProfile?.contact_persons?.length !== 0 ? (
        <div>
          <div className="app__user--profile-form">
            {/*table */}
            <table className="table mt-20">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Phone Number</th>
                  <th>Email</th>
                </tr>
              </thead>
              {/*table body*/}
              <tbody>
                {/* Row */}
                {customerProfile?.contact_persons.map(contactPerson => (
                  <tr key={contactPerson?.email}>
                    <td className="text-muted">{contactPerson?.name}</td>
                    <td className="text-muted">{contactPerson?.phone}</td>
                    <td className="text-muted">{contactPerson?.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="hr my-30"></div>

            {/* End Form Wrapper */}
          </div>
        </div>
      ) : (
        <NoData
          title={"No Contact found"}
          body={"There seems to be no Contact"}
          wrapperClass="mt-20"
          imageClass="w-50"
          imageComponent={EmptyBox}
        />
      )}
    </div>
  );
};

export default UserContacts;
