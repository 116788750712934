import React from 'react';
import {Bar} from 'react-chartjs-2';

import "./bar-charts.styles.scss"

const barChart = ({
    state,
    title,
    options
  }) => {
    return (
        <div className="app__chart-container">
            <div className="app__chart-heading">{title}</div>
            <Bar data={state} options={options}/>
        </div>
    );
  }

export default barChart;