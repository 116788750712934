import React from "react";
import { Link } from "react-router-dom";

import DefaultLayout from "layouts/default/default.layout";
import Button from "components/form/buttons/button/button.component";
import Pagination from "components/pagination/pagination.component";


export const SELECTED_USERS = "Selected Users";
export const GENERATE_NEW_PASSWORD = "Generate New Password";

const Customer = () => {

  const handleButton = value => {
    switch (value) {
      case GENERATE_NEW_PASSWORD:
        break;
      default:
        break;
    }
  };


  return (
    <DefaultLayout>
      <div className="container">
        {/* Content Header */}

          <div className="app__data--listing-header">
            <div className="app__data--listing-header-top mb-20">
              <div className="app__data--listing-header-left-col">
                <div className="app__data--listing-title">
                <Link to="/manage-customers" className="mr-10">
                  <i
                    className="fas fa-long-arrow-alt-left"
                    data-tip="Back to Manage Customers"
                  ></i>
                </Link> 
                  <i className={`fas fa-users app__navigation--item-icon`} ></i> Customer1's Users</div>
              </div>
              <div className="app__data--listing-header-right-col">
                {/* Button */}
                <Button 
                   icon="fas fa-key"
                  color="info"
                  label= {GENERATE_NEW_PASSWORD}
                  handleButton={handleButton}></Button>
              </div>
            </div>
            </div>
        {/* Content Body */}

        <table className="table mt-20">
          <thead>
            <tr>
              <th>
                <input type="checkbox" className="mr-15" id="" />
                User's Name
              </th>
              <th><div>Username</div></th>
              <th><div>Email</div></th>
            </tr>
          </thead>

          <tbody>
            {/* Row 1 */}
            <tr>
              <td>
                <div className="table__col--main">
                  <div className="mr-10">
                    <input type="checkbox" className="mr-15" id="test" />
                  </div>
                  <div className="title-wrapper">
                    Person 1
                  </div>
                </div>
              </td>
              <td className="text-muted">Person_1</td>
              <td className="text-muted">person1@gmail.com</td>
              <td>
                <div className="table__action--button-wrapper">
                  <ul>
                    <li className="table__action--button">
                      <Link to="#">Generate New Password</Link>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>

            {/* Row 2 */}
            <tr>
              <td>
                <div className="table__col--main">
                  <div className="mr-10">
                    <input type="checkbox" className="mr-15" id="test" />
                  </div>
                  <div className="title-wrapper">
                  Person 2
                  </div>
                </div>
              </td>
              <td className="text-muted">Person_2</td>
              <td className="text-muted">person2@gmail.com</td>
              <td>
                <div className="table__action--button-wrapper">
                  <ul>
                    <li className="table__action--button">
                      <Link to="#">Generate New Password</Link>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>

            {/* Row 3 */}
            <tr>
              <td>
                <div className="table__col--main">
                  <div className="mr-10">
                    <input type="checkbox" className="mr-15" id="test" />
                  </div>
                  <div className="title-wrapper">
                  Person 3
                  </div>
                </div>
              </td>
              <td className="text-muted">Person_3</td>
              <td className="text-muted">person3@gmail.com</td>
              <td>
                <div className="table__action--button-wrapper">
                  <ul>
                    <li className="table__action--button">
                      <Link to="#">Generate New Password</Link>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>

            {/* Row 4 */}
            <tr>
              <td>
                <div className="table__col--main">
                  <div className="mr-10">
                    <input type="checkbox" className="mr-15" id="test" />
                  </div>
                  <div className="title-wrapper">
                  Person 4
                  </div>
                </div>
              </td>
              <td className="text-muted">Person_4</td>
              <td className="text-muted">person4@gmail.com</td>
              <td>
                <div className="table__action--button-wrapper">
                  <ul>
                    <li className="table__action--button">
                      <Link to="#">Generate New Password</Link>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>

            {/* Row 5 */}
            <tr>
              <td>
                <div className="table__col--main">
                  <div className="mr-10">
                    <input type="checkbox" className="mr-15" id="test" />
                  </div>
                  <div className="title-wrapper">
                  Person 5
                  </div>
                </div>
              </td>
              <td className="text-muted">Person_5</td>
              <td className="text-muted">person5@gmail.com</td>
              <td>
                <div className="table__action--button-wrapper">
                  <ul>
                    <li className="table__action--button">
                      <Link to="#">Generate New Password</Link>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {/* Pagination */}
        <Pagination />

        {/* End Container */}
      </div>
    </DefaultLayout>
  );
};

export default Customer;