import React from "react";
import PropTypes from "prop-types";

import DrawerModal from "components/modals/base/drawer/drawer-modal.component";
import Button from "components/form/buttons/button/button.component";

const UserAddFooter = ({
  isUserLoading,
  handleCreateOne,
  handleCreateMultiple,
  handleCancel,
  showAddModal
}) => {
  return (
    <DrawerModal.Footer>
      <Button
        id="create-user"
        isLoading={isUserLoading}
        onClick={() => handleCreateOne()}
        className="btn btn--primary btn--xxl"
        tabIndex={showAddModal ? "0" : "-1"}
      >
        Create user
      </Button>
      <Button
        id="create-add-another"
        isLoading={isUserLoading}
        onClick={handleCreateMultiple}
        className="btn btn--outline-primary btn--xxl"
        tabIndex={showAddModal ? "0" : "-1"}
      >
        Create & add another
      </Button>
      <Button
        id="cancel"
        onClick={handleCancel}
        className="btn btn--outline-primary btn--xxl"
        tabIndex={showAddModal ? "0" : "-1"}
      >
        Cancel
      </Button>
    </DrawerModal.Footer>
  );
};

UserAddFooter.propTypes = {
  isUserLoading: PropTypes.bool,
  handleCreateOne: PropTypes.func.isRequired,
  handleCreateMultiple: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  showAddModal: PropTypes.bool
};

UserAddFooter.defaultProps = {
  showAddModal: false
};
export default UserAddFooter;
