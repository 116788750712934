import React from "react";
import PropTypes from "prop-types";
// Styles
import "./blank.styles.scss";
const Loader = ({ children }) => {
  return <div className="loader__wrapper">{children}</div>;
};
Loader.propTypes = {
  children: PropTypes.object.isRequired
};
export default Loader;