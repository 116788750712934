import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

// Custom Components
import * as customersAction from "store/customers/customers.action";
import * as subscriptionsAction from "store/subscriptions/subscriptions.action";
import DefaultLayout from "layouts/default/default.layout";
import CustomerAccountSideBar from "./customer-account-sidebar/customer-account-sidebar.container";
import CustomerProfile from "./customer-profile/customer-profile.container";
import CustomerContacts from "./customer-contacts/customer-contacts.container";
import CustomerSubscriptionHistory from "./customer-subscription-history/customer-subscription-history.container";
import Loading from "components/loading/loading.component";

// Styles
import "./customer-account.styles.scss";

const UserAccount = ({ location }) => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const isCustomerProfileLoading = useSelector(
    state => state.customers[customersAction.CUSTOMER_LOADING]
  );
  const customerProfile = useSelector(
    state => state.customers[customersAction.GET_CUSTOMER]
  );

  const isSubscriptionHistoryLoading = useSelector(
    state => state.subscriptions[subscriptionsAction.SUBSCRIPTIONS_LOADING]
  );
  const subscriptionHistory = useSelector(
    state => state.subscriptions[subscriptionsAction.GET_SUBSCRIPTIONS]
  );

  // Fetch the customer profile from api
  useEffect(() => {
    if (params?.id) {
      dispatch(customersAction.getCustomer(params.id));
      dispatch(subscriptionsAction.getSubscriptionHistory(params.id));
    }
  }, [params, dispatch]);

  {
    /* dynamic layouts*/
  }
  const loadComponent = () => {
    const { pathname } = location;
    if (pathname.search("profile") !== -1) {
      return <CustomerProfile />;
    } else if (pathname.search("contacts") !== -1) {
      return <CustomerContacts />;
    } else if (pathname.search("subscription-history") !== -1) {
      return <CustomerSubscriptionHistory />;
    } else if (pathname.search("emails") !== -1) {
      return <CustomerEmails />;
    }
  };

  return (
    <DefaultLayout>
      <div className="container">
        {/* Content Header */}
        <div className="app__data--listing-header">
          <div className="app__data--listing-header-top mb-20">
            <div className="app__data--listing-header-left-col">
              <div className="app__data--listing-title">
                <Link to="/manage-customers" className="mr-10">
                  <i
                    className="fas fa-long-arrow-alt-left"
                    data-tip="Back to Manage Customers"
                  ></i>
                </Link>
                Account
              </div>
            </div>
          </div>
        </div>

        {/* Content Body */}
        <div className="app__user--account--wrapper mb-20 mt-20">
          <div className="card app__user--account-card">
            {/* Loading */}
            <div className="row justify-center">
              <Loading isLoading={isCustomerProfileLoading} />
            </div>
            {/* If the profile is available */}
            {!isCustomerProfileLoading && customerProfile && (
              <div className="app__user--account--container">
                {/* Left Col */}
                <div className="app__user--account-left-col">
                  <CustomerAccountSideBar />
                </div>
                {/* Right Col */}
                <div className="app__user--account-right-col">
                  {loadComponent()}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* End Container */}
      </div>
    </DefaultLayout>
  );
};

export default UserAccount;
