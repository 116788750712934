import React from "react";

// Styles
import "./navigation-logo.styles.scss";

import Logo from "assets/img/logos/logo-xs.png";

const NavigationLogo = () => {
  return (
    <div className="app__navigation--logo">
      <img className="app__header-logo" src={Logo} alt="EisenVault" />
    </div>
  );
};

export default NavigationLogo;
