import React from "react";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

// Custom Module
import * as usersAction from "store/users/users.action";

// Hooks
import useDate from "hooks/useDate";

// Styles
import "./user-profile-view.styles.scss";

const ProfileView = () => {
  const moment = useDate();

  const userProfile = useSelector(
    state => state.users[usersAction.USER_PROFILE]
  );

  return (
    <>
      <h4>Profile</h4>
      <div className="hr mt-20"></div>
      <div className="app__user--profile-board">
        <div className="mt-50">
          <h5 className="app__user--account-subtitle underlined">
            Personal Information
          </h5>
        </div>

        <div className="mt-30 col-2">
          <label htmlFor="email" className="text-md mr-20 text-right">
            Email Address
          </label>
          <div className="app__user--profile-board-value">
            {userProfile.email}
          </div>
        </div>

        <div className="mt-30 col-2">
          <label htmlFor="firstname" className="text-md mr-20 text-right">
            Firstname
          </label>
          <div className="app__user--profile-board-value">
            {userProfile.firstname}
          </div>
        </div>

        <div className="mt-30 col-2">
          <label htmlFor="lastname" className="text-md mr-20 text-right">
            Lastname
          </label>
          <div className="app__user--profile-board-value">
            {userProfile.lastname}
          </div>
        </div>

        <div className="mt-30 col-2 align-center">
          <label htmlFor="title" className="text-md mr-20 text-right">
            Job Title
          </label>
          <div className="app__user--profile-board-value">
            {userProfile.job_title || "--"}
          </div>
        </div>

        <ReactTooltip />
        <div className="hr my-30"></div>

        {/* Timestamps  */}
        <div className="mt-50">
          <h5 className="app__user--account-subtitle underlined">Timestamps</h5>
        </div>

        <div className="mt-30 col-2">
          <label htmlFor="title" className="text-md mr-20 text-right">
            User Created
          </label>
          <div
            className="app__user--profile-board-value"
            data-tip={moment(userProfile.created_at).format(
              "MMMM Do YYYY, h:mm a"
            )}
          >
            {moment(userProfile.created_at).fromNow() || "--"}
          </div>
        </div>

        <div className="mt-30 col-2">
          <label htmlFor="title" className="text-md mr-20 text-right">
            Last Updated
          </label>
          <div
            className="app__user--profile-board-value"
            data-tip={moment(userProfile.updated_at).format(
              "MMMM Do YYYY, h:mm a"
            )}
          >
            {moment(userProfile.updated_at).fromNow() || "--"}
          </div>
        </div>

        {/* End Form Wrapper */}
      </div>
    </>
  );
};

export default ProfileView;
