import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import * as usersAction from "store/users/users.action";
import DefaultLayout from "layouts/default/default.layout";
import Pagination from "components/pagination/pagination.component";
import Button from "components/form/buttons/button/button.component";
import SearchCollapsable from "components/form/search/search-collapsable/search-collapsable.component";
import UserAddModal from "./modals/user-add/user-add.component";
import UserCard from "./user-card/user-card";
import Loading from "components/loading/loading.component";

// Styles
import "./user-listing.styles.scss";

export const ADD_USER = "Add User";

const UserListing = ({ location: { state } }) => {
  const dispatch = useDispatch();
  const [showSearch, setShowSearch] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [parameters, setParameters] = useState({
    name: "",
    page: 1,
    limit: 15
  });
  const usersResponse = useSelector(
    state => state.users[usersAction.GET_USERS]
  );
  const isUsersLoading = useSelector(
    state => state.users[usersAction.USER_LOADING]
  );
  const usersErrors = useSelector(
    state => state.users[usersAction.USER_ERRORS]
  );

  // If a state is found, restore it in the local state store
  useEffect(() => {
    if (state) {
      setParameters(state);
    }
  }, [state]);

  // Load the user list on paginate click
  useEffect(() => {
    dispatch(usersAction.getUsers(parameters));
    // eslint-disable-next-line
  }, [parameters]);
  
  const handleButton = () => {
    setShowAddModal(true);
  }


  return (
    <DefaultLayout>
      <section>
        <div className="container">
          {/* Show Add User Modal   */}
          {
            <UserAddModal
              setShowAddModal={setShowAddModal}
              showAddModal={showAddModal}
              userFilters={parameters}
              setUserFilters={setParameters}
            />
          }

          {/* Content Header */}
          <div className="app__data--listing-header">
            <div className="app__data--listing-header-top mb-20">
              <div className="app__data--listing-header-left-col">
                <div className="app__data--listing-title">Users</div>
              </div>
              <div className="app__data--listing-header-right-col">
                {/* Button */}
                <Button
                  icon="plus"
                  id="button"
                  className="btn--dropdown-form btn btn--info btn--icon"
                  icon="fas fa-plus"
                  ariaLabel="more_options_to_add_user"
                  onClick={handleButton}
                  >Add User</Button>
              </div>
            </div>

            <div className="app__data--listing-header-intro text-muted">
              Users are the intrinsic part of the system
            </div>

            {/* Filters & Search */}
            <div className="app__data--listing--filter mt-20">
              <div className="app__data--listing--filter-menu"></div>
              <SearchCollapsable
                dataTip="Search User"
                defaultValue={location?.state?.name}
                showSearch={location?.state?.name ? true : showSearch}
                setShowSearch={setShowSearch}
                placeholder="Search by Name, Email"
                state={parameters}
                onChange={(targetValue, state) => {
                  setParameters({
                    ...state,
                    page: 1,
                    name: targetValue
                  });
                }}
              />
              <div className="hr mt-20"></div>
            </div>
          </div>

          {/* Content Body */}
          <div className="mb-30">
            {/* Loading */}
            <Loading
              isLoading={isUsersLoading}
              wrapperClassName=""
            >
              <div className="justify-center">
                <div className="app__user--card-wrapper mt-20">
                  {/* Card */}
                  {usersResponse?.data?.length &&
                    usersResponse.data.map(user => (
                      <UserCard
                        key={user.id}
                        user={user}
                        parameters={parameters}
                      />
                    ))}
                </div>
              </div>
            </Loading>
          </div>

          {/* Pagination */}
          {!isUsersLoading && usersResponse?.meta ? (
            <Pagination
              meta={usersResponse?.meta}
              wrapperClass="mt-50"
              onClick={page => {
                setParameters({
                  ...parameters,
                  page: +page.selected + 1
                });
              }}
            />
          ) : null}
          {/* End Container */}
        </div>
      </section>
    </DefaultLayout>
  );
};

export default UserListing;
