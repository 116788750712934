// Custom Components
import http from "helpers/http/http.helper";

// Logged user profile
export const LOGGED_USER_PROFILE = "LOGGED_USER_PROFILE";

// Other user profile
export const USER_LOADING = "USER_LOADING";
export const GET_USERS = "GET_USERS";
export const USER_PROFILE = "USER_PROFILE";
export const USER_PROFILE_UPDATED = "USER_PROFILE_UPDATED";
export const USER_PROFILE_TWO_FACTOR_ENABLED =
  "USER_PROFILE_TWO_FACTOR_ENABLED";
export const USER_PROFILE_TWO_FACTOR_DISABLED =
  "USER_PROFILE_TWO_FACTOR_DISABLED";
export const USER_ERRORS = "USER_ERRORS";

// User Add
export const USER_ADD_LOADING = "USER_ADD_LOADING";
export const ADD_USER = "ADD_USER";
export const USER_ADD_ERROR = "USER_ADD_ERROR";

//User Update 
export const USER_UPDATE_LOADING = "USER_UPDATE_LOADING";
export const UPDATE_USER = "UPDATE_USER";
export const USER_UPDATE_ERROR = "USER_UPDATE_ERROR";

//Enable Disable User
export const USER_ENABLE_DISABLE_LOADING = "USER_ENABLE_DISABLE_LOADING";
export const ENABLE_DISABLE_USER = "ENABLE_DISABLE_USER";
export const ENABLE_DISABLE_USER_ERROR = "ENABLE_DISABLE_USER_ERROR";


// Logged User Profile
export const getLoggedUserProfile = userId => async dispatch => {
  try {
    dispatch({
      type: USER_LOADING,
      payload: true
    });
    const response = await http().get(`/users/${userId}`);
    dispatch({
      type: LOGGED_USER_PROFILE,
      payload: response.data.data
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: USER_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: USER_LOADING,
      payload: false
    });
  }
};

export const setLoggedUserProfile = loggedUser => async dispatch => {
  dispatch({
    type: LOGGED_USER_PROFILE,
    payload: loggedUser
  });
};

export const disableTwoFactorLoggedUser = user => async dispatch => {
  try {
    dispatch({
      type: USER_LOADING,
      payload: true
    });

    const response = await http().delete(`/users/${user.id}/two-factor`);

    dispatch({
      type: LOGGED_USER_PROFILE,
      payload: response.data.data
    });

    dispatch({
      type: USER_PROFILE_TWO_FACTOR_DISABLED,
      payload: response.data.notifications
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: USER_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: USER_LOADING,
      payload: false
    });
  }
};

// Other user Profile
export const setUserProfile = user => async dispatch => {
  dispatch({
    type: USER_PROFILE,
    payload: user
  });
};

export const getUserProfile = userId => async dispatch => {
  try {
    dispatch({
      type: USER_LOADING,
      payload: true
    });
    const response = await http().get(`/users/${userId}`);
    dispatch({
      type: USER_PROFILE,
      payload: response.data.data
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: USER_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: USER_LOADING,
      payload: false
    });
  }
};

// Users
export const getUsers = params => async dispatch => {
  try {
    dispatch({
      type: USER_LOADING,
      payload: true
    });
    const response = await http().get(`/users`, { params });
    dispatch({
      type: GET_USERS,
      payload: response.data
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: USER_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: USER_LOADING,
      payload: false
    });
  }
};

export const addUser = postData => async dispatch => {
  try {
    dispatch({
      type: USER_ADD_LOADING,
      payload: true
    });
    const response = await http().post("/users", postData);
    dispatch({
      type: ADD_USER,
      payload: response.data
    });
    dispatch(getUsers());
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: USER_ADD_ERROR,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: USER_ADD_LOADING,
      payload: false
    });
  }
};

export const updateUserProfile = user => async dispatch => {
  try {
    dispatch({
      type: USER_UPDATE_LOADING,
      payload: true
    });
    const response = await http().put(`/users/${user.id}`, user);
    dispatch({
      type: USER_PROFILE,
      payload: response.data.data
    });
    dispatch({
      type: USER_PROFILE_UPDATED,
      payload: response.data.notifications
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: USER_UPDATE_ERROR,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: USER_UPDATE_LOADING,
      payload: false
    });
  }
};

export const disableTwoFactor = user => async dispatch => {
  try {
    dispatch({
      type: USER_LOADING,
      payload: true
    });

    const response = await http().delete(`/users/${user.id}/two-factor`);

    dispatch({
      type: USER_PROFILE,
      payload: response.data.data
    });
    dispatch({
      type: USER_PROFILE_TWO_FACTOR_DISABLED,
      payload: response.data.notifications
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: USER_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: USER_LOADING,
      payload: false
    });
  }
};

export const enableDisableUser = (userId, isActive) => async dispatch => {
  try {
    dispatch({
      type: USER_ENABLE_DISABLE_LOADING,
      payload: true
    });

    let endpoint = "enable";
    if (!isActive) {
      endpoint = "disable";
    }

    const response = await http().put(`users/${userId}/${endpoint}`);
    dispatch({
      type: ENABLE_DISABLE_USER,
      payload: response.data.data
    });
    dispatch({
      type: USER_PROFILE_UPDATED,
      payload: response.data.notifications
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: ENABLE_DISABLE_USER_ERROR,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: USER_ENABLE_DISABLE_LOADING,
      payload: false
    });
  }
};