import React from "react";
import { useDispatch } from "react-redux";

//custom Components
import * as usersAction from "store/users/users.action";
import * as plansAction from "store/plans/plans.action";
import * as tagsAction from "store/tags/tags.action";
import NavigationLogo from "./navigation-logo/navigation-logo.component";
import NagivationItem from "./navigation-item/navigation-item.component";

// Styles
import "./navigation.styles.scss";

function Navigation() {
  const dispatch = useDispatch();

  return (
    <aside className="app__navigation">
      <div className="app__navigation--icons-wrapper">
        <div className="section-top">
          {/* Navigation Logo */}
          <NavigationLogo />

          <div className="app__navigation--links-container mt-20">
            {/* Customers */}
            <NagivationItem
              to="/manage-customers"
              iconClassName="fas fa-users"
              tip="customers"
            />

            {/* Manage Tags */}
            <NagivationItem
              to="/manage-tags"
              iconClassName="fas fa-tags"
              tip="Manage Tags"
              onClick={() => {
                dispatch(tagsAction.getTags());
              }}
            />

            {/* Plans */}
            <NagivationItem
              to="/plans"
              iconClassName="fas fa-money-check-alt"
              tip="Plans"
              onClick={() => {
                dispatch(plansAction.getPlans());
              }}
            />

            {/* Users*/}
            <NagivationItem
              to="/user"
              iconClassName="fas fa-users"
              tip="Users"
              onClick={() => {
                dispatch(usersAction.getUsers());
              }}
            />
          </div>
        </div>
      </div>
    </aside>
  );
}

export default Navigation;
