import { useState } from "react";
import { useDispatch } from "react-redux";

// Custom Components
import * as usersAction from "store/users/users.action";
import http from "helpers/http/http.helper";
import useLocalStorage, {
  EV_LOCALE,
  EV_TOKEN,
  EV_USER,
  EV_REMEMBER_ME
} from "hooks/useLocalStorage";

const useAuth = () => {
  const dispatch = useDispatch();
  const [locale, setLocale] = useLocalStorage(EV_LOCALE, null);
  const [token, setToken] = useLocalStorage(EV_TOKEN, null);
  const [user, setUser] = useLocalStorage(EV_USER, null);
  const [rememberMe, setRememberMe] = useLocalStorage(EV_REMEMBER_ME, 0);
  const [isAuthLoading, setIsAuthLoading] = useState(false);
  const [isAuthError, setIsAuthError] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [authResponse, setAuthResponse] = useState(null);

  async function handleAuthentication(postData) {
    try {
      setIsAuthLoading(true);
      const response = await http().post("/auth/login", postData);
      dispatch(usersAction.setLoggedUserProfile(response.data.data.user));
      setRememberMe(true);
      setToken(response?.data?.data?.token);
      setUser(response.data.data.user);
      setAuthResponse(response.data);
    } catch (error) {
      setIsAuthError(true);
      if (error?.response?.data?.errors) {
        setAuthError(error.response.data.errors);
      }
    } finally {
      setIsAuthLoading(false);
    }
  }

  return {
    setEmail,
    setPassword,
    email,
    password,
    locale,
    setLocale,
    handleAuthentication,
    authResponse,
    isAuthLoading,
    token,
    user,
    rememberMe,
    isAuthError,
    authError,
    setAuthError
  };
};

export default useAuth;
