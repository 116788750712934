import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { TAB_INTRO, TAB_SETUP } from "../user-2fa.container";
import TwoFactorOne from "assets/img/illustrations/2fa-1.png";
import Button from "components/form/buttons/button/button.component";

function TwoFactorIntro({ activeTab, setActiveTab }) {
  return (
    <div
      className={classnames(
        "app__2fa--content",
        {
          "app__2fa--content-active": activeTab === TAB_INTRO
        },
        {
          "app__2fa--content-inactive": activeTab === TAB_SETUP
        }
      )}
    >
      <img className="hero" src={TwoFactorOne} alt="Two Factor Step 1" />

      <p className="app__2fa--content-text mt-30">
        Add another layer of security to your eisenvault account by requiring
        access to your phone whenever you log in.
        Once activated, you'll need to
        enter both your password and an authentication code from your mobile
        phone to log in to your EisenVault account.
      </p>

      <div className="row justify-center mt-40">
        <Button
          id="intro"
          className="btn btn--info"
          onClick={() => setActiveTab(TAB_SETUP)}
        >
          Get Started
        </Button>
      </div>
    </div>
  );
}

TwoFactorIntro.propTypes = {
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func.isRequired
};

export default TwoFactorIntro;
