import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";

// Custom Components
import Button from "components/form/buttons/button/button.component";
import ErrorLabel from "components/form/error-label/error-label.component";
import notification from "helpers/notification/notification.helper";

// Hooks
import useHttp from "hooks/useHttp";

function ForgotPassword({
  showForgotPassword,
  setShowSignIn,
  setShowForgotPassword
}) {
  const [postData, setPostData] = useState({});
  const {
    post: forgotPassword,
    //setPostData,
    isLoading: isForgotPasswordLoading,
    responseNotifications,
    error: forgotPasswordError
  } = useHttp("auth/forgot-password");

  // When a success message is received
  useEffect(() => {
    if (responseNotifications) {
      notification.success(responseNotifications);
    }
  }, [responseNotifications]);

  return (
    <section
      className={classnames("login__form--forgot-password", {
        "login__form--forgot-password-show": showForgotPassword
      })}
    >
      {/* Welcome Message */}
      <div
        className="row mb-20 login__text-welcome justify-center"
        id="resetPasswordHeading"
      >
        Reset password
      </div>

      <p className="text-md text-muted mb-30">
        To reset your password enter the email address you normally use to login
        to eisenvault
      </p>

      {/* Form Elements */}
      <div className="col">
        <input
          type="email"
          placeholder="Email address"
          id="email_forgot_password"
          className="input-lg"
          onChange={e => setPostData({ email: e.target.value })}
        />
        <ErrorLabel field="email" errors={forgotPasswordError} />
      </div>
      <div className="my-20">
        <Button
          id="retrievePassword"
          tabIndex="1"
          disabled={
            isForgotPasswordLoading || responseNotifications ? true : false
          }
          onClick={() => forgotPassword(postData)}
          className="btn btn--info btn--block btn--lg btn--block"
        >
          Retrieve password
        </Button>
      </div>

      {/* Remember me and Forgot Password */}
      <div className="row justify-between align-center">
        <Link
          to="#"
          onClick={() => {
            setShowSignIn(true);
            setShowForgotPassword(false);
          }}
          id="backToLoginBtn"
          className="login__link text-md"
        >
          &larr; Back to login
        </Link>
      </div>
    </section>
  );
}

ForgotPassword.propTypes = {
  showForgotPassword: PropTypes.bool.isRequired,
  setShowSignIn: PropTypes.func.isRequired,
  setShowForgotPassword: PropTypes.func.isRequired
};

export default ForgotPassword;
