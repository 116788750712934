import * as actions from "./payment-modes.action";

const paymentModesReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.PAYMENTMODES_LOADING:
      return {
        ...state,
        [actions.PAYMENTMODES_LOADING]: action.payload
      };
    case actions.GET_PAYMENTMODES:
      return {
        ...state,
        [actions.GET_PAYMENTMODES]: action.payload
      };
    case actions.PAYMENTMODES_ERRORS:
      return {
        ...state,
        [actions.PAYMENTMODES_ERRORS]: action.payload
      };
    default:
      return state;
  }
};

export default paymentModesReducer;
