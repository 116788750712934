import React, { useState } from "react";

//barCharts
import BarChart from "components/charts/bar-charts/bar-charts.component";

const Activities = () => {
    {/*Labels and title for charts */}
    const activeUsers = {
        labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        datasets: [
          {
            label: 'Active Users',
            backgroundColor: '#17a2b8',
            borderColor: '#17a2b8',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: [0, 59, 80, 81, 56, 55, 40]
          }
        ],
    }

    const options =  {
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: "No. of users"
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Days'
            }
          }],
        }     
      }

      const loginOptions =  {
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: "Time in 30 mins interval"
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Days'
            }
          }],
        }     
      }

    const avgTimePerUser = {
        labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        datasets: [
          {
            label: 'Avg. time per user',
            backgroundColor: '#17a2b8',
            borderColor: '#17a2b8',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: [0, 59, 80, 81, 56, 55, 40]
          }
        ]
    }

    return (
        <div className="app__user--account-usage-body">
          {/*Container body */}
            <BarChart state={activeUsers} title="Active Users" options={options}/> 
            <BarChart state={avgTimePerUser} title="Avg. time per user" options={loginOptions}/> 
        </div>
    )
}

export default Activities;