import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Custom Components
import * as plansAction from "store/plans/plans.action";
import DefaultLayout from "layouts/default/default.layout";
import Loading, { loaderTypes } from "components/loading/loading.component";
import Plan from "./plan-card/plan-card.container";

// Styles
import "./plans.styles.scss";

const Plans = () => {
  const dispatch = useDispatch();

  const plansResponse = useSelector(
    state => state.plans[plansAction.GET_PLANS]
  );
  const isPlansLoading = useSelector(
    state => state.plans[plansAction.PLANS_LOADING]
  );
  const plansErrors = useSelector(
    state => state.plans[plansAction.PLANS_ERRORS]
  );

  useEffect(() => {
    dispatch(plansAction.getPlans());
    // eslint-disable-next-line
  }, []);

  return (
    <DefaultLayout>
      <div className="container">
        {/* Content Header */}
        <div className="app__data--listing-header">
          <div className="app__data--listing-header-top mb-20">
            <div className="app__data--listing-header-left-col">
              <div className="app__data--listing-title">
                <i className={`fas fa-money-check-alt `}></i> Plans &
                Subscriptions
              </div>
            </div>
          </div>
        </div>

        {/* Content Body */}
        {isPlansLoading ? (
          <Loading
            isLoading={isPlansLoading}
            loaderType={loaderTypes.InfiniteLoader}
          />
        ) : (
          <div className="card app__item--plan--wrapper mb-20 mt-20">
            {plansResponse?.data?.length &&
              plansResponse.data.map(plan => (
                <Plan key={plan.id} plan={plan} />
              ))}
          </div>
        )}

        {/* End Container */}
      </div>
    </DefaultLayout>
  );
};

export default Plans;
