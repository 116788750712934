import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

// Custom Components
import useToken from "hooks/useToken";
import LoaderLayout from "layouts/blank/blank.layout";
import Loading, { loaderTypes } from "components/loading/loading.component";

function PreLogin({ component: Component, path: path, ...rest }) {
  const { isTokenLoading, isTokenInvalid, tokenResponse } = useToken();

  if (isTokenLoading) {
    return (
      <LoaderLayout>
        <Loading
          isLoading={isTokenLoading}
          loaderType={loaderTypes.InfiniteLoader}
        />
      </LoaderLayout>
    );
  }

  return (
    <Route
      {...rest}
      render={props =>
        isTokenInvalid ||
        tokenResponse?.data?.two_factor_profile_locked === true ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

PreLogin.propTypes = {
  component: PropTypes.func.isRequired
};

export default PreLogin;
