import React from "react";
import DrawerModal from "components/modals/base/drawer/drawer-modal.component";
import PropTypes from "prop-types";

const AddContactFooter = ({
  setShowAddContactPersonModal,
  handleAddContactPerson,
  contactPerson
}) => {
  return (
    <DrawerModal.Footer>
      <div className="row">
        <button
          className="btn btn--primary btn--xxl mr-10"
          onClick={() => handleAddContactPerson(contactPerson)}
        >
          Save & Exit
        </button>
      </div>
      <button
        className="btn btn--outline-primary btn--xxl"
        onClick={() => setShowAddContactPersonModal(false)}
      >
        Cancel
      </button>
    </DrawerModal.Footer>
  );
};

AddContactFooter.propTypes = {
  setShowAddContactPersonModal: PropTypes.func.isRequired,
  handleAddContactPerson: PropTypes.func.isRequired,
  contactPerson: PropTypes.object.isRequired
};

export default AddContactFooter;
