import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// CUSTOM COMPONENTS
import * as baseAction from "store/base/base.action";
import * as tagsAction from "store/tags/tags.action";
import ManageTags from "../manage-tags.container";
import Loading, { loaderTypes } from "components/loading/loading.component";
import Button from "components/form/buttons/icon-button/icon-button.component";
import ManageTagsModal from "components/modals/manage-tags/manage-tags-modal.component";
import notification from "helpers/notification/notification.helper";
import Pagination from "components/pagination/pagination.component";

// Styles
import "./app-tags.styles.scss";

const SelectedTags = () => {
  const dispatch = useDispatch();
  const [showManageTagsModal, setManageTagsModal] = useState(false);
  const [parameters, setParameters] = useState({
    name: "",
    page: 1,
    limit: 10
  });

  const tagsResponse = useSelector(state => state.tags[tagsAction.GET_TAGS]);
  const isTagsLoading = useSelector(
    state => state.tags[tagsAction.TAGS_LOADING]
  );
  const tagsErrors = useSelector(state => state.tags[tagsAction.TAGS_ERRORS]);
  const deleteTagResponse = useSelector(
    state => state.tags[tagsAction.DELETE_TAG]
  );
  const isTagDeleting = useSelector(
    state => state.tags[tagsAction.TAG_DELETING]
  );
  const deleteTagNotifications = useSelector(
    state => state.tags[tagsAction.TAG_DELETED]
  );
  const deleteTagErrors = useSelector(
    state => state.tags[tagsAction.DELETE_TAG_ERROR]
  );

  // When the delete tag is successful
  useEffect(() => {
    if (deleteTagNotifications) {
      notification.success(deleteTagNotifications, {
        position: "top-right"
      });
      dispatch(tagsAction.getTags(parameters));
      dispatch(baseAction.reset(tagsAction.TAG_DELETED));
      dispatch(baseAction.reset(tagsAction.DELETE_TAG_ERROR));
    }
    // eslint-disable-next-line
  }, [deleteTagNotifications, dispatch, parameters]);

  // When there is an error deleting the tag
  useEffect(() => {
    if (deleteTagErrors && !deleteTagResponse) {
      notification.error(deleteTagErrors, {
        position: "top-right"
      });
    }
    // eslint-disable-next-line
  }, [deleteTagErrors, dispatch]);

  // Load the tags
  useEffect(() => {
    dispatch(tagsAction.getTags(parameters));
    // eslint-disable-next-line
  }, [parameters]);

  return (
    <ManageTags>
      {showManageTagsModal && (
        <ManageTagsModal
          showManageTagsModal={showManageTagsModal}
          setManageTagsModal={setManageTagsModal}
        />
      )}

      {/*Tags Container */}
      {isTagsLoading ? (
        <Loading
          isLoading={isTagsLoading}
          loaderType={loaderTypes.InfiniteLoader}
        />
      ) : (
        <div className="app__item-tag-items">
          {tagsResponse?.data &&
            tagsResponse.data.map(tag => (
              <div key={tag.id} className="app__item-tag-item-container">
                <div className="app__item-tag-item">{tag.name}</div>
                <div
                  className="app__item-tag-item-icon"
                  onClick={() => dispatch(tagsAction.deleteTag(tag.id))}
                >
                  <i
                    className={`far fa-times-circle manage__tags-input-list-item-icon`}
                  ></i>
                </div>
              </div>
            ))}
        </div>
      )}

      {/*Button */}
      <div className="app__item-manage-tags-button">
        <Button
          icon="fas fa-pen"
          color="info"
          label={"MANAGE  TAGS"}
          handleButton={() => setManageTagsModal(true)}
        ></Button>
      </div>

      {/* Pagination */}
      {!isTagsLoading && tagsResponse?.meta ? (
        <Pagination
          meta={tagsResponse?.meta}
          wrapperClass="mt-20"
          onClick={page => {
            setParameters({
              ...parameters,
              page: +page.selected + 1
            });
          }}
        />
      ) : null}
    </ManageTags>
  );
};

export default SelectedTags;
