import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Link, useHistory } from "react-router-dom";

// Custom Components
import * as baseAction from "store/base/base.action";
import * as customersAction from "store/customers/customers.action";
import * as subscriptionsAction from "store/subscriptions/subscriptions.action";
import AddContactPersonModal from "../modals/add-contact/add-contact-modal.component";
import DefaultLayout from "layouts/default/default.layout";
import CustomerTabs from "./add-customer-tabs/add-customer-tabs.container";
import CustomerInfo from "./customer-info/customer-info.container";
import SubscriptionAndBillingInfo from "./subscription-and-billing-info/subscription-and-billing-info.container";
import notification from "helpers/notification/notification.helper";
import useLocalStorage, { EV_CUSTOMER_ID } from "hooks/useLocalStorage";

const AddCustomer = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [customerId, setCustomerId] = useLocalStorage(EV_CUSTOMER_ID, null);
  const [showAddContactPersonModal, setShowAddContactPersonModal] =
    useState(false);
  const [errors, setErrors] = useState({ error: [] });
  const [contactPerson, setContactPerson] = useState({
    name: "",
    email: "",
    phone: "",
    primary: false
  });

  //Initial Customer Data
  const initCustomer = {
    id: "",
    company_full_legal_name: "",
    company_display_name: "",
    subdomain: "",
    domain: "",
    primary_admin_email: "",
    primary_admin_firstname: "",
    primary_admin_lastname: "",
    storage_limit: "",
    default_time_zone: "",
    default_language: "English",
    status: "Instance not created",
    contact_persons: [],
    tags: []
  };

  //Initial Subscription And Billing Data
  const initSubscriptionAndBillingInfo = {
    customer: "",
    plan: "",
    currency: "",
    subscription_status: "",
    start_date: "",
    end_date: "",
    increment_percentage: "",
    amount: "",
    payment_mode: "",
    payment_mode_details: "",
    coupon: ""
  };

  const [customer, setCustomer] = useState(initCustomer);
  const [subscriptionAndBilling, setSubscriptionAndBilling] = useState(
    initSubscriptionAndBillingInfo
  );
  const [customerCreated, setCustomerCreated] = useState(false);

  const addCustomerResponse = useSelector(
    state => state.customers[customersAction.ADD_CUSTOMER]
  );
  const isCustomerLoading = useSelector(
    state => state.customers[customersAction.CUSTOMER_ADD_LOADING]
  );
  const customerAddErrors = useSelector(
    state => state.customers[customersAction.CUSTOMER_ADD_ERROR]
  );
  const customerProfile = useSelector(
    state => state.customers[customersAction.GET_CUSTOMER]
  );
  const isCustomerUpdateLoading = useSelector(
    state => state.customers[customersAction.CUSTOMER_UPDATING]
  );
  const customerUpdatedResponse = useSelector(
    state => state.customers[customersAction.UPDATE_CUSTOMER]
  );
  const updateCustomerErrors = useSelector(
    state => state.customers[customersAction.UPDATE_CUSTOMER_ERROR]
  );
  const addSubscriptionResponse = useSelector(
    state => state.subscriptions[subscriptionsAction.ADD_SUBSCRIPTION]
  );
  const isSubscriptionLoading = useSelector(
    state => state.subscriptions[subscriptionsAction.SUBSCRIPTION_ADD_LOADING]
  );
  const subscriptionErrors = useSelector(
    state => state.subscriptions[subscriptionsAction.SUBSCRIPTION_ADD_ERROR]
  );
  //Check if customer is created
  useEffect(() => {
    if (customerId) {
      setCustomerCreated(true);
      setSubscriptionAndBilling(prevState => ({
        ...prevState,
        customer: customerId
      }));
      dispatch(customersAction.getCustomer(customerId));
    }
  }, [customerId, dispatch]);

  useEffect(() => {
    if (customerId && customerProfile) {
      setCustomer(customerProfile);
    }
  }, [customerProfile, customerId]);

  // When the customer creation is successful
  useEffect(() => {
    if (addCustomerResponse?.notifications) {
      notification.success(addCustomerResponse.notifications, {
        position: "top-left"
      });
      setSubscriptionAndBilling(prevState => ({
        ...prevState,
        customer: addCustomerResponse?.data?.id
      }));
      setCustomer(prevState => ({
        ...prevState,
        id: addCustomerResponse?.data?.id
      }));
      setCustomerId(addCustomerResponse?.data?.id);
      dispatch(baseAction.reset(customersAction.ADD_CUSTOMER));
      dispatch(baseAction.reset(customersAction.CUSTOMER_ADD_ERROR));
      history.push(`/manage-customers/subscription-and-billing-info`);
    }
    // eslint-disable-next-line
  }, [addCustomerResponse, dispatch]);

  // When there is an error adding the customer
  useEffect(() => {
    if (customerAddErrors && !addCustomerResponse) {
      notification.error(customerAddErrors, {
        position: "top-left"
      });
    }
  }, [customerAddErrors, dispatch]);

  // When the customer updation is successful
  useEffect(() => {
    if (customerUpdatedResponse) {
      notification.success(customerUpdatedResponse?.notifications, {
        position: "top-left"
      });
      dispatch(baseAction.reset(customersAction.UPDATE_CUSTOMER));
      dispatch(baseAction.reset(customersAction.UPDATE_CUSTOMER_ERROR));
      history.push(`/manage-customers/subscription-and-billing-info`);
    }
    // eslint-disable-next-line
  }, [customerUpdatedResponse, dispatch]);

  // When there is an error updating the customer
  useEffect(() => {
    if (updateCustomerErrors && !customerUpdatedResponse) {
      notification.error(updateCustomerErrors, {
        position: "top-left"
      });
    }
    // eslint-disable-next-line
  }, [updateCustomerErrors, customerUpdatedResponse, dispatch]);

  // When the component is unloaded, reset the error messages
  useEffect(() => {
    return () => {
      dispatch(baseAction.reset(customersAction.ADD_CUSTOMER));
      dispatch(baseAction.reset(customersAction.UPDATE_CUSTOMER));
      dispatch(baseAction.reset(customersAction.CUSTOMER_ADD_ERROR));
      dispatch(baseAction.reset(customersAction.UPDATE_CUSTOMER_ERROR));
      dispatch(baseAction.reset(subscriptionsAction.ADD_SUBSCRIPTION));
      dispatch(baseAction.reset(subscriptionsAction.SUBSCRIPTION_ADD_ERROR));
    };
  }, [dispatch]);

  // When the subscription creation is successful
  useEffect(() => {
    if (addSubscriptionResponse?.notifications) {
      notification.success(addSubscriptionResponse.notifications, {
        position: "top-left"
      });
      setCustomer(initCustomer);
      setCustomerId("");
      dispatch(baseAction.reset(subscriptionsAction.ADD_SUBSCRIPTION));
      dispatch(baseAction.reset(subscriptionsAction.SUBSCRIPTION_ADD_ERROR));
      history.push("/manage-customers");
    }
    // eslint-disable-next-line
  }, [addSubscriptionResponse, dispatch]);

  // When there is an error adding the subscription
  useEffect(() => {
    if (subscriptionErrors && !addSubscriptionResponse) {
      notification.error(subscriptionErrors, {
        position: "top-left"
      });
    }
    // eslint-disable-next-line
  }, [subscriptionErrors, dispatch]);

  const handleAddContactPerson = contactPerson => {
    if (contactPerson?.name?.length === 0) {
      setErrors({
        ...errors,
        error: [{ message: "name is required", field: "name" }]
      });
      return;
    }
    if (contactPerson?.email?.length === 0) {
      setErrors({
        ...errors,
        error: [{ message: "email is required", field: "email" }]
      });
      return;
    }
    if (contactPerson?.phone?.length === 0) {
      setErrors({
        ...errors,
        error: [{ message: "phone is required", field: "number" }]
      });
      return;
    }
    // Check if the name already exists
    const nameExists = customer.contact_persons.some(
      p => p.name === contactPerson.name
    );
    if (nameExists) return;
    // Check if the email already exists
    const emailExists = customer.contact_persons.some(
      p => p.email === contactPerson.email
    );
    if (emailExists) return;
    // Check if the phoneNumber already exists
    const numberExists = customer.contact_persons.some(
      p => p.phone === contactPerson.phone
    );
    if (numberExists) return;
    setCustomer({
      ...customer,
      contact_persons: [...customer?.contact_persons, contactPerson]
    });
    setContactPerson({
      name: "",
      email: "",
      phone: "",
      primary: false
    });
    setShowAddContactPersonModal(false);
  };

  const handleRemoveContactPerson = contactPerson => {
    if (!contactPerson) return;
    const newContacts = customer.contact_persons.filter(
      c => c.name !== contactPerson.name
    );
    setCustomer({
      ...customer,
      contact_persons: newContacts
    });
  };

  const handleCustomerFormSubmit = (customer, customerCreated) => {
    if (customerCreated) {
      dispatch(customersAction.updateCustomer(customer));
    } else {
      dispatch(customersAction.addCustomer(customer));
    }
  };

  const loadComponent = () => {
    const { pathname } = history.location;
    if (pathname.search("add-customer-info") !== -1) {
      return (
        <CustomerInfo
          customer={customer}
          setCustomer={setCustomer}
          setShowAddContactPersonModal={setShowAddContactPersonModal}
          handleRemoveContactPerson={handleRemoveContactPerson}
          contactPerson={contactPerson}
          handleCustomerFormSubmit={handleCustomerFormSubmit}
          customerCreated={customerCreated}
        />
      );
    } else if (pathname.search(`subscription-and-billing-info`) !== -1) {
      return (
        <SubscriptionAndBillingInfo
          subscriptionAndBilling={subscriptionAndBilling}
          setSubscriptionAndBilling={setSubscriptionAndBilling}
        />
      );
    }
  };

  return (
    <DefaultLayout>
      {
        <AddContactPersonModal
          showAddContactPersonModal={showAddContactPersonModal}
          setShowAddContactPersonModal={setShowAddContactPersonModal}
          handleAddContactPerson={handleAddContactPerson}
          contactPerson={contactPerson}
          setContactPerson={setContactPerson}
          errors={errors?.error}
        />
      }
      <ReactTooltip />
      <div className="container">
        {/* Content Header */}
        <div className="app__data--listing-header">
          <div className="app__data--listing-header-top mb-20">
            <div className="app__data--listing-header-left-col">
              <div className="app__data--listing-title">
                <Link to="/manage-customers" className="mr-10">
                  <i
                    className="fas fa-long-arrow-alt-left"
                    data-tip="Back to Manage Customers"
                  ></i>
                </Link>
                Add Customer
              </div>
            </div>
          </div>
        </div>

        <div className="customer-info card">
          {/* Tabs */}
          <CustomerTabs />
          {loadComponent()}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default AddCustomer;
