import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

// Custom Components
import ErrorLabel from "components/form/error-label/error-label.component";
import debounce from "helpers/debounce/debounce";

// Styles
import "./dropdown-search.styles.scss";

const DropDownSearch = ({
  title,
  options = [],
  onSelect,
  onSearch = () => {},
  onBlur = () => {},
  onClose = () => {},
  wrapperClass = "",
  errorField = "",
  errors = [],
  showSearch = true,
  textColor = "text-grey-900",
  bgColor = "bg-white-200",
  borderColor = "bdr-grey-200",
  searchBgColor = "bg-grey-100"
}) => {
  const [showMenu, toggleShowMenu] = useState(false);
  const [uniqueId, setUniqueId] = useState("");
  const [searched, setSearched] = useState(false);
  const [hasError, setHasError] = useState(false);

  // Add eventlistener to close the menu
  useEffect(() => {
    const randomId = Math.random();
    setUniqueId(randomId);
    const handleCloseComponent = e => {
      const TARGET_CLASSNAMES = [
        "dropdown__search--select-" + randomId,
        "dropdown__search--input"
      ];

      const match = TARGET_CLASSNAMES.some(
        classItem =>
          typeof e.target.className.includes === "function" &&
          e.target.className.includes(classItem)
      );

      if (typeof e.target.className.includes === "function" && !match) {
        toggleShowMenu(false);
      }
    };

    window.document.addEventListener("click", handleCloseComponent);

    return () =>
      window.document.removeEventListener("click", handleCloseComponent);
  }, []);

  // Set error messages etc
  useEffect(() => {
    setHasError(false);
    if (errors?.length) {
      errors.forEach(error => {
        if (error.field === errorField) {
          setHasError(true);
        }
      });
    }
    //  eslint-disable-next-line
  }, [errors]);

  const handleSearch = useCallback(
    debounce(targetValue => {
      onSearch(targetValue);
      setSearched(true);
    }, 700),
    []
  );

  const handleSelect = option => {
    onSelect(option);
    toggleShowMenu(false);
    setHasError(false);
  };

  return (
    <div className={wrapperClass}>
      <div
        className={`dropdown__search--wrapper ${textColor} ${bgColor} ${borderColor}`}
      >
        <div
          className={`dropdown__search--select dropdown__search--select-${uniqueId} cp`}
          onClick={() => toggleShowMenu(!showMenu)}
          tabIndex="0"
        >
          {title}
          <div>
            <i className="fas fa-caret-down"></i>
            {showMenu && (
              <i
                className="fas fa-times ml-10 text-grey-400"
                onClick={onClose}
                tabIndex="0"
                aria-label="collapse dropdown"
              ></i>
            )}
          </div>
        </div>

        {/* Options */}
        {showMenu ? (
          <div className="dropdown__search--options">
            {showSearch && (
              <div className={`dropdown__search--search-box ${searchBgColor}`}>
                <i className="fas fa-search dropdown__search--icon"></i>
                <input
                  type="search"
                  className="dropdown__search--input"
                  onChange={e => handleSearch(e.target.value)}
                  onBlur={() => {
                    if (searched) {
                      onBlur();
                      setSearched(false);
                    }
                  }}
                  placeholder="Search"
                  autoFocus
                />
              </div>
            )}

            {/* Option Items */}
            <ul className="dropdown__search--option">
              {options.map(option => (
                <li key={option.id} onClick={() => handleSelect(option)}>
                  {option.label}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
      {hasError && <ErrorLabel field={errorField} errors={errors} />}
    </div>
  );
};

DropDownSearch.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
  onSearch: PropTypes.func,
  onBlur: PropTypes.func,
  wrapperClass: PropTypes.string,
  errorField: PropTypes.string,
  errors: PropTypes.array,
  showSearch: PropTypes.bool,
  textColor: PropTypes.string,
  bgColor: PropTypes.string,
  borderColor: PropTypes.string,
  searchBgColor: PropTypes.string
};

export default DropDownSearch;
