import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

// Styles
import "./dropdown.styles.scss";

export const CHECKBOX = "checkbox";
export const RADIO = "radio";
export const NONE = "none";

const DropDown = ({
  title = "",
  options = [],
  handleFilter,
  inputType = NONE
}) => {
  const [visible, setVisible] = useState(false);

  // Add eventlistener to close the dropdown
  useEffect(() => {
    const handleCloseComponent = e => {
      const TARGET_CLASSNAME = "app__dropdown";
      if (!e.target.className.includes(TARGET_CLASSNAME)) {
        setVisible(false);
      }
    };

    window.document.addEventListener("click", handleCloseComponent);

    return () =>
      window.document.removeEventListener("click", handleCloseComponent);
  }, []);

  return (
    <div className="app__dropdown">
      <div
        className="app__dropdown--title cp"
        onClick={() => setVisible(!visible)}
      >
        <div className="app__dropdown--inner">{title}</div>
        <i className="fas fa-caret-down ml-7 app__dropdown--icon-right"></i>
      </div>

      <motion.ul
        className="app__dropdown-menu-list mt-5"
        animate={{
          opacity: visible ? 1 : 0,
          visibility: visible ? "visible" : "hidden"
        }}
      >
        {options &&
          options.map(item => (
            <li className="app__dropdown--form" key={item.value}>
              <div
                className="app__dropdown--form row cp"
                onClick={() => handleFilter(item.label)}
              >
                {inputType === NONE ? null : (
                  <input
                    type={inputType}
                    className="mr-5 app__dropdown--form"
                    value={item.value}
                    defaultChecked={item.checked}
                    onClick={handleFilter}
                    id={item.value}
                    name={title}
                    onChange={() => {}}
                  />
                )}
                <label
                  className="app__dropdown--form light cp"
                  htmlFor={item.value}
                >
                  {item.label}
                </label>
              </div>
            </li>
          ))}
      </motion.ul>
    </div>
  );
};

DropDown.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
  inputType: PropTypes.string
};

export default DropDown;
