import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Link, useParams } from "react-router-dom";
import { isEmpty } from "lodash";

// Custom Components
import debounce from "helpers/debounce/debounce";
import * as baseAction from "store/base/base.action";
import * as customersAction from "store/customers/customers.action";
import * as tagsAction from "store/tags/tags.action";
import * as localesAction from "store/locales/locales.action";
import * as timezonesAction from "store/timezones/timezones.action";
import Loading from "components/loading/loading.component";
import AddContactPersonModal from "../modals/add-contact/add-contact-modal.component";
import Tag from "components/form/dropdown-search/ dropdown-search-tag/dropdown-search-tag.component";
import InputField from "components/input-field/input-field.component";
import DropDownSearch from "components/form/dropdown-search/dropdown-search.component";
import DefaultLayout from "layouts/default/default.layout";
import notification from "helpers/notification/notification.helper";
import Button from "components/form/buttons/button/button.component";
import TextInput from "components/form/inputs/text/text-input.component";

const AddSubscription = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const customerId = params.id;

  //Initial Customer Data
  const initCustomer = {
    id: "",
    company_full_legal_name: "",
    company_display_name: "",
    subdomain: "",
    domain: "",
    primary_admin_email: "",
    primary_admin_firstname: "",
    primary_admin_lastname: "",
    storage_limit: "",
    default_time_zone: "",
    default_language: "",
    status: "Active",
    contact_persons: [],
    tags: []
  };

  const [tag, setTagName] = useState();
  const [filter, setFilter] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const [customer, setCustomer] = useState(initCustomer);
  const [showAddContactPersonModal, setShowAddContactPersonModal] =
    useState(false);
  const [errors, setErrors] = useState({ error: [] });
  const [contactPerson, setContactPerson] = useState({
    name: "",
    email: "",
    phone: "",
    primary: false
  });

  const customerProfile = useSelector(
    state => state.customers[customersAction.GET_CUSTOMER]
  );
  const isCustomerLoading = useSelector(
    state => state.customers[customersAction.CUSTOMER_LOADING]
  );
  const isCustomerUpdateLoading = useSelector(
    state => state.customers[customersAction.CUSTOMER_UPDATING]
  );
  const customerUpdatedResponse = useSelector(
    state => state.customers[customersAction.UPDATE_CUSTOMER]
  );
  const updateCustomerErrors = useSelector(
    state => state.customers[customersAction.UPDATE_CUSTOMER_ERROR]
  );
  const tags = useSelector(state => state.tags[tagsAction.GET_TAGS]);
  const addTagsResponse = useSelector(state => state.tags[tagsAction.ADD_TAGS]);
  const timezones = useSelector(
    state => state.timezones[timezonesAction.GET_TIMEZONES]
  );
  const locales = useSelector(
    state => state.locales[localesAction.GET_LOCALES]
  );

  // When the customer updation is successful
  useEffect(() => {
    if (customerUpdatedResponse) {
      notification.success(customerUpdatedResponse?.notifications, {
        position: "top-left"
      });
      dispatch(baseAction.reset(customersAction.UPDATE_CUSTOMER));
      dispatch(baseAction.reset(customersAction.UPDATE_CUSTOMER_ERROR));
    }
    // eslint-disable-next-line
  }, [customerUpdatedResponse, dispatch]);

  // When there is an error updating the customer
  useEffect(() => {
    if (updateCustomerErrors && !customerUpdatedResponse) {
      notification.error(updateCustomerErrors, {
        position: "top-left"
      });
    }
    // eslint-disable-next-line
  }, [updateCustomerErrors, customerUpdatedResponse, dispatch]);

  useEffect(() => {
    if (customerId) {
      dispatch(customersAction.getCustomer(customerId));
    }
  }, [customerId]);

  useEffect(() => {
    dispatch(timezonesAction.getTimezones({}));
    dispatch(localesAction.getLocales({}));
  }, []);

  useEffect(() => {
    if (customerProfile) {
      setCustomer(customerProfile);
    }
  }, [customerProfile]);

  // When the component is unloaded, reset the error messages
  useEffect(() => {
    return () => {
      dispatch(baseAction.reset(customersAction.UPDATE_CUSTOMER));
      dispatch(baseAction.reset(customersAction.UPDATE_CUSTOMER_ERROR));
    };
  }, [dispatch]);

  const handleAddContactPerson = contactPerson => {
    if (contactPerson?.name?.length === 0) {
      setErrors({
        ...errors,
        error: [{ message: "name is required", field: "name" }]
      });
      return;
    }
    if (contactPerson?.email?.length === 0) {
      setErrors({
        ...errors,
        error: [{ message: "email is required", field: "email" }]
      });
      return;
    }
    if (contactPerson?.phone?.length === 0) {
      setErrors({
        ...errors,
        error: [{ message: "phone is required", field: "number" }]
      });
      return;
    }
    // Check if the name already exists
    const nameExists = customer.contact_persons.some(
      p => p.name === contactPerson.name
    );
    if (nameExists) return;
    // Check if the email already exists
    const emailExists = customer.contact_persons.some(
      p => p.email === contactPerson.email
    );
    if (emailExists) return;
    // Check if the phoneNumber already exists
    const numberExists = customer.contact_persons.some(
      p => p.phone === contactPerson.phone
    );
    if (numberExists) return;
    setCustomer({
      ...customer,
      contact_persons: [...customer?.contact_persons, contactPerson]
    });
    setContactPerson({
      name: "",
      email: "",
      phone: "",
      primary: false
    });
    setShowAddContactPersonModal(false);
  };

  const handleRemoveContactPerson = contactPerson => {
    if (!contactPerson) return;
    const newContacts = customer.contact_persons.filter(
      c => c.name !== contactPerson.name
    );
    setCustomer({
      ...customer,
      contact_persons: newContacts
    });
  };

  const handleCustomerFormSubmit = customer => {
    dispatch(customersAction.updateCustomer(customer));
  };

  const handleAddTag = tag => {
    if (!tag) return;
    // Check if the tag already exists
    const tagExists = customer?.tags?.some(t => t.name === tag.name);
    if (tagExists) return;

    setCustomer({
      ...customer,
      tags: [...customer?.tags, tag]
    });
    setShowDropdown(false);
  };

  const handleRemoveTag = tag => {
    if (!tag) return;
    const newTags = customer?.tags?.filter(
      tagItem => tagItem.name !== tag.name
    );
    setCustomer({
      ...customer,
      tags: newTags
    });
  };

  const addNewTag = async value => {
    dispatch(tagsAction.addTags([{ name: value }]));
    setTagName(value);
    setShowDropdown(false);
  };

  useEffect(() => {
    if (addTagsResponse?.notifications) {
      handleAddTag({
        id: addTagsResponse?.data?.upserted[0]?.id,
        name: tag
      });
      dispatch(baseAction.reset(tagsAction.ADD_TAGS));
    }
  }, [addTagsResponse]);

  const handleTagKeyUp = e => {
    if (e.key === "Enter" && e.target.value.length) {
      addNewTag(e.target.value);
    }
  };

  const handleTagSearch = useCallback(
    debounce(targetValue => {
      setShowDropdown(true);

      setFilter(prevState => {
        const newState = {
          ...prevState,
          name: targetValue
        };

        dispatch(tagsAction.getTags(newState));

        return newState;
      });
    }, 700),
    []
  );

  return (
    <DefaultLayout>
      {
        <AddContactPersonModal
          showAddContactPersonModal={showAddContactPersonModal}
          setShowAddContactPersonModal={setShowAddContactPersonModal}
          handleAddContactPerson={handleAddContactPerson}
          contactPerson={contactPerson}
          setContactPerson={setContactPerson}
          errors={errors?.error}
        />
      }
      <ReactTooltip />

      <div className="container">
        {/* Content Header */}
        <div className="app__data--listing-header">
          <div className="app__data--listing-header-top mb-20">
            <div className="app__data--listing-header-left-col">
              <div className="app__data--listing-title">
                <Link to="/manage-customers" className="mr-10">
                  <i
                    className="fas fa-long-arrow-alt-left"
                    data-tip="Back to Manage Customers"
                  ></i>
                </Link>
                Update Customer
              </div>
            </div>
          </div>
        </div>
        {isCustomerLoading ? (
          <Loading isLoading={isCustomerLoading} />
        ) : (
          <div className="customer-info card">
            <div className="m-40">
              <div className="input-group mb-20">
                <label>
                  Company Full Legal name
                  <span className="text-danger">*</span>
                </label>
                <TextInput
                  type="text"
                  id="company_full_legal_name"
                  name="company_full_legal_name"
                  className="input-xxl pl-25"
                  errors={updateCustomerErrors}
                  iconClass="far fa-building"
                  iconPosition="left"
                  value={customer?.company_full_legal_name || ""}
                  onChange={e => {
                    const targetValue = e.target.value;
                    setCustomer(prevState => ({
                      ...prevState,
                      company_full_legal_name: targetValue
                    }));
                  }}
                />
              </div>
              <div className="input-group mb-20">
                <label>
                  Company Display Name
                  <span className="text-danger">*</span>
                </label>
                <TextInput
                  type="text"
                  id="company_display_name"
                  name="company_display_name"
                  className="input-xxl pl-25"
                  errors={updateCustomerErrors}
                  iconClass="far fa-building"
                  iconPosition="left"
                  value={customer?.company_display_name || ""}
                  onChange={e => {
                    const targetValue = e.target.value;
                    setCustomer(prevState => ({
                      ...prevState,
                      company_display_name: targetValue
                    }));
                  }}
                />
              </div>
              <div className="row justify-between w-70">
                <div className="input-group mb-20 row">
                  <label>Customer Subdomain:</label>
                  <label className="pl-10">
                    {customerProfile?.customer_domain}
                  </label>
                </div>
                <div className="input-group mb-20 pr-60 row">
                  <label>Primary Admin Email:</label>
                  <label className="pl-10">
                    {customerProfile?.primary_admin_email}
                  </label>
                </div>
              </div>
              <div className="input-group mb-20">
                <label>
                  Primary Admin First Name
                  <span className="text-danger">*</span>
                </label>
                <div className="customer__organisation">
                  <TextInput
                    type="text"
                    id="primary_admin_firstname"
                    name="primary_admin_firstname"
                    className="input-xxl pl-25"
                    errors={updateCustomerErrors}
                    iconClass="far fa-user"
                    iconPosition="left"
                    value={customer?.primary_admin_firstname || ""}
                    onChange={e => {
                      const targetValue = e.target.value;
                      setCustomer(prevState => ({
                        ...prevState,
                        primary_admin_firstname: targetValue
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="input-group mb-20">
                <label>
                  Storage
                  <span className="text-danger">*</span>
                </label>
                <div className="customer__website">
                  <TextInput
                    type="text"
                    id="primary_admin_storage"
                    name="primary_admin_storage"
                    className="input-xxl pl-25"
                    errors={updateCustomerErrors}
                    iconClass="fa fa-database"
                    iconPosition="left"
                    value={customer?.storage_limit || ""}
                    onChange={e => {
                      const targetValue = e.target.value;
                      setCustomer(prevState => ({
                        ...prevState,
                        storage_limit: targetValue
                      }));
                    }}
                  />
                  <div className="text-muted">
                    storage want to allocate in GB's
                  </div>
                </div>
              </div>
              <div className="input-group mb-20">
                <label>
                  Default Time Zone
                  <span className="text-danger">*</span>
                </label>
                <DropDownSearch
                  title={(() => {
                    let title = "Default Time Zone";
                    if (customer?.default_time_zone) {
                      title = customer.default_time_zone;
                    }
                    return title;
                  })()}
                  onSearch={value =>
                    dispatch(timezonesAction.getTimezones({ name: value }))
                  }
                  onBlur={() => dispatch(timezonesAction.getTimezones({}))}
                  options={timezones?.data?.map(timezone => ({
                    id: timezone.id,
                    label: timezone.name,
                    value: timezone.name,
                    data: timezone
                  }))}
                  onSelect={item => {
                    const targetValue = item.value;
                    setCustomer(prevState => ({
                      ...prevState,
                      default_time_zone: targetValue
                    }));
                  }}
                  showSearch={true}
                  errorField="default_time_zone"
                  errors={updateCustomerErrors}
                />
              </div>

              <div className="input-group mb-20">
                <label>
                  Default Language
                  <span className="text-danger">*</span>
                </label>
                <DropDownSearch
                  title={(() => {
                    let title = "Default Language";
                    if (customer?.default_language) {
                      title = customer.default_language;
                    }
                    return title;
                  })()}
                  onSearch={value =>
                    dispatch(localesAction.getLocales({ name: value }))
                  }
                  onBlur={() => dispatch(localesAction.getLocales({}))}
                  options={locales?.data?.map(locale => ({
                    id: locale.id,
                    label: locale.name,
                    value: locale.name,
                    data: locale
                  }))}
                  onSelect={item => {
                    const targetValue = item.value;
                    setCustomer(prevState => ({
                      ...prevState,
                      default_language: targetValue
                    }));
                  }}
                  errorField="default_language"
                  errors={updateCustomerErrors}
                />
              </div>
              <div className="input-group mb-20">
                <label>
                  Tags
                  <span className="text-danger">*</span>
                </label>
                <TextInput
                  type="text"
                  id="tags"
                  name="primary_admin_lastname"
                  className="input-xxl pl-25"
                  errors={updateCustomerErrors}
                  placeholder="Add Tags..."
                  onKeyUp={handleTagKeyUp}
                  onChange={e => handleTagSearch(event.target.value)}
                  autoComplete="off"
                />
                {showDropdown ? (
                  <div className="add__customer-dropdown-container">
                    {tags?.data &&
                      tags?.data.map((item, idx) => (
                        <li
                          key={idx}
                          className="add__customer-dropdown-list-item"
                          onClick={() => handleAddTag(item)}
                        >
                          {item.name}
                        </li>
                      ))}
                  </div>
                ) : null}
              </div>
              {customer?.tags?.length > 0 && (
                <div className="app__user--add-tags mt-20 mb-20">
                  {customer?.tags?.map(tag => (
                    <Tag
                      key={tag?.id}
                      label={tag?.name}
                      tag={tag}
                      onRemove={handleRemoveTag}
                      wrapperClass="mr-10"
                    />
                  ))}
                </div>
              )}
              <div className="input-group mb-20">
                <label>
                  Contact Persons
                  <span className="text-danger">*</span>
                </label>
                <InputField
                  setShowAddContactPersonModal={setShowAddContactPersonModal}
                  customer={customer}
                  handleRemoveContactPerson={handleRemoveContactPerson}
                  errors={updateCustomerErrors}
                />
              </div>
              <div className="row justify-center mb-40 mt-40">
                <Button
                  disabled={isCustomerUpdateLoading}
                  id="intro"
                  className="btn btn--info"
                  onClick={() => handleCustomerFormSubmit(customer)}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </DefaultLayout>
  );
};

export default AddSubscription;
