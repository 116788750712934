import React, { useState } from "react";
import PropTypes from "prop-types";

//Custom Components
import DrawerSubModal from "components/modals/base/drawer/drawer-modal.component";
import AddContactPersonFooter from "./add-contact-footer.component";
import TextInput from "components/form/inputs/text/text-input.component";
import Radio from "components/form/radio/radio.component";

// Styles
import "./add-contact-modal.styles.scss";

const AddContactModal = ({
  showAddContactPersonModal,
  setShowAddContactPersonModal,
  handleAddContactPerson,
  contactPerson,
  setContactPerson,
  errors
}) => {
  return (
    <div>
      <DrawerSubModal.Dialog
        title="Add Contact Person"
        showModal={showAddContactPersonModal}
        setShowModal={setShowAddContactPersonModal}
        styles={{ width: "45vw" }}
      >
        <DrawerSubModal.Body
          Footer={() =>
            AddContactPersonFooter({
              setShowAddContactPersonModal,
              handleAddContactPerson,
              contactPerson
            })
          }
        >
          <div className="add-contact">
            {/* Right Column */}
            <div className="properties__right--col">
              <div className="input-group mb-20">
                <div className="input-group mb-20">
                  <label>
                    Full Name
                    <span className="text-danger">*</span>
                  </label>
                  <TextInput
                    type="text"
                    id="name"
                    name="name"
                    className="input-lg pl-25"
                    iconClass="far fa-user"
                    iconPosition="left"
                    value={contactPerson?.name || ""}
                    onChange={e => {
                      const targetValue = e.target.value;
                      setContactPerson(prevState => ({
                        ...prevState,
                        name: targetValue
                      }));
                    }}
                    errorField="name"
                    errors={errors}
                  />
                </div>
                <div className="input-group mb-20">
                  <label>
                    Email Address
                    <span className="text-danger">*</span>
                  </label>
                  <TextInput
                    type="text"
                    id="email"
                    name="email"
                    className="input-lg pl-25"
                    iconClass="far fa-envelope"
                    iconPosition="left"
                    value={contactPerson?.email || ""}
                    onChange={e => {
                      const targetValue = e.target.value;
                      setContactPerson(prevState => ({
                        ...prevState,
                        email: targetValue
                      }));
                    }}
                    errorField="email"
                    errors={errors}
                  />
                </div>
                <div className="input-group mb-20">
                  <label>
                    Phone Number
                    <span className="text-danger">*</span>
                  </label>
                  <TextInput
                    type="text"
                    id="number"
                    name="number"
                    className="input-lg pl-25"
                    iconClass="fas fa-phone"
                    iconPosition="left"
                    value={contactPerson?.phone || ""}
                    onChange={e => {
                      const targetValue = e.target.value;
                      setContactPerson(prevState => ({
                        ...prevState,
                        phone: targetValue
                      }));
                    }}
                    errorField="number"
                    errors={errors}
                  />
                </div>
              </div>
              <div className="add-contact-input mb-20">
                <Radio
                  defaultChecked={contactPerson?.primary}
                  handleOnClick={() => {
                    setContactPerson(prevState => ({
                      ...prevState,
                      primary: !contactPerson?.primary
                    }));
                  }}
                  hasTabIndex={true}
                  ariaLabelledBy="external_sharing"
                />
                <label className="mt-10 ml-5">Primary Contact</label>
              </div>
              <div className="text-muted">
                Atleast one contact needs to be primary
              </div>
              {/* End of Right Col */}
            </div>
          </div>
        </DrawerSubModal.Body>
      </DrawerSubModal.Dialog>
    </div>
  );
};

AddContactModal.propTypes = {
  showAddContactPersonModal: PropTypes.bool.isRequired,
  setShowAddContactPersonModal: PropTypes.func.isRequired,
  handleAddContactPerson: PropTypes.func.isRequired,
  contactPerson: PropTypes.object.isRequired,
  setContactPerson: PropTypes.func.isRequired,
  errors: PropTypes.array
};

export default AddContactModal;
