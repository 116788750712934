// Custom Components
import http from "helpers/http/http.helper";

// GET PLANS
export const PLANS_LOADING = "PLANS_LOADING";
export const GET_PLANS = "GET_PLANS";
export const PLANS_ERRORS = "PLANS_ERRORS";

// GET PLAN
export const PLAN_LOADING = "PLAN_LOADING";
export const GET_PLAN = "GET_PLAN";
export const PLAN_ERRORS = "PLAN_ERRORS";


// UPDATE PLANS
export const PLAN_UPDATING = "PLAN_UPDATING";
export const UPDATE_PLAN = "UPDATE_PLAN";
export const PLAN_UPDATED ="PLAN_UPDATED"
export const UPDATE_PLAN_ERROR = "UPDATE_PLAN_ERROR";

// Plans
export const getPlans = () => async dispatch => {
  try {
    dispatch({
      type: PLANS_LOADING,
      payload: true
    });
    const response = await http().get(`/plans`);
    dispatch({
      type: GET_PLANS,
      payload: response.data
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: PLANS_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: PLANS_LOADING,
      payload: false
    });
  }
};

export const updatePlan = plan => async dispatch => {
  try {
    dispatch({
      type: PLAN_UPDATING,
      payload: true
    });
    const response = await http().put(`/plans/${plan.id}`, plan);
    dispatch({
      type: UPDATE_PLAN,
      payload: response.data.data
    });
    dispatch({
      type: PLAN_UPDATED,
      payload: response.data.notifications
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: UPDATE_PLAN_ERROR,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: PLAN_UPDATING,
      payload: false
    });
  }
};

export const getPlan = planId => async dispatch => {
  try {
    dispatch({
      type: PLAN_LOADING,
      payload: true
    });
    const response = await http().get(`/plans/${planId}`);
    dispatch({
      type: GET_PLAN,
      payload: response.data.data
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: PLAN_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: PLAN_LOADING,
      payload: false
    });
  }
};
