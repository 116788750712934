import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

// Styles
import "./icon-avatar.styles.scss";

const IconAvatar = ({
  source = "",
  alt = "",
  size = "md",
  flat = false,
  border = false,
  transparent = false,
  classes = "",
  handleOnClick = () => {}
}) => {
  const [isImage, setIsImage] = useState(false);

  useEffect(() => {
    if (source && source.startsWith("http")) {
      setIsImage(true);
    } else {
      setIsImage(false);
    }
  }, [source]);

  const renderImage = () => {
    return (
      <div className="avatar">
        <img
          onClick={handleOnClick}
          alt={alt}
          title={alt}
          className={classnames(
            {
              avatar__flat: flat,
              avatar__border: border,
              "avatar__flat-10": flat === "10",
              "avatar--xs": size === "xs",
              "avatar--sm": size === "sm",
              "avatar--md": size === "md",
              "avatar--lg": size === "lg",
              "avatar--xl": size === "xl",
              "avatar--xxl": size === "xxl"
            },
            `${classes}`
          )}
          src={source}
          tabIndex="0"
        />
      </div>
    );
  };

  const getColorByInitial = initial => {
    switch (initial) {
      case "a":
      case "b":
        return { background: "bg-success", text: "text-white-100" };
      case "c":
      case "d":
        return { background: "bg-info", text: "text-white-100" };
      case "e":
      case "f":
        return { background: "bg-warning", text: "text-white-100" };
      case "g":
      case "h":
        return { background: "bg-danger", text: "text-white-100" };
      case "i":
      case "j":
        return { background: "bg-dark", text: "text-white-100" };
      case "k":
      case "l":
        return { background: "bg-green-sea", text: "text-white-100" };
      case "m":
      case "n":
        return { background: "bg-orange", text: "text-white-100" };
      case "o":
      case "p":
        return { background: "bg-carrot", text: "text-white-100" };
      case "q":
      case "r":
        return { background: "bg-pumpkin", text: "text-white-100" };
      case "s":
      case "t":
        return { background: "bg-indigo", text: "text-white-100" };
      case "u":
      case "v":
        return { background: "bg-river", text: "text-white-100" };
      case "w":
        return { background: "bg-belize", text: "text-white-100" };
      case "x":
        return { background: "bg-wisteria", text: "text-white-100" };
      case "y":
        return { background: "bg-mazrine", text: "text-white-100" };
      case "z":
        return { background: "bg-grey-100", text: "text-dark" };

      default:
        return { background: "bg-grey-100", text: "text-dark" };
    }
  };

  const renderText = () => {
    const chunks = alt ? alt.split(" ") : [];
    let initials = "";
    for (const chunk of chunks.slice(0, 2)) {
      initials += chunk[0] ? chunk[0].toUpperCase() : "";
    }

    const color = getColorByInitial(alt[0]?.toLowerCase());
    return (
      <div
        onClick={handleOnClick}
        title={alt}
        className={classnames(
          `avatar cp ${color.background} ${color.text}`,
          {
            avatar__flat: flat,
            avatar__border: border,
            avatar__transparent: transparent,
            "avatar__flat-10": flat === "10",
            "avatar--xs": size === "xs",
            "avatar--sm": size === "sm",
            "avatar--md": size === "md",
            "avatar--lg": size === "lg",
            "avatar--xl": size === "xl",
            "avatar--xxl": size === "xxl"
          },
          `${classes}`
        )}
      >
        {initials}
      </div>
    );
  };

  return <>{isImage ? renderImage() : renderText()}</>;
};

IconAvatar.propTypes = {
  source: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.string,
  flat: PropTypes.bool,
  border: PropTypes.bool,
  transparent: PropTypes.bool,
  classes: PropTypes.string,
  handleOnClick: PropTypes.func
};

export default IconAvatar;
