// Custom Components
import http from "helpers/http/http.helper";

// GET SUBSCRIPTIONS
export const SUBSCRIPTIONS_LOADING = "SUBSCRIPTIONS_LOADING";
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const SUBSCRIPTIONS_ERRORS = "SUBSCRIPTIONS_ERRORS";

// ADD SUBSCRIPTION
export const SUBSCRIPTION_ADD_LOADING = "SUBSCRIPTION_ADD_LOADING";
export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";
export const SUBSCRIPTION_ADD_ERROR = "SUBSCRIPTION_ADD_ERROR";

// DELETE SUBSCRIPTION
export const SUBSCRIPTION_DELETE_LOADING = "SUBSCRIPTION_DELETE_LOADING";
export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
export const SUBSCRIPTION_DELETE_ERROR = "SUBSCRIPTION_DELETE_ERROR";

// SUBSCRIPTIONS
export const getSubscriptionHistory = (id) => async dispatch => {
  try {
    dispatch({
      type: SUBSCRIPTIONS_LOADING,
      payload: true
    });
    const response = await http().get(`/subscriptions/${id}`);
    dispatch({
      type: GET_SUBSCRIPTIONS,
      payload: response.data
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: SUBSCRIPTIONS_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: SUBSCRIPTIONS_LOADING,
      payload: false
    });
  }
};

export const deleteSubscription = (id) => async dispatch => {
  try {
    dispatch({
      type: SUBSCRIPTION_DELETE_LOADING,
      payload: true
    });
    const response = await http().put(`/subscriptions/${id}/delete`);
    dispatch({
      type: DELETE_SUBSCRIPTION,
      payload: response.data
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: SUBSCRIPTION_DELETE_ERROR,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: SUBSCRIPTION_DELETE_LOADING,
      payload: false
    });
  }
};

export const addSubscription = postData => async dispatch => {
  try {
    dispatch({
      type: SUBSCRIPTION_ADD_LOADING,
      payload: true
    });
    const finalPostData = {
      ...postData
    };
    finalPostData.plan = finalPostData.plan.id;
    finalPostData.currency = finalPostData.currency.id;
    finalPostData.subscription_status = finalPostData.subscription_status.id;
    finalPostData.payment_mode = finalPostData.payment_mode.id;

    const response = await http().post("/subscriptions", finalPostData);
    dispatch({
      type: ADD_SUBSCRIPTION,
      payload: response.data
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: SUBSCRIPTION_ADD_ERROR,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: SUBSCRIPTION_ADD_LOADING,
      payload: false
    });
  }
};
