// Custom Components
import http from "helpers/http/http.helper";

// GET LOCALES
export const LOCALES_LOADING = "LOCALES_LOADING";
export const GET_LOCALES = "GET_LOCALES";
export const LOCALES_ERRORS = "LOCALES_ERRORS";

// LOCALES
export const getLocales = params => async dispatch => {
  try {
    dispatch({
      type: LOCALES_LOADING,
      payload: true
    });
    const response = await http().get(`/locales`, { params });
    dispatch({
      type: GET_LOCALES,
      payload: response.data
    });
  } catch (error) {
    if (error?.response?.status === 401) {
      return (window.location.href = "/");
    }
    dispatch({
      type: LOCALES_ERRORS,
      payload: error?.response?.data?.errors || error
    });
  } finally {
    dispatch({
      type: LOCALES_LOADING,
      payload: false
    });
  }
};
