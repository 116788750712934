import * as actions from "./users.action";

const usersReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.LOGGED_USER_PROFILE:
      return {
        ...state,
        [actions.LOGGED_USER_PROFILE]: action.payload
      };
    case actions.USER_LOADING:
      return {
        ...state,
        [actions.USER_LOADING]: action.payload
      };
    case actions.USER_ADD_LOADING:
      return {
        ...state,
        [actions.USER_ADD_LOADING]: action.payload
      };
    case actions.GET_USERS:
      return {
        ...state,
        [actions.GET_USERS]: action.payload
      };
    case actions.USER_PROFILE:
      return {
        ...state,
        [actions.USER_PROFILE]: action.payload
      };
    case actions.ADD_USER:
      return {
        ...state,
        [actions.ADD_USER]: action.payload
      };
    case actions.USER_PROFILE_UPDATED:
      return {
        ...state,
        [actions.USER_PROFILE_UPDATED]: action.payload
      };
    case actions.USER_PROFILE_TWO_FACTOR_ENABLED:
      return {
        ...state,
        [actions.USER_PROFILE_TWO_FACTOR_ENABLED]: action.payload
      };
    case actions.USER_PROFILE_TWO_FACTOR_DISABLED:
      return {
        ...state,
        [actions.USER_PROFILE_TWO_FACTOR_DISABLED]: action.payload
      };
    case actions.USER_ERRORS:
      return {
        ...state,
        [actions.USER_ERRORS]: action.payload
      };
    case actions.USER_ADD_ERROR:
      return {
        ...state,
        [actions.USER_ADD_ERROR]: action.payload
      };
    case actions.USER_UPDATE_LOADING:
      return {
        ...state,
        [actions.USER_UPDATE_LOADING]: action.payload
      };
    case actions.UPDATE_USER:
      return {
        ...state,
        [actions.UPDATE_USER]: action.payload
      };
    case actions.USER_UPDATE_ERROR:
      return {
        ...state,
        [actions.USER_UPDATE_ERROR]: action.payload
      };

    default:
      return state;
  }
};

export default usersReducer;
