import * as actions from "./domains.action";

const domainsReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.DOMAINS_LOADING:
      return {
        ...state,
        [actions.DOMAINS_LOADING]: action.payload
      };
    case actions.GET_DOMAINS:
      return {
        ...state,
        [actions.GET_DOMAINS]: action.payload
      };
    case actions.DOMAINS_ERRORS:
      return {
        ...state,
        [actions.DOMAINS_ERRORS]: action.payload
      };
    default:
      return state;
  }
};

export default domainsReducer;
