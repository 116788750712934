import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

// Styles
import "./department-menu.styles.scss";

const DepartmentMenu = ({ showMenu, setShowMenu }) => {
  const handleKeyDown = (e) => {
    if (e.which === 27) {
      setShowMenu(false);
    }
  };

  return (
    <div
      className={classnames("app__navigation--item-department-menu", {
        show: showMenu,
      })}
    >
      <div className="app__navigation--item-department-menu-header">
        <div className="app__navigation--item-department-menu-search-box">
          <i className="fas fa-search app__navigation--item-department-menu-search-icon"></i>
          <input
            type="text"
            placeholder="Filter Departments"
            className="app__navigation--item-department-menu-search"
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>

      <div className="app__navigation--item-department-menu-body">
        <ul>
          <li>
            <Link to="/departments/accounting">Accounting</Link>
          </li>
          <li>
            <Link to="/departments/accounting">Marketing</Link>
          </li>
          <li>
            <Link to="/departments/accounting">Digital Marketing</Link>
          </li>
          <li>
            <Link to="/departments/accounting">Search Engine Optimization</Link>
          </li>
          <li>
            <Link to="/departments/accounting">Information Technology</Link>
          </li>
          <li>
            <Link to="/departments/accounting">Human Resources</Link>
          </li>
          <li>
            <Link to="/departments/accounting">Support</Link>
          </li>
          <li>
            <Link to="/departments/accounting">Sales</Link>
          </li>
          <li>
            <Link to="/departments/accounting">Online</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DepartmentMenu;
