import React, { useState } from "react";
import { NavLink , Link } from "react-router-dom";

import DefaultLayout from "layouts/default/default.layout";

import DropDown from "components/form/dropdown/dropdown.component";
import Users from "./account-usage-users/account-usage-users.container";
import Activities from "./account-usage-activities/account-usage-activities.container";

// Styles
import "./account-usage.styles.scss";

export const ADD_CUSTOMER = "Add Customer";
export const CREATE_USER = "Create User";

export const DAILY = "Daily";
export const WEEKLY = "Weekly"
export const MONTHLY = "Monthly"


const AccountUsage = ({location}) => {

  {/* dynamic layouts*/}
  const loadComponent = () => {
    const { pathname } = location;
    if (pathname.search("account-usage-users") !== -1) {
      return <Users />;
    } else if (pathname.search("account-usage-activities") !== -1) {
      return <Activities />;
    }
  };

  const handleFilter = () => {};

  {/*Filter options */}
  const filterOptons = [
    {
      label: DAILY ,
      value: DAILY,
    },
    {
      label: WEEKLY,
      value: WEEKLY,
    },
    {
      label: MONTHLY,
      value: MONTHLY,
    }
  ];

  return (
    <DefaultLayout>
      <div className="container">
        {/* Content Header */}

          <div className="app__data--listing-header">
            <div className="app__data--listing-header-top mb-20">
              <div className="app__data--listing-header-left-col">
                <div className="app__data--listing-title"> 
                <Link to="/manage-customers" className="mr-10">
                  <i
                    className="fas fa-long-arrow-alt-left"
                    data-tip="Back to Manage Customers"
                  ></i>
                </Link> 
                <i className={`fas fa-users app__navigation--item-icon`}></i> Account Usage</div>
              </div>
              <div className="app__data--listing-header-right-col">

                {/* Naviagtion Items*/}
              <ul className="app__data--navigation-items"> 
                    <li className={`app__data--navigation-item `}  >
                      <NavLink to="/manage-customers/1/account-usage-users" activeClassName="app__data--isactive"  exact={true}>Users</NavLink>
                    </li>
                    <li className="app__data--navigation-item">|</li>
                    <li className={`app__data--navigation-item`} >
                    <NavLink to="/manage-customers/1/account-usage-activities" activeClassName="app__data--isactive">Activities</NavLink>
                    </li>
                  </ul>
              </div>
            </div>

            {/* Filters & Date Range */}
            <div className="app__data--listing--filter mt-20">
              <div className="app__data--listing--filter-menu-bar">
                <div className="app__data--listing--filter-menu-dropdown">
                  <DropDown
                    title="filter"
                    options={filterOptons}
                    handleFilter={handleFilter}
                  />
                </div>
                  <div className="app__user--account-usage-date-range-items">
                      <label htmlFor="dob">Start Date:</label>
                      <input type="date" className="input-xxl" id="License-expiry" />
                  </div>
                  <div  className="app__user--account-usage-date-range-items">
                      <label htmlFor="dob">End Date:</label>
                      <input type="date" className="input-xxl" id="License-expiry" />
                  </div> 
                
              </div>
            </div>
          </div>
        <div class="hr mt-20"></div>

        {/* Content Body */}
        {loadComponent()}
           
           {/* Export Button */}
        <div className="app__user--account-usage-button"><button className="app__user--export-button">Export Pdf</button></div>
        {/* End Container */}
      </div>
    </DefaultLayout>
  );
};

export default AccountUsage;

