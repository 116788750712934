import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import { CopyToClipboard } from "react-copy-to-clipboard";

// Custom Components
import * as usersAction from "store/users/users.action";
import Button from "components/form/buttons/button/button.component";
import { TAB_CONFIRM } from "../user-2fa.container";

// Styles
import "./2fa-confirmation.styles.scss";

const TwoFactorConfirmation = ({ activeTab, backupCodes = [] }) => {
  const params = useParams();
  const dispatch = useDispatch();

  const [isCopied, setIsCopied] = useState(true);

  // Reset copied after a second
  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 1000);
    }
  }, [isCopied]);

  // Fetch the user profile when the component unloads
  useEffect(() => {
    return () => {
      dispatch(usersAction.getUserProfile(params.id));
    };
    // eslint-disable-next-line
  }, [dispatch, params]);

  const handleCopyToClipboard = () => {
    let codes = "";
    backupCodes.forEach(code => {
      codes += `${code}\n`;
    });

    return codes;
  };

  return (
    <div
      className={classnames("app__2fa--content", {
        "app__2fa--content-active": activeTab === TAB_CONFIRM
      })}
    >
      <i className="fas fa-shield-alt app__2fa--confirm-badge mb-10"></i>
      <h4 className="text-center">Two factor authentication enabled</h4>

      <p className="app__2fa--content-text mt-30">
        all set from now on when you sign in to your eisenvault account you'll
        need to enter an authentication code from your phone using your
        authenticator app as well as your regular password
      </p>

      <div className="hr my-20"></div>

      <p className="app__2fa--content-text">
        we've generated some backup authentication codes for you to keep in a
        safe place just in case you lose access to your mobile phone
      </p>

      <ul className="app__2fa--backup-codes mt-20">
        {backupCodes.map(code => (
          <li key={code}>{code}</li>
        ))}
      </ul>

      <div className="row justify-center mt-10">
        <CopyToClipboard
          text={handleCopyToClipboard()}
          onCopy={() => setIsCopied(true)}
        >
          <Button
            id="confirmation"
            className={classnames("btn", {
              "btn--outline-info": !isCopied,
              "btn--success": isCopied
            })}
          >
            copy to clipboard
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  );
};

TwoFactorConfirmation.propTypes = {
  activeTab: PropTypes.string,
  backupCodes: PropTypes.array.isRequired
};

export default TwoFactorConfirmation;
