import React from "react";
import PropTypes from "prop-types";

// Styles
import "./dropdown-search-tag.styles.scss";

const DropdownSearchTag = ({
  label,
  tag,
  wrapperClass = "",
  onRemove = null
}) => {
  return (
    <div className={`dropdown-search-tag ${wrapperClass}`}>
      {label}
      {onRemove && (
        <i
          onClick={() => onRemove(tag)}
          className="fas fa-times cp text-hover-danger ml-10"
        ></i>
      )}
    </div>
  );
};

DropdownSearchTag.propTypes = {
  label: PropTypes.string.isRequired,
  tag: PropTypes.object.isRequired,
  wrapperClass: PropTypes.string,
  onRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(null)])
};

export default DropdownSearchTag;
