import React from "react";
import PropTypes from "prop-types";

function NotificationMessage({ message }) {
  const hasMany = Array.isArray(message) && message.length > 1;
  if (Array.isArray(message)) {
    return (
      <ul>
        {message.map((msg, idx) => (
          <li key={idx} className="mb-4">
            {hasMany && <>- </>}
            {msg?.message ? msg.message : msg}
          </li>
        ))}
      </ul>
    );
  }

  if (typeof message !== "string") return "";
  return <>{message}</>;
}

NotificationMessage.propTypes = {
  message: PropTypes.any.isRequired
};

export default NotificationMessage;